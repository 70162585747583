import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { APIURL, MEDIAMAXWIDTH, SEARCHOPTIONVIEWWIDTH,  } from '../common/systemparameter'
import { reportError } from '../common/utility'
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "../common/usersession"
import { useMediaQuery } from 'react-responsive'

const { Option } = Select


const HerbicidePesticideSelect = ({withBlank, defaultValue, onChange}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [herbicidePesticideOption, setHerbicidePesticideOption] = useState([])
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : SEARCHOPTIONVIEWWIDTH)
        
    const getAllHerbicidePesticide = () => {
        axios.get(`${APIURL}herbicidepesticide/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( herbicidePesticide => <Option key={herbicidePesticide.pkey}>{herbicidePesticide.name}</Option>))
            setHerbicidePesticideOption(options)
        })
        .catch( error => {
            reportError(error, "<HerbicidePesticideSelect /> Failed to get herbicide and pesticide type.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const onSelectionChange = (e, value) => {
         onChange(e, value)
    }

    useEffect(() => {
        getAllHerbicidePesticide()
    }, []) 
    
    return(
        <Select style={{width: optionWidth}} onChange={onSelectionChange} allowClear showSearch optionFilterProp="children" defaultValue={defaultValue}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {herbicidePesticideOption}
        </Select>
    )
}

export default HerbicidePesticideSelect