import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { APIURL, MEDIAMAXWIDTH, SEARCHOPTIONVIEWWIDTH,  } from "./systemparameter"
import { reportError } from "./utility"
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "./usersession"
import { useMediaQuery } from 'react-responsive'

const { Option } = Select


const LocationSelect = ({withBlank, defaultValue, onChange, allowClear, marginLeft = 0}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : SEARCHOPTIONVIEWWIDTH)
    const [locationOption, setLocationOption] = useState([])

                
    const getAllLocation = () => {
        axios.get(`${APIURL}locationfull/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( location => <Option key={location.pkey}>{location.name}</Option>))
            setLocationOption(options)
        })
        .catch( error => {
            reportError(error, "<LocationSelect /> Failed to get locations.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const onSelectionChange = (e, value) => {
        onChange(e, value)
    }

    useEffect(() => {
        getAllLocation()
    }, []) 
    
    return(
        <Select style={{width: optionWidth, marginLeft: marginLeft}} defaultValue={defaultValue} onChange={onSelectionChange} allowClear={allowClear} showSearch optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {locationOption}
        </Select>
    )
}

export default LocationSelect