import { message, Space, Modal, Image } from 'antd'
import { APIURL, DATETIMEFORMAT } from "./systemparameter"
import moment from 'moment'
import axios from 'axios'
import { getUserAuthToken, getUserEstateId, OTHERSYSPARAM } from './usersession'

const { confirm } = Modal

export const isFloat = (n) => {
    return Number(n) === n && n % 1 !== 0;
}

export const reportError = (error, customMessage="") => {
    // Error message duration in seconds
    const MAXLEN = 200
    const second = 5

    // non_field_errors
    const obj = error?.response?.data?.non_field_errors
    if(obj !== undefined) {
        //alert(0)
        message.error(obj.join(" "))
    }
    else {
        // Other error
        const otherErrorMsg = error?.response?.data
        //console.log(otherErrorMsg)

        if(otherErrorMsg !== undefined) {
            if(otherErrorMsg?.detail != undefined)  {
                //alert(1)
                message.error(otherErrorMsg.detail, second)
            }
            else if(typeof(otherErrorMsg) === "object") {
                //alert(2)
                let msg = ""
                for(var key in otherErrorMsg) msg = `${msg} ${otherErrorMsg[key]}`
                message.error(msg, second)
            }
            else {
                //alert(otherErrorMsg)
                if(otherErrorMsg.length < MAXLEN)
                    message.error(otherErrorMsg, second)
                //else
                    //message.error("Error message is too long to be displayed.")
            }
        }
    }

    //message.error(error.message)
    if(customMessage !== "") message.error(customMessage)
}

export const numberWithCommas = (number) => {
    if(number != null)
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    else
        return "0"
}

export const stringNumberSorter = (a, b) => {
    return parseFloat(a.replace(',', '')) - parseFloat(b.replace(',', ''))
}

export const localDateTimeSorter = (a, b) => {
    let aMoment = moment(a, DATETIMEFORMAT)
    let bMoment = moment(b, DATETIMEFORMAT)
    return aMoment.unix() - bMoment.unix()
}

export const compressImage = (src, newX, newY) => {
    return new Promise((res, rej) => {
        const img = new Image()
        img.src = src
        img.onload = () => {
            const elem = document.createElement('canvas')
            elem.width = newX
            elem.height = newY
            const ctx = elem.getContext('2d')
            ctx.drawImage(img, 0, 0, newX, newY)
            const data = ctx.canvas.toDataURL()
            res(data)
        }
        img.onerror = error => rej(error)
    })
}

export const validPalmSearchRange = (number) => {
    if(number == '') return true

    let searchRange = number.replace(' ','')
    // Other than ',' and '-' found.
    if(isNaN(parseInt(searchRange.replace(',', '').replace('-','')))) return false
    // Invalid operand for ',' or '-'
    if(searchRange.includes(',,') || searchRange.includes('--') || searchRange.includes(',-') | searchRange.includes('-,')) return false
    if(searchRange.charAt(0) == ',' || searchRange.charAt(0) == '-' || searchRange.charAt(searchRange.length - 1) == ',' || searchRange.charAt(searchRange.length - 1) == '-') return false
    
    // Maximum range number is 100,000
    let searchRangeArr = searchRange.split(',')
    for(const i of searchRangeArr) {
        if(i.includes('-')) {
            const subSearchRangeArr = i.split('-')
            for(const j of subSearchRangeArr) {
                if(parseInt(j) > 100000) return false    
            }
        }
        else {
            if(parseInt(i) > 100000) return false
        }
    }

    return true
}


export const checkUnregisteredInfo = () => {
    axios.get(`${APIURL}unregisteredinfo/`, {
        params: {
            estate: getUserEstateId()
        },
        timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
        headers: {"Authorization": `Token ${getUserAuthToken()}`}
    })
    .then( response => {
        //const unregisteredInfo = JSON.parse(response.data)
        let module = ''
        if(response.data.attendance > 0) module = "Attendance"

        if(response.data.ffb_collection > 0) {
            if(module != '') module = `${module}, `
            module = `${module}FFB Daily Collection`
        }
        
        if(response.data.factory_receipt > 0) {
            if(module != '') module = `${module}, `
            module = `${module}Factory Receipt`
        }
        if(module == '') return

        // Modal
        Modal.warning({
            title: <div><h3>{`Note: Unregistered information (worker/driver/lorry) found in ${module} module. Please register the information and update activity record accordingly.`}</h3></div>,
            icon: null,
            centered: true,
            maskClosable: false,
            closable: false,
            cancelButtonProps: {style: {display: "none"}},
        })
    })
    .catch( error => {
        reportError(error, "Failed to get unregistered info.")
    })
}