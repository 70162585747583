import { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import { Form, Input, Button, message, Space, Layout, Row, Col, Popconfirm, Modal, Descriptions, Spin, Switch } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { SaveOutlined, CloseOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import MainHeader from '../common/header'
import MainFooter from '../common/footer'
import { reportError } from '../common/utility'
import axios from "axios"
import { APIURL, LOADING, MENUPATH_DRIVER, SEARCHOPTIONVIEWWIDTH } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, retrieveBackgroundColor } from "../common/usersession"
import EstateSelect from '../common/estateselect'
import { walltiles } from '../common/layout'

const { Header, Footer, Content } = Layout
const { confirm } = Modal


const DriverUpdate = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
            
    const [form] = Form.useForm()
    const [disableDeleteButton, setDisableDeleteButton] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [estateId, setEstateId] = useState(urlParams.get("estateId"))
    const [isActive, setIsActive] = useState(urlParams.get("active") == "true" ? true: false)
    
    const updateDriver = () => {
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${APIURL}driver/update/${urlParams.get("pkey")}/`, {
                name: values.name,
                estate: estateId,
                employee_id: values.employeeId,
                active: isActive,
                remark: values.remark,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`Driver ${values.name} updated.`)
                navigate(MENUPATH_DRIVER)
            })
            .catch( error => {
                reportError(error, "Failed to update driver.")
            })
            .finally(() => {
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    const onDelete = () => {
        setIsLoading(true)
        
        axios.delete(`${APIURL}driver/delete/${urlParams.get("pkey")}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Driver ${urlParams.get("name")} deleted.`)
            navigate(MENUPATH_DRIVER)
        })
        .catch( error => {
            reportError(error, "Failed to delete driver.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    // Determine if has related record.
    const toDisableDeleteButton = () => {
        setIsLoading(true)

        axios.get(`${APIURL}driverdownloaded/${urlParams.get("pkey")}/`, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response.data.downloaded) 
                setDisableDeleteButton(true)
        })
        .catch( error => {
            reportError(error, "Failed to determine if driver record was downloaded.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onCancel = () => {
        navigate(MENUPATH_DRIVER)
    }

    const onUpdate = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Update driver confirmed?</p></Space>,
            onOk() { updateDriver() },
            onCancel() {},
            centered: true
        })
    }

    const onIsActiveChange = (checked) => {
        setIsActive(checked)
    }

    const onEstateChange = (e, value) => {
        if(e != undefined) 
            setEstateId(e)
        else 
            setEstateId(0)
    }

    const validateEstate = (() => {
        if(estateId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Estate is required."))
    })

    useEffect(() => {
        toDisableDeleteButton()
        
        form.setFieldsValue({
            name: urlParams.get("name"),
            employeeId: urlParams.get("employeeId"),
            remark: urlParams.get("remark"),
        })
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles, backgroundColor: retrieveBackgroundColor()}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onCancel()} 
                    title="Driver">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update driver</Descriptions.Item>
                    </Descriptions>
                </PageHeader>
        
                <Form form={form} onFinish={onUpdate} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                    <Form.Item name="name" label="Name"
                        rules={[
                            { required: true, message: "Driver name is required."},
                        ]}>
                        <Input maxLength={50} placeholder="Driver Name" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="employeeId" label="Employee ID"
                        rules={[
                            { required: true, message: "Employee ID is required."},
                        ]}>
                        <Input maxLength={50} placeholder="Employee ID" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="estate" label="Estate"
                        rules={[
                            { required: true, validator: validateEstate },
                        ]}>
                        <EstateSelect withBlank={false} allowClear={false} defaultValue={urlParams.get("estate")} onChange={onEstateChange} marginLeft={10}/>
                    </Form.Item>

                    <Form.Item name="isActive" label="Is Active">
                        <Switch defaultChecked={isActive} onChange={onIsActiveChange} style={{ marginLeft: 10 }}/>
                    </Form.Item>
                    
                    <Form.Item name="remark" label="Remark">
                        <Input placeholder="Remark" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>Update</Button>
                            <Popconfirm title="Delete driver confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" disabled={disableDeleteButton} icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" onClick={onCancel} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                        title="Driver:"
                        subTitle="Update driver"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default DriverUpdate