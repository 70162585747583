import { Table, Row, Col, Space, Button, Collapse, Pagination, message, Spin, DatePicker, Image, Input } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT, FALLBACK, LOADING, PAGESIZE, PAGINATIONSIZE, SEARCHOPTIONVIEWWIDTH, UNIDATEFORMAT, UNIDATETIMEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserEstateId, getUserEstateName } from "../common/usersession"
import { useNavigate } from "react-router"
import { numberWithCommas, reportError } from '../common/utility'
import dayjs from 'dayjs';
import FactorySelect from '../common/factoryselect'

const { Panel } = Collapse
const { RangePicker } = DatePicker

const FactoryReceiptSummaryReportTable = () => {
    const FileDownload = require('js-file-download')
    const navigate = useNavigate()

    const [factoryReceiptDataSource, setFactoryReceiptDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const [collectedOnDateRange, setCollectedOnDateRange] = useState([dayjs().add(-1, 'month').add(1, 'day'), dayjs()])
    const [factoryPKey, setFactoryPKey] = useState(0)
    const [factory, setFactory] = useState('')
    const [chitNumber, setChitNumber] = useState('')
        
    const searchFactoryReceipt = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}factoryreceiptsummarypaginated/`, {
            params: { 
                estate: getUserEstateId(),
                from_date: collectedOnDateRange[0] == null ? null : collectedOnDateRange[0].format(UNIDATEFORMAT),
                to_date: collectedOnDateRange[1] == null ? null : collectedOnDateRange[1].format(UNIDATEFORMAT),
                factory: factoryPKey,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search factory receipt summary.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( summary => {
            data.push({
                key: summary.id,
                factory: summary.factory_name,
                grossWeightTon: summary.gross_weight_ton.toFixed(2),
                tareWeightTon: summary.tare_weight_ton.toFixed(2),
                nettWeightTon: summary.nett_weight_ton.toFixed(2),
                collectedOn: dayjs(summary.collected_date).format(DATEFORMAT),
            })
        })
        
        setFactoryReceiptDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const downloadSearchResult = (mode) => {
        setIsLoading(true)
                        
        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Estate: ${getUserEstateName()}`
        if(collectedOnDateRange[0] != null) searchCriteria = `${searchCriteria}\nCollected On: ${collectedOnDateRange[0].format(DATEFORMAT)} to ${collectedOnDateRange[1].format(DATEFORMAT)}`
        if(factoryPKey != 0) searchCriteria = `${searchCriteria}\nFactory: ${factory}`
                                                        
        axios.get(`${APIURL}factoryreceiptsummary/download/`, {
            params: { 
                estate: getUserEstateId(),
                from_date: collectedOnDateRange[0] == null ? null : collectedOnDateRange[0].format(UNIDATEFORMAT),
                to_date: collectedOnDateRange[1] == null ? null : collectedOnDateRange[1].format(UNIDATEFORMAT),
                factory: factoryPKey,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 12),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Factory Receipt Summary ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchFactoryReceipt(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSearch = () => {
        setCurrentPage(1)
        searchFactoryReceipt(1)
    }

    const onReset = () => {
        window.location.reload()
    }

    const onCollectedOnDateRangeChange = (dateRange) => {
        if(dateRange == null)
            setCollectedOnDateRange([null, null])
        else
            setCollectedOnDateRange(dateRange)
    }

    const onFactoryChange = (e, value) => {
        setFactoryPKey(e)
        setFactory(value.children)
    }

    useEffect(() => {
        searchFactoryReceipt(currentPage)
    }, [])

    const columns = [
        { title: 'Collected On', dataIndex: 'collectedOn', key: 'collectedOn', align: 'center', sorter: (a, b) => a.collectedOn.localeCompare(b.collectedOn) },
        { title: 'Factory', dataIndex: 'factory', key: 'factory', align: 'center', sorter: (a, b) => a.factory.localeCompare(b.factory) },
        { title: 'Gross Weight (Ton)', dataIndex: 'grossWeightTon', key: 'grossWeightTon', align: 'right', sorter: (a, b) => a.grossWeightTon - b.grossWeightTon },
        { title: 'Tare Weight (Kg)', dataIndex: 'tareWeightTon', key: 'tareWeightTon', align: 'right', sorter: (a, b) => a.tareWeightTon - b.tareWeightTon },
        { title: 'Nett Weight (Kg)', dataIndex: 'nettWeightTon', key: 'nettWeightTon', align: 'right', sorter: (a, b) => a.nettWeightTon - b.nettWeightTon },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search factory receipt" key="1">
                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Collected On :</Col>
                            <Col span={3}>
                                <RangePicker onChange={onCollectedOnDateRangeChange} defaultValue={collectedOnDateRange} format={DATEFORMAT}/>
                            </Col>

                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Factory :</Col>
                            <Col span={3}>
                                <FactorySelect withBlank={true} allowClear={true} onChange={onFactoryChange} noStyle={true}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            </>
                        </Row>   

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} loading={isLoading} icon={<DownloadOutlined />} />
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} loading={isLoading} icon={<FileExcelOutlined />} />
                                <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={factoryReceiptDataSource} pagination={false} />

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} />
        </Row>
        </Spin>
        </>
    )
}

export default FactoryReceiptSummaryReportTable