import { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import { Form, Button, Space, Layout, Row, Col, Popconfirm, message, Modal, Input, Descriptions, Spin, DatePicker, InputNumber, Card, Table, Pagination } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { SaveOutlined, CloseOutlined, QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons"
import MainHeader from '../common/header'
import MainFooter from '../common/footer'
import { APIURL, DATEFORMAT, DATETIMEFORMAT, LOADING, MENUPATH_TREE, MONTHFORMAT, NONEDATE, PAGESIZE, PAGINATIONSIZE, SEARCHOPTIONVIEWWIDTH, UNIDATEFORMAT } from '../common/systemparameter'
import { reportError } from '../common/utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserEstateId, SYSPARAM, retrieveBackgroundColor } from "../common/usersession"
import dayjs from 'dayjs'
import GenderSelect from '../common/genderselect'
import SpeciesSelect from '../common/speciesselect'
import HealthConditionSelect from '../common/healthconditionselect'
import { walltiles } from '../common/layout'

const { Header, Footer, Content } = Layout
const { confirm } = Modal


const TreeUpdate = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const [treeLocationHistoryDataSource, setTreeLocationHistoryDataSource] = useState([])

    // Unpack url search parameters
    const param = new URLSearchParams(window.location.search)
    const urlParams = JSON.parse(param.get("tree"))
        
    const [speciesPKey, setSpeciesPKey] = useState(parseInt(urlParams["speciesPKey"]))
    const [genderPKey, setGenderPKey] = useState(parseInt(urlParams["genderPKey"]))
    const [healthConditionPKey, setHealthConditionPKey] = useState(parseInt(urlParams["healthConditionPKey"]))
    const [plantedOn, setPlantedOn] = useState(dayjs(urlParams["plantedOnUnformatted"]))
    const [deadOn, setDeadOn] = useState(dayjs(urlParams["deadOnUnformatted"]))
        
    const updateTree = () => {
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${APIURL}tree/update/${urlParams["key"]}/`, {
                gender: genderPKey,
                health_condition: healthConditionPKey,
                species: speciesPKey,
                planted_on: plantedOn.format(UNIDATEFORMAT),
                dead_on: healthConditionPKey != SYSPARAM("Dead") ? NONEDATE : deadOn.format(UNIDATEFORMAT),
                block: values.block,
                row: values.row,
                number: values.number,
                source_seedling: null,
                remark: values.remark,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 4,
                headers: { "Authorization": `Token ${getUserAuthToken()}`, }
            })
            .then( response => {
                message.info(`Tree record updated.`)
                navigate(MENUPATH_TREE)
            })
            .catch( error => {
                reportError(error, `Failed to update tree record.`)
            })
            .finally(() => {
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
        .finally(() => {
        })
    }

    const onDelete = () => {
        setIsLoading(true)
        
        axios.delete(`${APIURL}tree/delete/${urlParams["key"]}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Tree record deleted.`)
            navigate(MENUPATH_TREE)
        })
        .catch( error => {
            reportError(error, "Failed to delete tree record.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const getTreeLocationHistory = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}treelocationhistory/`, {
            params: { 
                tree: urlParams["key"],
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to get tree location history.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( history => {
            data.push({
                key: history.pkey,
                block: history.block,
                row: history.row,
                number: history.number,
                remark: history.remark,
                lastInLocationOn: dayjs(history.last_in_location_on).format(DATEFORMAT),
                uploadedOn: history.uploaded_on != null ? dayjs(history.uploaded_on).format(DATETIMEFORMAT) : '',
                uploadedBy: history.uploader_data != null ? history.uploader_data.username : '',
            })
        })
        
        setTreeLocationHistoryDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const onSave = async (e) => {
        if(healthConditionPKey == SYSPARAM("Dead")) {
            if(dayjs(dayjs(deadOn).format("YYYY-MM-01")).isBefore(dayjs(dayjs(plantedOn).format("YYYY-MM-01")))) {
                message.warning('Dead on is before planted on.')
                return
            }
        }
            
        form.validateFields()
        .then( values => {
            // If dead, remark is required.
            if(healthConditionPKey == SYSPARAM("Dead") && (form.getFieldValue("remark") == undefined || values.remark.trim() == "")) {
                message.warning('Remark is required for registering dead tree.')
                return
            }

            // To palm must be bigger than from palm.
            if(parseInt(values.toPalm) < parseInt(values.fromPalm)) {
                message.warning('Invalid "To Palm".')
                return
            }

            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>Update tree confirmed?</p></Space>,
                onOk() { updateTree() },
                onCancel() {},
                centered: true
            })
        })
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSpeciesChange = (e, value) => {
        setSpeciesPKey(e)
    }

    const onGenderChange = (e, value) => {
        setGenderPKey(e)
    }

    const onPlantedOnChange = (month) => {
        setPlantedOn(month)
    }

    const onDeadOnChange = (month) => {
        setDeadOn(month)
    }

    const onHealthConditionChange = (e, value) => {
        setHealthConditionPKey(e)

        if(e != SYSPARAM("Dead"))
            setDeadOn(dayjs(NONEDATE).toDate())
        else
            setDeadOn(dayjs())
    }

    const validateSpecies = (() => {
        if(plantedOn != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Species is required."))
    })

    const validateGender = (() => {
        if(plantedOn != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Gender is required."))
    })

    const validateHealthCondition = (() => {
        if(plantedOn != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Health condition is required."))
    })

    const validatePlantedOn = (() => {
        if(plantedOn != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Planted on is required."))
    })

    const validateDeadOn = (() => {
        if(deadOn != null) {
            return Promise.resolve()
        }

        return Promise.reject(new Error("Dead on is required."))
    })
    
    const onCancel = () => {
        navigate(MENUPATH_TREE)
    }

    useEffect(() => {
        getTreeLocationHistory(currentPage)

        form.setFieldsValue({
            block: urlParams["block"],
            row: urlParams["row"],
            number: urlParams["number"],
            remark: urlParams["remark"]
        })
    }, [])

    const columns = [
        { title: 'Last In Location', dataIndex: 'lastInLocationOn', key: 'lastInLocationOn', sorter: (a, b) => a.lastInLocationOn.localeCompare(b.lastInLocationOn) },
        { title: 'Block', dataIndex: 'block', key: 'block', sorter: (a, b) => a.block.localeCompare(b.block) },
        { title: 'Row', dataIndex: 'row', key: 'row', sorter: (a, b) => a.row.localeCompare(b.row) },
        { title: 'Palm', dataIndex: 'number', key: 'number', sorter: (a, b) => a.number.localeCompare(b.number) },
        { title: 'Remark', dataIndex: 'remark', key: 'remark', sorter: (a, b) => a.remark.localeCompare(b.remark) },
        { title: 'Uploaded On', dataIndex: 'uploadedOn', key: 'uploadedOn', sorter: (a, b) => a.uploadedOn.localeCompare(b.uploadedOn) },
        { title: 'Uploaded By', dataIndex: 'uploadedBy', key: 'uploadedBy', sorter: (a, b) => a.uploadedBy.localeCompare(b.uploadedBy) },
    ]

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles, backgroundColor: retrieveBackgroundColor()}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onCancel()} 
                    title="Tree Record">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update tree record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row align='center'>
                    <Col span={8} style={{textAlign: "start"}} >
                        <Card title={`Tree ID: ${urlParams["treeId"]}`}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Created On:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{urlParams["createdOn"]}</Card.Grid>
                    
                            { urlParams["updatedOn"] != urlParams["createdOn"] &&
                                <>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Updated On:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{urlParams["updatedOn"]}</Card.Grid>
                                </>
                            }
                            { urlParams["uploadedOn"] != "" &&
                                <>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Uploaded On:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{urlParams["uploadedOn"]}</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Uploaded By:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{urlParams["uploadedBy"]}</Card.Grid>
                                </>
                            }
                        </Card>
                    </Col>
                </Row>

                <Row><Col><Space><div /></Space></Col></Row>

                <Form form={form} onFinish={onSave} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                    <Form.Item name="species" label="Species"
                        rules={[
                            { required: true, validator: validateSpecies },
                        ]}>
                        <SpeciesSelect withBlank={false} allowClear={false} onChange={onSpeciesChange} defaultValue={urlParams["species"]} marginLeft={10}/>
                    </Form.Item>
                    
                    <Form.Item name="gender" label="Gender"
                        rules={[
                            { required: true, validator: validateGender },
                        ]}>
                        <GenderSelect withBlank={false} allowClear={false} onChange={onGenderChange} defaultValue={urlParams["gender"]} marginLeft={10}/>
                    </Form.Item>

                    <Form.Item name="healthCondition" label="Health Condition"
                        rules={[
                            { required: true, validator: validateHealthCondition },
                        ]}>
                        <HealthConditionSelect withBlank={false} allowClear={false} onChange={onHealthConditionChange} defaultValue={urlParams["healthCondition"]} marginLeft={10}/>
                    </Form.Item>

                    <Form.Item name="plantedOn" label="Planted On"
                        rules={[
                            { required: true, validator: validatePlantedOn },
                        ]}>
                        <DatePicker picker="month" onChange={onPlantedOnChange} defaultValue={plantedOn} format={MONTHFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }}/>
                    </Form.Item>

                    { healthConditionPKey == SYSPARAM("Dead") &&
                    <Form.Item name="deadOn" label="Dead On"
                        rules={[
                            { required: true, validator: validateDeadOn },
                        ]}>
                        <DatePicker picker="month" onChange={onDeadOnChange} defaultValue={deadOn} format={MONTHFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }}/>
                    </Form.Item>
                    }

                    <Form.Item name="block" label="Block"
                        rules={[
                            { required: true, message: "Block is required."},
                        ]}>
                        <InputNumber min={0} max={100000} step={1} precision={0} placeholder="Block" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="row" label="Row"
                        rules={[
                            { required: true, message: "Row is required."},
                        ]}>
                        <InputNumber min={0} max={100000} step={1} precision={0} placeholder="Row" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="number" label="Palm"
                        rules={[
                            { required: true, message: "Palm is required."},
                        ]}>
                        <InputNumber min={0} max={1000000} step={1} precision={0} placeholder="Palm" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="remark" label="Remark">
                        <Input placeholder="Remark" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }}/>
                    </Form.Item>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>Update</Button>
                            <Popconfirm title="Delete tree confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" onClick={onCancel} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Row><Col><Space><div /></Space></Col></Row>

            { totalRecord > 0 &&
            <Row justify={"center"}>
                <Col span={22}>
                    <Table bordered columns={columns} dataSource={treeLocationHistoryDataSource} pagination={false}/>
                </Col>
            </Row>
            }

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                    title="Tree Record:"
                    subTitle="Update tree record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default TreeUpdate
