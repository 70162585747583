import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { APIURL, MEDIAMAXWIDTH, SEARCHOPTIONVIEWWIDTH } from "./systemparameter"
import { reportError } from "./utility"
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "./usersession"
import { useMediaQuery } from 'react-responsive'

const { Option } = Select


const FertilizerSelect = ({withBlank, defaultValue, onChange, allowClear = false, noStyle = false, marginLeft = 0}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [fertilizerOption, setFertilizerOption] = useState([])
    const [optionWidth, setOptionWidth] = useState(!noStyle ? isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : SEARCHOPTIONVIEWWIDTH : SEARCHOPTIONVIEWWIDTH)
            
    //---------
    // Load all
    //---------
    const getAllFertilizer = () => {
        axios.get(`${APIURL}fertilizer/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( fertilizer => <Option key={fertilizer.pkey}>{fertilizer.name}</Option> ))
            setFertilizerOption(options)
        })
        .catch( error => {
            reportError(error, "<FertilizerSelect /> Failed to get fertilizer.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    //---------------------------------------------------------------
    // Call back to parent component to set selected option key value
    //---------------------------------------------------------------
    const onSelectionChange = (e, value) => {
        if(e != undefined && value != undefined)
            onChange(e, value)
        else
            onChange(0, "")
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getAllFertilizer()
    }, []) 
    
    return(
        <Select defaultValue={defaultValue} style={{width: optionWidth, marginLeft: marginLeft}} onChange={(e, value) => onSelectionChange(e, value)} allowClear={allowClear} showSearch optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {fertilizerOption}
        </Select>
    )
}

export default FertilizerSelect