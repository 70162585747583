import { Row, Col, Space, Button, Collapse, message, Spin, DatePicker } from 'antd'
import { DownloadOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, DATEFORMAT, LOADING, UNIDATEFORMAT, UNIDATETIMEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../common/usersession"
import { reportError } from '../common/utility'
import dayjs from 'dayjs';

const { Panel } = Collapse
const { RangePicker } = DatePicker


const AttendanceReportTable = () => {
    const FileDownload = require('js-file-download')
    const [isLoading, setIsLoading] = useState(false)
    const [reportDateRange, setReportDateRange] = useState([dayjs().add(-1, 'month').add(1, 'day'), dayjs()])
        
    
    const downloadExcelReport = () => {
        setIsLoading(true)
                        
        axios.get(`${APIURL}attendanceexcelreport/download/`, {
            params: { 
                from_date: reportDateRange[0].format(UNIDATEFORMAT),
                to_date: reportDateRange[1].format(UNIDATEFORMAT),
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 12),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                FileDownload(response.data, `Attendance Report ${now}.xlsx`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onReportDateRangeChange = (dateRange) => {
        if(dateRange == null)
            setReportDateRange([null, null])
        else
            setReportDateRange(dateRange)
    }

    useEffect(() => {
    }, [])

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="" key="1">
                        <Row justify="center">
                            <>
                            <Col span={6} />
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Report Date Range:</Col>
                            <Col span={3}>
                            <RangePicker onChange={onReportDateRangeChange} defaultValue={reportDateRange} format={DATEFORMAT} allowClear={false} />
                            </Col>
                            <Col span={7}/>
                            </>
                        </Row>   

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={downloadExcelReport} loading={isLoading} icon={<DownloadOutlined />} />
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        </Spin>
        </>
    )
}

export default AttendanceReportTable