import { Table, Row, Col, Space, Button, Collapse, Pagination, message, Spin, DatePicker, Image, Input, Badge } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT, FALLBACK, LOADING, PAGESIZE, PAGINATIONSIZE, SEARCHOPTIONVIEWWIDTH, UNIDATEFORMAT, UNIDATETIMEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserEstateId, getUserEstateName } from "../common/usersession"
import { useNavigate } from "react-router"
import { numberWithCommas, reportError } from '../common/utility'
import dayjs from 'dayjs';
import IncidentTypeSelect from '../common/incidenttypeselect'

const { Panel } = Collapse
const { RangePicker } = DatePicker

const IncidentTable = () => {
    const FileDownload = require('js-file-download')
    const navigate = useNavigate()

    const [incidentDataSource, setIncidentDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const [incidentDateRange, setIncidentDateRange] = useState([dayjs().add(-1, 'month').add(1, 'day'), dayjs()])
    const [incidentTypePKey, setIncidentTypePKey] = useState(0)
    const [incidentType, setIncidentType] = useState('')
    const [visible, setVisible] = useState(false)
    const [previewPhotos, setPreviewPhotos] = useState([])
        
    const searchIncident = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}incidentpaginated/`, {
            params: { 
                estate: getUserEstateId(),
                from_date: incidentDateRange[0] == null ? null : incidentDateRange[0].format(UNIDATEFORMAT),
                to_date: incidentDateRange[1] == null ? null : incidentDateRange[1].format(UNIDATEFORMAT),
                incident_type: incidentTypePKey,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search incident.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( incident => {
            data.push({
                key: incident.pkey,
                incidentTypePKey: incident.incident_type_data.pkey,
                incidentType: incident.incident_type_data.name,
                
                incidentDate: dayjs(incident.incident_date).format(DATETIMEFORMAT),
                incidentDateUnformatted: dayjs(incident.incident_date).format(UNIDATETIMEFORMAT),
                remark: incident.remark,
                createdOn: dayjs(incident.created_on).format(DATETIMEFORMAT),
                updatedOn: dayjs(incident.updated_on).format(DATETIMEFORMAT),
                uploadedOn: incident.uploaded_on != null ? dayjs(incident.uploaded_on).format(DATETIMEFORMAT) : '',
                uploadedBy: incident.uploader_data != null ? incident.uploader_data.username : '',
                
                img: `${incident?.photo_data[0]?.img}?now=${dayjs().valueOf()}`,
                photoCount: `${incident.photo_data.length}`,
                photos: incident?.photo_data
            })
        })
        
        setIncidentDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const downloadSearchResult = (mode) => {
        if(incidentDateRange[0] == null) {
            message.warning('Date range is required.')
            return
        }

        setIsLoading(true)
                        
        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Estate: ${getUserEstateName()}`
        if(incidentDateRange[0] != null) searchCriteria = `${searchCriteria}\nIncident Date: ${incidentDateRange[0].format(DATEFORMAT)} to ${incidentDateRange[1].format(DATEFORMAT)}`
        if(incidentTypePKey != 0) searchCriteria = `${searchCriteria}\nIncident Type: ${incidentType}`
                                                
        axios.get(`${APIURL}incident/download/`, {
            params: { 
                estate: getUserEstateId(),
                from_date: incidentDateRange[0].format(UNIDATEFORMAT),
                to_date: incidentDateRange[1].format(UNIDATEFORMAT),
                incident_type: incidentTypePKey,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 12),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Incident ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const showPhotos = (e, record) => {
        e.stopPropagation()

        const photos = []
        record.photos.forEach( photo => {
            photos.push(<Image fallback={FALLBACK} src={`${photo.img}?now=${new Date()}`}/>)
        })
        
        setPreviewPhotos(photos)
        setVisible(true)
    }
    
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchIncident(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSearch = () => {
        setCurrentPage(1)
        searchIncident(1)
    }

    const onReset = () => {
        window.location.reload()
    }

    const onCreateNew = () => {
        navigate({
            pathname: '/incidentnew',
        })
    }

    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/incidentupdate", 
                    search: `?incident=${JSON.stringify(record)}`
                }) 
            }
        }
    }

    const onIncidentDateRangeChange = (dateRange) => {
        if(dateRange == null)
            setIncidentDateRange([null, null])
        else
            setIncidentDateRange(dateRange)
    }

    const onIncidentTypeChange = (e, value) => {
        setIncidentTypePKey(e)
        setIncidentType(value.children)
    }

    useEffect(() => {
        searchIncident(currentPage)
    }, [])

    const columns = [
        { title: 'Incident Date', dataIndex: 'incidentDate', key: 'incidentDate', align: 'center', sorter: (a, b) => a.incidentDate.localeCompare(b.incidentDate) },
        { title: 'Incident Type', dataIndex: 'incidentType', key: 'incidentType', align: 'center', sorter: (a, b) => a.incidentType.localeCompare(b.incidentType) },
        { title: 'Remark', dataIndex: 'remark', key: 'remark', align: 'left', },
        { title: 'Uploaded By', dataIndex: 'uploadedBy', key: 'uploadedBy', align: 'center', sorter: (a, b) => a.uploadedBy.localeCompare(b.uploadedBy) },
        { title: 'Uploaded On', dataIndex: 'uploadedOn', key: 'uploadedOn', align: 'center', sorter: (a, b) => a.uploadedOn.localeCompare(b.uploadedOn) },
        { title: 'Photo', dataIndex: 'img', key: 'img', align: 'center',
            render: (img, record) => {
                if(img != null) 
                    return <>
                        <Badge count={record.photoCount}>
                            <Image width={50} fallback={FALLBACK} src={img} preview={false} onClick={(e) => showPhotos(e, record) }/>
                        </Badge>
                    </>
                else
                    return null
            }
        },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search incident" key="1">
                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Incident Date :</Col>
                            <Col span={3}>
                                <RangePicker onChange={onIncidentDateRangeChange} defaultValue={incidentDateRange} format={DATEFORMAT}/>
                            </Col>

                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Incident Type :</Col>
                            <Col span={3}>
                                <IncidentTypeSelect withBlank={true} allowClear={true} onChange={onIncidentTypeChange} noStyle={true}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            </>
                        </Row>   

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} loading={isLoading} icon={<DownloadOutlined />} />
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} loading={isLoading} icon={<FileExcelOutlined />} />
                                <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={incidentDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <div style={{ display: 'none'}}>
            <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}>{previewPhotos}</Image.PreviewGroup>
        </div>
        
        </Spin>
        </>
    )
}

export default IncidentTable