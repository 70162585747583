import { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import { Form, Button, Space, Layout, Row, Col, Popconfirm, message, DatePicker, Card, Modal, Input, Descriptions, Spin, InputNumber } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { SaveOutlined, CloseOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import MainHeader from '../common/header'
import MainFooter from '../common/footer'
import { APIURL, LOADING, UNIDATETIMEFORMAT, SEARCHOPTIONVIEWWIDTH, UNIDATEFORMAT, MENUPATH_FFBDAILYCOLLECTION, DATEFORMAT } from "../common/systemparameter"
import { reportError } from "../common/utility"
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, retrieveBackgroundColor } from "../common/usersession"
import dayjs from 'dayjs'
import WorkerSelect from '../common/workerselect'
import { walltiles } from '../common/layout'

const { Header, Footer, Content } = Layout
const { confirm } = Modal


const FFBDailyCollectionUpdate = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)
    const ffbDailyCollection = JSON.parse(urlParams.get('ffbDailyCollection'))

    const [collectedOn, setCollectedOn] = useState(dayjs(ffbDailyCollection.collectedOnUnformatted), UNIDATETIMEFORMAT)
    const [workerPKey, setWorkerPKey] = useState(ffbDailyCollection.workerPKey)

    const updateFFBDailyCollection = () => {
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${APIURL}ffbdailycollection/update/${ffbDailyCollection.key}/`, {
                collected_on: dayjs(collectedOn).format(UNIDATEFORMAT),
                worker: workerPKey,
                block: values.block,
                number_of_bunches: values.numberOfBunches,
                loose_fruit_bag: values.looseFruitBag,
                remark: values.remark
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Ffb collection record updated.")
                navigate(MENUPATH_FFBDAILYCOLLECTION)
            })
            .catch( error => {
                reportError(error, `Failed to update ffb collection record.`)
            })
            .finally(() => {
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }

    const onDelete = () => {
        setIsLoading(true)
        
        axios.delete(`${APIURL}ffbdailycollection/delete/${ffbDailyCollection.key}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Ffb collection record deleted.`)
            navigate(MENUPATH_FFBDAILYCOLLECTION)
        })
        .catch( error => {
            reportError(error, "Failed to delete ffb collection record.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onSave = () => {
        form.validateFields()
        .then( values => {
            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>Update ffb collection record confirmed?</p></Space>,
                onOk() { updateFFBDailyCollection() },
                onCancel() {},
                centered: true
            })
        })
    }

    const onCancel = () => {
        navigate(MENUPATH_FFBDAILYCOLLECTION)
    }

    const onCollectedOnChange = (datetime) => {
        setCollectedOn(datetime)
    }

    const onWorkerChange = (e, value) => {
        if(e != undefined) 
            setWorkerPKey(e)
        else 
            setWorkerPKey(0)
    }

    const validateCollectedOn = (() => {
        if(collectedOn != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Collected on is required."))
    })

    useEffect(() => {
        form.setFieldsValue({
            block: ffbDailyCollection.block,
            numberOfBunches: ffbDailyCollection.numberOfBunches,
            looseFruitBag: ffbDailyCollection.looseFruitBag,
            remark: ffbDailyCollection.remark,
        })
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles, backgroundColor: retrieveBackgroundColor()}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader 
                    onBack={() => onCancel()} 
                    title="FFB Daily Collection">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update ffb daily collection record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row align='center'>
                    <Col span={8} style={{textAlign: "start"}} >
                        {/* <Card title={<Title level={5}>{`Worker: ${urlParams.get("name")}`}</Title>}> */}
                        <Card title={`FFB Daily Collection`}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Created On:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{ffbDailyCollection.createdOn}</Card.Grid>
                    
                            { ffbDailyCollection.updatedOn != ffbDailyCollection.createdOn &&
                                <>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Updated On:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{ffbDailyCollection.updatedOn}</Card.Grid>
                                </>
                            }
                            { ffbDailyCollection.uploadedOn != "" &&
                                <>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Uploaded On:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{ffbDailyCollection.uploadedOn}</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Uploaded By:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{ffbDailyCollection.uploadedBy}</Card.Grid>
                                </>
                            }
                        </Card>
                    </Col>
                </Row>
                    
                <Row><Col><Space><div /></Space></Col></Row>

                <Form form={form} onFinish={onSave} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                    <Form.Item name="collectedOn" label="Collected On"
                        rules={[
                            { required: true, validator: validateCollectedOn },
                        ]}>
                        <DatePicker onChange={onCollectedOnChange} defaultValue={collectedOn} format={DATEFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item name="worker" label="Worker">
                        <WorkerSelect withBlank={false} allowClear={false} onChange={onWorkerChange} noStyle={true} marginLeft={10} defaultValue={ffbDailyCollection.worker}/>
                    </Form.Item> 

                    <Form.Item name="block" label="Block"
                        rules={[
                            { required: true, message: "Block is required."},
                        ]}>
                        <InputNumber min={0} max={100000} step={1} precision={0} placeholder="Block" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item name="numberOfBunches" label="Number of Bunches"
                        rules={[
                            { required: true, message: 'Number of bunches is required.' },
                        ]}>
                        <InputNumber min={0} max={100000} step={1} precision={0} placeholder="Number of Bunches" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item name="looseFruitBag" label="Loose Fruit (Bag)"
                        rules={[
                            { required: true, message: 'Loose fruit weight is required.' },
                        ]}>
                        <InputNumber min={0} max={100000} step={1} precision={2} placeholder="Loose Fruit (Bag)" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item name="remark" label="Remark">
                        <Input placeholder="Remark" style={{ marginLeft: 10, width: SEARCHOPTIONVIEWWIDTH }}/>
                    </Form.Item>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined/>}>Update</Button>
                            <Popconfirm title="Delete ffb collection record confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" onClick={onCancel} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                    title="FFB Daily Collection:"
                    subTitle="Update ffb daily collection record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default FFBDailyCollectionUpdate