import { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import { Form, Input, Button, message, Space, Layout, Row, Col, Popconfirm, Modal, Descriptions, Switch, Spin } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { SaveOutlined, CloseOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import MainHeader from '../common/header'
import MainFooter from '../common/footer'
import { isFloat, reportError } from '../common/utility'
import axios from "axios"
import { APIURL, LOADING, MENUPATH_SPECIES, SEARCHOPTIONVIEWWIDTH } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, retrieveBackgroundColor } from "../common/usersession"
import { walltiles } from '../common/layout'


const { Header, Footer, Content } = Layout
const { confirm } = Modal


const SpeciesUpdate = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
            
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [form] = Form.useForm()
    const [disableDeleteButton, setDisableDeleteButton] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isActive, setIsActive] = useState(urlParams.get("active") == "true" ? true: false)
                               
    const updateSpecies = () => {
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${APIURL}species/update/${urlParams.get("pkey")}/`, {
                id: values.speciesId,
                scientific_name: values.scientificName,
                general_name: values.generalName,
                active: isActive,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`Species ${values.generalName} updated.`)
                navigate(MENUPATH_SPECIES)
            })
            .catch( error => {
                reportError(error, "Failed to update species.")
            })
            .finally(() => {
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    const onDelete = () => {
        setIsLoading(true)
        
        axios.delete(`${APIURL}species/delete/${urlParams.get("pkey")}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Species ${urlParams.get("generalName")} deleted.`)
            navigate(MENUPATH_SPECIES)
        })
        .catch( error => {
            reportError(error, "Failed to delete species.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    // Determine if has related record.
    const toHideDeleteButton = () => {
        setIsLoading(true)

        axios.get(`${APIURL}speciesdownloaded/${urlParams.get("pkey")}/`, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response.data.downloaded) setDisableDeleteButton(true)
        })
        .catch( error => {
            reportError(error, "Failed to determine if species have batch.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onCancel = () => {
        navigate(MENUPATH_SPECIES)
    }

    const onUpdate = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Update species confirmed?</p></Space>,
            onOk() { updateSpecies() },
            onCancel() {},
            centered: true
        })
    }

    const onIsActiveChange = (checked) => {
        setIsActive(checked)
    }

    useEffect(() => {
        toHideDeleteButton()
        
        form.setFieldsValue({
            speciesId: urlParams.get("id"),
            generalName: urlParams.get("generalName"),
            scientificName: urlParams.get("scientificName"),
        })
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles, backgroundColor: retrieveBackgroundColor()}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onCancel()} 
                    title="Species">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update species</Descriptions.Item>
                    </Descriptions>
                </PageHeader>
        
                <Form form={form} onFinish={onUpdate} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                    <Form.Item name="generalName" label="New General Name"
                        rules={[
                            { required: true, message: "General name is required."},
                        ]}>
                        <Input maxLength={50} placeholder="General Name" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="scientificName" label="New Scientific Name"
                        rules={[
                            { required: true, message: "Scientific name is required."},
                        ]}>
                        <Input maxLength={50} placeholder="Scientific Name" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="speciesId" label="New Species ID"
                        rules={[
                            { required: true, message: "Species ID is required."},
                        ]}>
                        <Input maxLength={3} minLength={3} placeholder="Species ID (3 alphanumeric)" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="isActive" label="Is Active">
                        <Switch defaultChecked={isActive} onChange={onIsActiveChange} style={{ marginLeft: 10 }}/>
                    </Form.Item>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>Update</Button>
                            <Popconfirm title="Delete species confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" disabled={disableDeleteButton} icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" onClick={onCancel} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                        title="Species:"
                        subTitle="Update species"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default SpeciesUpdate