import { Table, Row, Col, Space, Button, Collapse, Pagination, message, Spin, DatePicker, InputNumber } from 'antd'
import { DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, LOADING, PAGESIZE, PAGINATIONSIZE, SEARCHOPTIONVIEWWIDTH, UNIDATETIMEFORMAT, UNIDATEFORMAT, DATEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserEstateId, getUserEstateName } from "../common/usersession"
import { reportError } from '../common/utility'
import dayjs from 'dayjs';

const { Panel } = Collapse
const { RangePicker } = DatePicker


const FFBCollectionSummaryReportTable = () => {
    const FileDownload = require('js-file-download')
    
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const [ffbCollectionDataSource, setFFBCollectionDataSource] = useState([])
    const [collectedOnDateRange, setCollectedOnDateRange] = useState([dayjs().add(-1, 'month').add(1, 'day'), dayjs()])
    const [block, setBlock] = useState(-1)
    
    const getSummary = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}ffbcollectionsummarypaginated/`, {
            params: { 
                estate: getUserEstateId(),
                block: block,
                from_date: collectedOnDateRange[0] == null ? null : collectedOnDateRange[0].format(UNIDATEFORMAT),
                to_date: collectedOnDateRange[1] == null ? null : collectedOnDateRange[1].format(UNIDATEFORMAT),
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 2,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search ffb collection summary.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( summary => {
            data.push({
                key: summary.id,
                worker: summary.name,
                employeeId: summary.employee_id,
                block: summary.block,
                collectedDate: dayjs(dayjs(summary.collected_date, "YYYY-MM-DD")).format(DATEFORMAT),
                numberOfBunches: summary.number_of_bunches,
                looseFruitBag: summary.loose_fruit_bag,
            })
        })
        
        setFFBCollectionDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const downloadSearchResult = (mode) => {
        setIsLoading(true)
                        
        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Estate: ${getUserEstateName()}`
        if(block != -1) searchCriteria = `${searchCriteria}\nBlock: ${block}`
        if(collectedOnDateRange[0] != null) searchCriteria = `${searchCriteria}\nCollected On: ${collectedOnDateRange[0].format(DATEFORMAT)} to ${collectedOnDateRange[1].format(DATEFORMAT)}`
                                                                
        axios.get(`${APIURL}ffbcollectionsummary/download/`, {
            params: { 
                estate: getUserEstateId(),
                block: block,
                from_date: collectedOnDateRange[0] == null ? null : collectedOnDateRange[0].format(UNIDATEFORMAT),
                to_date: collectedOnDateRange[1] == null ? null : collectedOnDateRange[1].format(UNIDATEFORMAT),
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 12,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `FFB Collection Summary ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onPaginationChange = (page) => {
        setCurrentPage(page)
        getSummary(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSearch = () => {
        setCurrentPage(1)
        getSummary(1)
    }

    const onReset = () => {
        window.location.reload()
    }

    const onCollectedOnDateRangeChange = (dateRange) => {
        if(dateRange == null)
            setCollectedOnDateRange([null, null])
        else
            setCollectedOnDateRange(dateRange)
    }

    const onBlockChange = (e, value) => {
        if(e !== null)
            setBlock(e)
        else 
            setBlock(-1)
    }

    useEffect(() => {
        getSummary(currentPage)
    }, [])

    const columns = [
        { title: 'Worker', dataIndex: 'worker', key: 'worker', align: 'center', sorter: (a, b) => a.worker.localeCompare(b.worker) },
        { title: 'Employee ID', dataIndex: 'employeeId', key: 'employeeId', align: 'center', sorter: (a, b) => a.employeeId.localeCompare(b.employeeId) },
        { title: 'Block', dataIndex: 'block', key: 'block', align: 'center', sorter: (a, b) => a.block - b.block },
        { title: 'Collected On', dataIndex: 'collectedDate', key: 'collectedDate', align: "center", sorter: (a, b) => a.collectedDate.localeCompare(b.collectedDate) },
        { title: 'Bunches', dataIndex: 'numberOfBunches', key: 'numberOfBunches', align: 'right', sorter: (a, b) => a.numberOfBunches - b.numberOfBunches },
        { title: 'Loose Fruit (Bag)', dataIndex: 'looseFruitBag', key: 'looseFruitBag', align: 'right', sorter: (a, b) => a.looseFruitBag - b.looseFruitBag },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search ffb collection" key="1">
                    <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Block :</Col>
                            <Col span={3}>
                                <InputNumber onChange={onBlockChange} style={{ width: SEARCHOPTIONVIEWWIDTH }} min={0} max={100000} step={1} precision={0} placeholder="Block"/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Collected On :</Col>
                            <Col span={3}>
                                <RangePicker onChange={onCollectedOnDateRangeChange} defaultValue={collectedOnDateRange} format={DATEFORMAT}/>
                            </Col>

                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            </>
                        </Row>   

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} loading={isLoading} icon={<DownloadOutlined />} />
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} loading={isLoading} icon={<FileExcelOutlined />} />
                                <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                        
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={ffbCollectionDataSource} pagination={false} />

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={24} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
        </Row>
        </Spin>
        </>
    )
}

export default FFBCollectionSummaryReportTable