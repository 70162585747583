import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { APIURL, ALLOWED_ESTATES, MEDIAMAXWIDTH, MENUPATH_DELIVERYTRIP, MENUPATH_TREE, SEARCHOPTIONVIEWWIDTH, MENUPATH_DASHBOARD } from "./systemparameter"
import { reportError } from "./utility"
import { getUserAuthToken, getUserEstateId, setUserEstate, refreshUserSession, OTHERSYSPARAM, SYSPARAM, getUserGroup } from "./usersession"
import { useNavigate } from "react-router"
import { useMediaQuery } from 'react-responsive'

const { Option } = Select


const MainEstateSelect = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const navigate = useNavigate()
    const [estateOption, setEstateOption] = useState([])
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : SEARCHOPTIONVIEWWIDTH)
                
    //-------------------
    // Handle estate change
    //-------------------
    const onEstateChange = (estateId) => {
        const whichEstate = estateOption.filter( option => option.key == estateId )
        setUserEstate(estateId, whichEstate[0].props.children)

        // if(getUserGroup().includes(OTHERSYSPARAM("SYSTEMIMPLEMENTOR")) || getUserGroup().includes(OTHERSYSPARAM("ADMINOFFICER")) || getUserGroup().includes(OTHERSYSPARAM("MANDUR")) || getUserGroup().includes(OTHERSYSPARAM("FIELDCONDUCTOR")))
        //     navigate(MENUPATH_TREE)
        // else if(getUserGroup().includes(OTHERSYSPARAM("SYSTEMIMPLEMENTOR")))
        //     navigate(MENUPATH_DELIVERYTRIP)
        navigate(MENUPATH_DASHBOARD)
        
        window.location.reload()
    }

    const getAllEstate = () => {
        axios.get(`${APIURL}estate/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            // To add user's allowed sites ONLY.
            const data = response.data.results.filter( estate => String(SYSPARAM(ALLOWED_ESTATES)).includes(estate.pkey) )

            const options = data.map( estate => <Option key={estate.pkey}>{estate.name}</Option> )
            setEstateOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get estate(s)")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    useEffect(() => {
        getAllEstate()
    }, [])
    
    return(
        <Select style={{width: optionWidth}} defaultValue={getUserEstateId()} onChange={onEstateChange}>
            {estateOption}
        </Select>
    )
}

export default MainEstateSelect