import { Table, Row, Col, Space, Button, Collapse, Pagination, message, Spin, DatePicker, Image, Modal, Badge } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT, FALLBACK, LOADING, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT, UNIDATETIMEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserEstateId, getUserEstateName } from "../common/usersession"
import { useNavigate } from "react-router"
import { reportError } from '../common/utility'
import dayjs from 'dayjs';
import AttendanceTypeSelect from './atttendancetypeselect'
import WorkerSelect from '../common/workerselect'

const { Panel } = Collapse
const { confirm } = Modal
const { RangePicker } = DatePicker


const AttendanceTable = () => {
    const FileDownload = require('js-file-download')
    const navigate = useNavigate()

    const [dateRange, setDateRange] = useState([dayjs().add(-1, 'month').add(1, 'day'), dayjs()])
    const [workerPKey, setWorkerPKey] = useState(0)
    const [worker, setWorker] = useState("")
    const [attendanceTypePKey, setAttendanceTypePKey] = useState(0)
    const [attendanceType, setAttendanceType] = useState("")
    const [attendanceDataSource, setAttendanceDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [previewPhotos, setPreviewPhotos] = useState([])
        
    const searchAttendance = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}attendancepaginated/`, {
            params: { 
                estate: getUserEstateId(),
                from_date: dateRange[0] == null ? null : dateRange[0].format(UNIDATEFORMAT),
                to_date: dateRange[1] == null ? null : dateRange[1].format(UNIDATEFORMAT),
                worker: workerPKey,
                attendance_type: attendanceTypePKey,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search worker attendance.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( attendance => {
            data.push({
                key: attendance.pkey,
                workerPKey: attendance.worker,
                name: attendance.worker > 0 ? attendance.worker_data.name : attendance.unregistered_worker_name,
                employeeId: attendance.worker_data.employee_id,
                checker: attendance.checker_data.username,
                attendanceTypePKey: attendance.attendance_type_data.pkey,
                attendanceType: attendance.attendance_type_data.name,
                clockedOn: dayjs(attendance.clocked_on).format(DATETIMEFORMAT),
                clockedOnUnformatted: dayjs(attendance.clocked_on).format(UNIDATETIMEFORMAT),
                createdOn: dayjs(attendance.created_on).format(DATETIMEFORMAT),
                updatedOn: dayjs(attendance.updated_on).format(DATETIMEFORMAT),
                uploadedOn: attendance.uploaded_on != null ? dayjs(attendance.uploaded_on).format(DATETIMEFORMAT) : '',
                uploadedBy: attendance.uploader_data != null ? attendance.uploader_data.username : '',
                deviceId: attendance.device_id,
                remark: attendance.remark,
                img: `${attendance?.photo_data[0]?.img}?now=${dayjs().valueOf()}`,
                photoCount: `${attendance.photo_data.length}`,
                photos: attendance?.photo_data
            })
        })
        
        setAttendanceDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const downloadSearchResult = (mode) => {
        if(dateRange[0] == null) {
            message.warning('Date range is required.')
            return
        }

        setIsLoading(true)
                        
        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Estate: ${getUserEstateName()}`
        searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(workerPKey != 0) searchCriteria = `${searchCriteria}\nWorker: ${worker}`
        if(attendanceTypePKey != 0) searchCriteria = `${searchCriteria}\nAttendanceType: ${attendanceType}`
                                        
        axios.get(`${APIURL}attendance/download/`, {
            params: { 
                estate: getUserEstateId(),
                from_date: dateRange[0] == null ? null : dateRange[0].format(UNIDATEFORMAT),
                to_date: dateRange[1] == null ? null : dateRange[1].format(UNIDATEFORMAT),
                worker: workerPKey,
                attendance_type: attendanceTypePKey,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 12),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Attendance ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const showPhotos = (e, record) => {
        e.stopPropagation()

        const photos = []
        record.photos.forEach( photo => {
            photos.push(<Image fallback={FALLBACK} src={`${photo.img}?now=${new Date()}`}/>)
        })
        
        setPreviewPhotos(photos)
        setVisible(true)
    }

    const onDateRangeChange = (dateRange) => {
        if(dateRange == null)
            setDateRange([null, null])
        else
            setDateRange(dateRange)
    }

    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchAttendance(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSearch = () => {
        setCurrentPage(1)
        searchAttendance(1)
    }

    const onReset = () => {
        window.location.reload()
    }

    const onCreateNew = () => {
        navigate("/attendancenew")
    }

    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/attendanceupdate", 
                    search: `?attendance=${JSON.stringify(record)}`
                }) 
            }
        }
    }

    const onSearchWorkerChange = (e, value) => {
        if(e != undefined) {
            setWorkerPKey(e)
            setWorker(value.children.join(''))
        }
        else {
            setWorkerPKey(0)
            setWorker("")
        }
    }

    const onAttendanceTypeChange = (e, value) => {
        if(e != undefined) {
            setAttendanceTypePKey(e)
            setAttendanceType(value.children)
        }
        else {
            setAttendanceTypePKey(0)
            setAttendanceType("")
        }
    }

    useEffect(() => {
        searchAttendance(currentPage)
    }, [])

    const columns = [
        { title: 'Worker', dataIndex: 'name', key: 'name', align: 'center', sorter: (a, b) => a.name.localeCompare(b.name), 
            render: (name, record) => {
                return {
                    props: {
                        style: {background: record.workerPKey > 0 ? '' : 'coral'}
                    },
                    children: <div>{name}</div>
                }
            }
        },
        { title: 'Employee ID', dataIndex: 'employeeId', key: 'employeeId', align: 'center', sorter: (a, b) => a.employeeId.localeCompare(b.employeeId) },
        { title: 'Attendance Type', dataIndex: 'attendanceType', key: 'attendanceType', align: 'center', sorter: (a, b) => a.attendanceType.localeCompare(b.attendanceType) },
        { title: 'Checker', dataIndex: 'checker', key: 'checker', align: 'center', sorter: (a, b) => a.checker.localeCompare(b.checker) },
        { title: 'Clocked On', dataIndex: 'clockedOn', key: 'clockedOn', align: 'center', sorter: (a, b) => a.clockedOn.localeCompare(b.clockedOn) },
        { title: 'Uploaded By', dataIndex: 'uploadedBy', key: 'uploadedBy', align: 'center', sorter: (a, b) => a.uploadedBy.localeCompare(b.uploadedBy) },
        { title: 'Uploaded On', dataIndex: 'uploadedOn', key: 'uploadedOn', align: 'center', sorter: (a, b) => a.uploadedOn.localeCompare(b.uploadedOn) },
        { title: 'Device ID', dataIndex: 'deviceId', key: 'deviceId', align: 'center', sorter: (a, b) => a.deviceId.localeCompare(b.deviceId) },
        { title: 'Photo', dataIndex: 'img', key: 'img', align: 'center',
            render: (img, record) => {
                if(img != null) 
                    return <>
                        <Badge count={record.photoCount}>
                            <Image width={50} fallback={FALLBACK} src={img} preview={false} onClick={(e) => showPhotos(e, record) }/>
                        </Badge>
                    </>
                else
                    return null
            }
        },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search attendance" key="1">
                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Worker :</Col>
                            <Col span={3}>
                                <WorkerSelect withBlank={true} allowClear={true} onChange={onSearchWorkerChange} noStyle={true}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Attendance Type :</Col>
                            <Col span={3}>
                                <AttendanceTypeSelect withBlank={true} allowClear={true} onChange={onAttendanceTypeChange} noStyle={true} />
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Date Range :</Col>
                            <Col span={3}>
                                <RangePicker onChange={onDateRangeChange} defaultValue={dateRange} format={DATEFORMAT}/>
                            </Col>
                            </>
                        </Row>   

                        <Row><Col><Space><div /></Space></Col></Row>
                        
                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} loading={isLoading} icon={<DownloadOutlined />} />
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} loading={isLoading} icon={<FileExcelOutlined />} />
                                <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table 
            bordered 
            columns={columns} 
            dataSource={attendanceDataSource} 
            pagination={false} 
            onRow={onRowClick} />


        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <div style={{ display: 'none'}}>
            <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}>{previewPhotos}</Image.PreviewGroup>
        </div>
        
        </Spin>
        </>
    )
}

export default AttendanceTable