import { Table, Row, Col, Space, Button, Collapse, Pagination, message, Spin, DatePicker, InputNumber } from 'antd'
import { DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, LOADING, NONEDATE, PAGESIZE, PAGINATIONSIZE, SEARCHOPTIONVIEWWIDTH, UNIDATETIMEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserEstateId, getUserEstateName, SYSPARAM } from "../common/usersession"
import { reportError } from '../common/utility'
import dayjs from 'dayjs';
import SpeciesSelect from '../common/speciesselect'
import GenderSelect from '../common/genderselect'
import HealthConditionSelect from '../common/healthconditionselect'
import TrueFalseSelect from '../common/truefalseselect'
//import TrueFalseSelect from '../common/truefalseselect'

const { Panel } = Collapse
const { RangePicker } = DatePicker


const TreeSummaryReportTable = () => {
    const FileDownload = require('js-file-download')
    
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const [treeDataSource, setTreeDataSource] = useState([])
    const [speciesPKey, setSpeciesPKey] = useState(0)
    const [species, setSpecies] = useState('')
    const [genderPKey, setGenderPKey] = useState(0)
    const [gender, setGender] = useState('')
    const [healthConditionPKey, setHealthConditionPKey] = useState(0)
    const [healthCondition, setHealthCondition] = useState('')
    const [plantedOnDateRange, setPlantedOnDateRange] = useState([null, null])
    const [deadOnDateRange, setDeadOnDateRange] = useState([null, null])
    const [block, setBlock] = useState(-1)
    const [row, setRow] = useState(-1)
    
    const [isLiving, setIsLiving] = useState(true)
    const [isLivingLabel, setIsLivingLabel] = useState(OTHERSYSPARAM('YES'))
        
    const getSummary = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}treesummarypaginated/`, {
            params: { 
                estate: getUserEstateId(),
                block: block,
                row: row,
                species: speciesPKey,
                gender: genderPKey,
                health_condition: healthConditionPKey,
                planted_from_year: plantedOnDateRange[0] == null ? null : plantedOnDateRange[0].$y,
                planted_to_year: plantedOnDateRange[1] == null ? null : plantedOnDateRange[1].$y,
                dead_from_year: deadOnDateRange[0] == null ? null : deadOnDateRange[0].$y,
                dead_to_year: deadOnDateRange[1] == null ? null : deadOnDateRange[1].$y,
                is_living: isLiving,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 2,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search tree summary.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( summary => {
            data.push({
                key: summary.id,
                block: summary.block,
                row: summary.row,
                species: summary.species_name,
                gender: summary.gender_name,
                healthCondition: summary.health_condition_name,
                plantedInYear: summary.planted_year,
                deadInYear: summary.dead_year == NONEDATE.substring(0, 4) ? '-' : summary.dead_year,
                ageYear: summary.age_year,
                quantity: summary.quantity
            })
        })
        
        setTreeDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const downloadSearchResult = (mode) => {
        setIsLoading(true)
                        
        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Estate: ${getUserEstateName()}`
        if(block != -1) searchCriteria = `${searchCriteria}\nBlock: ${block}`
        if(row != -1) searchCriteria = `${searchCriteria}\nRow: ${row}`
        if(speciesPKey != 0) searchCriteria = `${searchCriteria}\nSpecies: ${species}`
        if(genderPKey != 0) searchCriteria = `${searchCriteria}\nGender: ${gender}`
        if(healthConditionPKey != 0) searchCriteria = `${searchCriteria}\nHealth Condition: ${healthCondition}`
        if(plantedOnDateRange[0] != null) searchCriteria = `${searchCriteria}\nPlanted In Year: ${plantedOnDateRange[0].$y} to ${plantedOnDateRange[1].$y}`
        if(deadOnDateRange[0] != null) searchCriteria = `${searchCriteria}\nDead In Year: ${deadOnDateRange[0].$y} to ${deadOnDateRange[1].$y}`
        if(isLiving != '') searchCriteria = `${searchCriteria}\nIs Living: ${isLivingLabel}`
                                                        
        axios.get(`${APIURL}treesummary/download/`, {
            params: { 
                estate: getUserEstateId(),
                block: block,
                row: row,
                species: speciesPKey,
                gender: genderPKey,
                health_condition: healthConditionPKey,
                planted_from_year: plantedOnDateRange[0] == null ? null : plantedOnDateRange[0].$y,
                planted_to_year: plantedOnDateRange[1] == null ? null : plantedOnDateRange[1].$y,
                dead_from_year: deadOnDateRange[0] == null ? null : deadOnDateRange[0].$y,
                dead_to_year: deadOnDateRange[1] == null ? null : deadOnDateRange[1].$y,
                is_living: isLiving,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 12,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Tree Summary ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onPaginationChange = (page) => {
        setCurrentPage(page)
        getSummary(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSearch = () => {
        setCurrentPage(1)
        getSummary(1)
    }

    const onReset = () => {
        window.location.reload()
    }

    const onPlantedOnDateRangeChange = (dateRange) => {
        if(dateRange != null)
            setPlantedOnDateRange(dateRange)
        else
            setPlantedOnDateRange([null, null])
    }

    const onDeadOnDateRangeChange = (dateRange) => {
        if(dateRange != null)
            setDeadOnDateRange(dateRange)
        else
            setDeadOnDateRange([null, null])
    }

    const onSpeciesChange = (e, value) => {
        setSpeciesPKey(e)
        setSpecies(value.children)
    }

    const onGenderChange = (e, value) => {
        setGenderPKey(e)
        setGender(value.children)
    }

    const onHealthConditionChange = (e, value) => {
        setHealthConditionPKey(e)
        setHealthCondition(value.children)
    }

    const onBlockChange = (e, value) => {
        if(e !== null)
            setBlock(e)
        else 
            setBlock(-1)
    }

    const onRowChange = (e, value) => {
        if(e !== null)
            setRow(e)
        else 
            setRow(-1)
    }

    const onIsLivingChange = (e, value) => {
        setIsLiving(e)
        setIsLivingLabel(value.children)
    }

    useEffect(() => {
        getSummary(currentPage)
    }, [])

    const columns = [
        { title: 'Block', dataIndex: 'block', key: 'block', align: 'center', sorter: (a, b) => a.block - b.block },
        { title: 'Row', dataIndex: 'row', key: 'row', align: 'center', sorter: (a, b) => a.row - b.row },
        { title: 'Species', dataIndex: 'species', key: 'species', align: 'center', sorter: (a, b) => a.species.localeCompare(b.species) },
        { title: 'Gender', dataIndex: 'gender', key: 'gender', align: 'center', sorter: (a, b) => a.gender.localeCompare(b.gender) },
        { title: 'Health Condition', dataIndex: 'healthCondition', key: 'healthCondition', align: 'center', sorter: (a, b) => a.healthCondition.localeCompare(b.healthCondition) },
        { title: 'Planted In Year', dataIndex: 'plantedInYear', key: 'plantedInYear', align: 'center', sorter: (a, b) => a.plantedInYear - b.plantedInYear },
        { title: 'Dead In Year', dataIndex: 'deadInYear', key: 'deadInYear', align: 'center', sorter: (a, b) => a.deadInYear - b.deadInYear },
        { title: 'Age (Year)', dataIndex: 'ageYear', key: 'ageYear', align: 'center', sorter: (a, b) => a.ageYear - b.ageYear },
        { title: 'Tree Count', dataIndex: 'quantity', key: 'quantity', align: 'right', sorter: (a, b) => a.quantity - b.quantity },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search tree" key="1">
                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Block :</Col>
                            <Col span={3}>
                                <InputNumber onChange={onBlockChange} style={{ width: SEARCHOPTIONVIEWWIDTH }} min={0} max={100000} step={1} precision={0} placeholder="Block"/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Species :</Col>
                            <Col span={3}>
                                <SpeciesSelect withBlank={true} onChange={onSpeciesChange} allowClear={true} noStyle={true}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Planted In Year :</Col>
                            <Col span={3}>
                                <RangePicker onChange={onPlantedOnDateRangeChange} defaultValue={plantedOnDateRange} format="YYYY" picker="year" allowClear/>
                            </Col>
                            </>
                        </Row>   

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Row :</Col>
                            <Col span={3}>
                                <InputNumber onChange={onRowChange} style={{ width: SEARCHOPTIONVIEWWIDTH }} min={0} max={100000} step={1} precision={0} placeholder="Row"/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Gender :</Col>
                            <Col span={3}>
                                <GenderSelect withBlank={true} onChange={onGenderChange} allowClear={true} noStyle={true}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Dead In Year :</Col>
                            <Col span={3}>
                                <RangePicker onChange={onDeadOnDateRangeChange} defaultValue={deadOnDateRange} format="YYYY" picker="year" allowClear/>
                            </Col>
                            </>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <>
                            <Col span={6} />
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 20 }}>Health Condition :</Col>
                            <Col span={3}>
                                <HealthConditionSelect withBlank={true} onChange={onHealthConditionChange} allowClear={true} noStyle={true}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Is Living :</Col>
                            <Col span={3}>
                                <TrueFalseSelect withBlank={true} defaultValue={isLivingLabel} onChange={onIsLivingChange} noStyle={true}/>
                            </Col>
                            </>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} loading={isLoading} icon={<DownloadOutlined />} />
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} loading={isLoading} icon={<FileExcelOutlined />} />
                                <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                        
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={treeDataSource} pagination={false} />

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={24} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
        </Row>
        </Spin>
        </>
    )
}

export default TreeSummaryReportTable