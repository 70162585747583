import { Table, Row, Col, Space, Button, Collapse, Pagination, message, Spin, DatePicker, Image, InputNumber, Input, Badge } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT, FALLBACK, LOADING, PAGESIZE, PAGINATIONSIZE, SEARCHOPTIONVIEWWIDTH, UNIDATEFORMAT, UNIDATETIMEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserEstateId, getUserEstateName, retrieveTreeBlock, retrieveTreeRow, storeTreeBlock, storeTreeRow } from "../common/usersession"
import { useNavigate } from "react-router"
import { reportError, validPalmSearchRange } from '../common/utility'
import dayjs from 'dayjs';
import SearchTreeByLocation from '../common/searchtreebylocation'
import HerbicidePesticideSelect from '../common/herbicidepesticideselect'

const { Panel } = Collapse
const { RangePicker } = DatePicker

const SprayingTable = () => {
    const FileDownload = require('js-file-download')
    const navigate = useNavigate()

    const [sprayingDataSource, setSprayingDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const [sprayedOnDateRange, setSprayedOnDateRange] = useState([dayjs().add(-1, 'month').add(1, 'day'), dayjs()])
    //const [block, setBlock] = useState(-1)
    //const [row, setRow] = useState(-1)
    const [block, setBlock] = useState(!isNaN(retrieveTreeBlock()) ? retrieveTreeBlock() : -1)
    const [row, setRow] = useState(!isNaN(retrieveTreeRow()) ? retrieveTreeRow() : -1)
    const [number, setNumber] = useState('')
    const [herbicidePesticidePKey, setHerbicidePesticidePKey] = useState(0)
    const [herbicidePesticide, setHerbicidePesticide] = useState('')
    // const [isLiving, setIsLiving] = useState(true)
    // const [isLivingLabel, setIsLivingLabel] = useState(OTHERSYSPARAM('YES'))

    const [showSearchTree, setShowSearchTree] = useState(false)
    const [visible, setVisible] = useState(false)
    const [previewPhotos, setPreviewPhotos] = useState([])
    
    const searchSpraying = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}sprayingpaginated/`, {
            params: { 
                estate: getUserEstateId(),
                block: block,
                row: row,
                number: number,
                from_date: sprayedOnDateRange[0] == null ? null : sprayedOnDateRange[0].format(UNIDATEFORMAT),
                to_date: sprayedOnDateRange[1] == null ? null : sprayedOnDateRange[1].format(UNIDATEFORMAT),
                herbicidepesticide: herbicidePesticidePKey,
                //is_living: isLiving,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)

            if(response.data.results.length > 0) {
                storeTreeBlock(block)
                storeTreeRow(row)
            }
        })
        .catch( error => {
            reportError(error, "Failed to search spraying activity.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( spraying => {
            data.push({
                key: spraying.pkey,
                treeId: spraying.tree_data.tree_id,
                sprayedOn: dayjs(spraying.sprayed_on).format(DATETIMEFORMAT),
                sprayedOnUnformatted: dayjs(spraying.sprayed_on).format(UNIDATETIMEFORMAT),
                herbicidePesticidePKey: spraying.herbicidepesticide_data.pkey,
                herbicidePesticide: spraying.herbicidepesticide_data.name,
                remark: spraying.remark,
                createdOn: dayjs(spraying.created_on).format(DATETIMEFORMAT),
                updatedOn: dayjs(spraying.updated_on).format(DATETIMEFORMAT),
                uploadedOn: spraying.uploaded_on != null ? dayjs(spraying.uploaded_on).format(DATETIMEFORMAT) : '',
                uploadedBy: spraying.uploader_data != null ? spraying.uploader_data.username : '',
                img: `${spraying?.photo_data[0]?.img}?now=${dayjs().valueOf()}`,
                photoCount: `${spraying.photo_data.length}`,
                photos: spraying?.photo_data
            })
        })
        
        setSprayingDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const downloadSearchResult = (mode) => {
        if(sprayedOnDateRange[0] == null) {
            message.warning('Date range is required.')
            return
        }

        setIsLoading(true)
                        
        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Estate: ${getUserEstateName()}`
        if(block != -1) searchCriteria = `${searchCriteria}\nBlock: ${block}`
        if(row != -1) searchCriteria = `${searchCriteria}\nRow: ${row}`
        if(number != '') searchCriteria = `${searchCriteria}\nNumber: ${number}`
        if(sprayedOnDateRange[0] != null) searchCriteria = `${searchCriteria}\nPruned On: ${sprayedOnDateRange[0].format(DATEFORMAT)} to ${sprayedOnDateRange[1].format(DATEFORMAT)}`
        if(herbicidePesticidePKey != 0) searchCriteria = `${searchCriteria}\nHerbicide/Pesticide: ${herbicidePesticide}`
        //if(isLiving != '') searchCriteria = `${searchCriteria}\nIs Living: ${isLivingLabel}`
                                                
        axios.get(`${APIURL}spraying/download/`, {
            params: { 
                estate: getUserEstateId(),
                block: block,
                row: row,
                number: number,
                from_date: sprayedOnDateRange[0] == null ? null : sprayedOnDateRange[0].format(UNIDATEFORMAT),
                to_date: sprayedOnDateRange[1] == null ? null : sprayedOnDateRange[1].format(UNIDATEFORMAT),
                herbicidepesticide: herbicidePesticidePKey,
                //is_living: isLiving,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 12),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Spraying ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const showPhotos = (e, record) => {
        e.stopPropagation()

        const photos = []
        record.photos.forEach( photo => {
            photos.push(<Image fallback={FALLBACK} src={`${photo.img}?now=${new Date()}`}/>)
        })
        
        setPreviewPhotos(photos)
        setVisible(true)
    }
    
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchSpraying(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSearch = () => {
        if(!validPalmSearchRange(number)) {
            message.warning('Invalid palm search range.')
            return
        }

        setCurrentPage(1)
        searchSpraying(1)
    }

    const onReset = () => {
        window.location.reload()
    }

    const onCreateNew = () => {
        setShowSearchTree(true)
    }

    const navigateTo = (tree) => {
        navigate({
            pathname: '/sprayingnew',
            search: `?tree=${JSON.stringify(tree)}`
        })
    }

    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/sprayingupdate", 
                    search: `?spraying=${JSON.stringify(record)}`
                }) 
            }
        }
    }

    const onBlockChange = (e, value) => {
        if(e !== null)
            setBlock(e)
        else 
            setBlock(-1)
    }

    const onRowChange = (e, value) => {
        if(e !== null)
            setRow(e)
        else 
            setRow(-1)
    }

    const onNumberChange = (e) => {
        setNumber(e.target.value.trim())
    }

    const onSprayedOnDateRangeChange = (dateRange) => {
        if(dateRange == null)
            setSprayedOnDateRange([null, null])
        else
            setSprayedOnDateRange(dateRange)
    }

    const onHerbicidePesticideChange = (e, value) => {
        setHerbicidePesticidePKey(e)
        setHerbicidePesticide(value.children)
    }

    // const onIsLivingChange = (e, value) => {
    //     setIsLiving(e)
    //     setIsLivingLabel(value.children)
    // }

    useEffect(() => {
        searchSpraying(currentPage)
    }, [])

    const columns = [
        { title: 'Tree ID', dataIndex: 'treeId', key: 'treeId', align: 'center', sorter: (a, b) => a.treeId.localeCompare(b.treeId) },
        { title: 'Sprayed On', dataIndex: 'sprayedOn', key: 'sprayedOn', align: 'center',sorter: (a, b) => a.sprayedOn.localeCompare(b.sprayedOn) },
        { title: 'Herbicide/Pesticide', dataIndex: 'herbicidePesticide', key: 'herbicidePesticide', align: 'center',sorter: (a, b) => a.herbicidePesticide.localeCompare(b.herbicidePesticide) },
        { title: 'Uploaded By', dataIndex: 'uploadedBy', key: 'uploadedBy', align: 'center',sorter: (a, b) => a.uploadedBy.localeCompare(b.uploadedBy) },
        { title: 'Uploaded On', dataIndex: 'uploadedOn', key: 'uploadedOn', align: 'center',sorter: (a, b) => a.uploadedOn.localeCompare(b.uploadedOn) },
        { title: 'Photo', dataIndex: 'img', key: 'img', align: 'center',
            render: (img, record) => {
                if(img != null) 
                    return <>
                        <Badge count={record.photoCount}>
                            <Image width={50} fallback={FALLBACK} src={img} preview={false} onClick={(e) => showPhotos(e, record) }/>
                        </Badge>
                    </>
                else
                    return null
            }
        },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search spraying" key="1">
                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Block :</Col>
                            <Col span={3}>
                                <InputNumber defaultValue={block != -1 ? block : ''} onChange={onBlockChange} style={{ width: SEARCHOPTIONVIEWWIDTH }} min={0} max={100000} step={1} precision={0} placeholder="Block"/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Sprayed On :</Col>
                            <Col span={3}>
                                <RangePicker onChange={onSprayedOnDateRangeChange} defaultValue={sprayedOnDateRange} format={DATEFORMAT}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            </>
                        </Row>   

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Row :</Col>
                            <Col span={3}>
                                <InputNumber defaultValue={row != -1 ? row : ''} onChange={onRowChange} style={{ width: SEARCHOPTIONVIEWWIDTH }} min={0} max={100000} step={1} precision={0} placeholder="Row"/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Herbicide/Pesticide :</Col>
                            <Col span={3}>
                                <HerbicidePesticideSelect withBlank={true} allowClear={true} onChange={onHerbicidePesticideChange} noStyle={true}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            </>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', paddingRight: 10 }}>Palm :</Col>
                            <Col span={3}>
                                <Input onChange={onNumberChange} style={{ width: SEARCHOPTIONVIEWWIDTH }} placeholder={"Use \",\" and \"-\" for multiple palms"}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            {/* <Col span={3}>Is Living:</Col>
                            <Col span={3}>
                                <TrueFalseSelect withBlank={true} defaultValue={isLivingLabel} onChange={onIsLivingChange} noStyle={true}/>
                            </Col> */}
                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            </>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>
                        
                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} loading={isLoading} icon={<DownloadOutlined />} />
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} loading={isLoading} icon={<FileExcelOutlined />} />
                                <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={sprayingDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <SearchTreeByLocation show={showSearchTree} toClose={() => setShowSearchTree(false)} navigationCallBack={navigateTo} />
        
        <div style={{ display: 'none'}}>
            <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}>{previewPhotos}</Image.PreviewGroup>
        </div>
        
        </Spin>
        </>
    )
}

export default SprayingTable