import { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import { Form, Button, Space, Layout, Row, Col, Popconfirm, message, DatePicker, Card, Modal, Input, Descriptions, Image, Upload, Spin, InputNumber, Switch, Table } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { SaveOutlined, CloseOutlined, DeleteOutlined, QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons"
import MainHeader from '../common/header'
import MainFooter from '../common/footer'
import { APIURL, FALLBACK, LOADING, DATETIMEFORMAT, UNIDATETIMEFORMAT, MENUPATH_HARVESTING, SEARCHOPTIONVIEWWIDTH, MAXPHOTOS, URL } from "../common/systemparameter"
import { reportError } from "../common/utility"
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserGroup, retrieveBackgroundColor } from "../common/usersession"
import dayjs from 'dayjs'
import { walltiles } from '../common/layout'

const { Header, Footer, Content } = Layout
const { confirm } = Modal


const HarvestingUpdate = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [uploading, setUploading] = useState(false)

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)
    const harvesting = JSON.parse(urlParams.get('harvesting'))

    const [numberOfBunches, setNumberOfBunches] = useState(harvesting.numberOfBunches)
    const [estimatedTotalWeightKg, setEstimatedTotalWeightKg] = useState(harvesting.estimatedTotalWeightKg)
    const [withPruning, setWithPruning] = useState(harvesting.withPruning == OTHERSYSPARAM('YES') ? true : false)
    const [harvestedOn, setHarvestedOn] = useState(dayjs(harvesting.harvestedOnUnformatted), UNIDATETIMEFORMAT)
    const [photoFile, setPhotoFile] = useState([])
    const [photoDataSource, setPhotoDataSource] = useState([])

    const disable = getUserGroup() == OTHERSYSPARAM('SYSTEMIMPLEMENTOR') ? false : true

    const removePhoto = (e, pkey) => {
        e.stopPropagation()
        setIsLoading(true)
        
        axios.patch(`${APIURL}harvesting/removephoto/${pkey}/`, {}, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info("Photo removed.")
            setPhotoDataSource(photoDataSource.filter(photo => photo.pkey != pkey))
        })
        .catch( error => {
            reportError(error, `Failed to remove harvesting photo.`)
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }
    
    const updateHarvesting = () => {
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${APIURL}harvesting/update/${harvesting.key}/`, {
                number_of_bunches: numberOfBunches,
                estimated_total_weight_kg: estimatedTotalWeightKg,
                with_pruning: withPruning,
                harvested_on: harvestedOn,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Harvesting record updated.")
                navigate(MENUPATH_HARVESTING)
            })
            .catch( error => {
                reportError(error, `Failed to update harvesting record.`)
            })
            .finally(() => {
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }

    const onDelete = () => {
        setIsLoading(true)
        
        axios.delete(`${APIURL}harvesting/delete/${harvesting.key}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Harvesting record deleted.`)
            navigate(MENUPATH_HARVESTING)
        })
        .catch( error => {
            reportError(error, "Failed to delete harvesting record.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onSave = () => {
        if(photoDataSource.length == 0) {
            message.warning('Photo is required.')
            return
        }

        form.validateFields()
        .then( values => {
            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>Update harvesting record confirmed?</p></Space>,
                onOk() { updateHarvesting() },
                onCancel() {},
                centered: true
            })
        })
    }

    const onCancel = () => {
        navigate(MENUPATH_HARVESTING)
    }

    const onHarvestedOnChange = (datetime) => {
        setHarvestedOn(datetime)
    }

    const onNumberOfBunchesChange = (e) => {
        if(e !== null)
            setNumberOfBunches(e)
        else 
            setNumberOfBunches(0)
    }

    const onEstimatedTotalWeightKgChange = (e) => {
        if(e !== null)
            setEstimatedTotalWeightKg(e)
        else 
            setEstimatedTotalWeightKg(0)
    }

    const onWithPruningChange = (checked) => {
        setWithPruning(checked)
    }

    const validateHarvestedOn = (() => {
        if(harvestedOn != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Harvested on is required."))
    })

    const props = {
        name: "img",
        action: `${APIURL}harvesting/uploadphoto/${harvesting.key}/`,
        timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 10,
        headers: {
            Authorization: `Token ${getUserAuthToken()}`,
        },
        beforeUpload: file => {
            if(photoDataSource.length >= MAXPHOTOS) {
                setUploading(false)
                setIsLoading(false)
                message.error(`Maximum of only ${MAXPHOTOS} photos are allowed.`)
                setPhotoFile([])
                return false
            }

            const isImage = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif'
            if(!isImage) {
                setUploading(false)
                setIsLoading(false)
                message.error(`${file.name} is not an image file.`)
                setPhotoFile([])
                return false
            }

            setPhotoFile([file])
        },
        onChange(info) {
            setUploading(true)

            if(info.file.status !== 'uploading') {
                setUploading(false)
                //setIsLoading(true)
            }

            if(info.file.status === 'done') {
                setUploading(false)
                setIsLoading(false)
                setPhotoFile([])

                setPhotoDataSource(
                    [{pkey: info.fileList[0].response.pkey, img: `${URL.substring(0, URL.length - 1)}${info.fileList[0].response.img}?now=${dayjs().valueOf()}`}]
                    .concat(photoDataSource)
                )    

                message.info("Photo uploaded.")
            }
            else if(info.file.status === 'error') {
                setUploading(false)
                message.error(`"${info.file.name}" upload failed.`)
                message.error(info.file.response?.detail)
            }
        },
        maxCount: 1,
        fileList: photoFile,
    }

    useEffect(() => {
        const photos = []
        harvesting.photos.forEach( photo => {
            photos.push({
                pkey: photo.pkey,
                img: `${photo.img}?now=${dayjs().valueOf()}`
            })
        })
        setPhotoDataSource(photos)

        form.setFieldsValue({
            numberOfBunches: harvesting.numberOfBunches,
            estimatedTotalWeightKg: harvesting.estimatedTotalWeightKg
        })
    }, [])

    const columns = [
        { title: 'Photo', dataIndex: 'img', key: 'img', align: 'center',
            render: (img, record) => {
                if(img != null) 
                    return <>
                        <Image width={100} fallback={FALLBACK} src={img} onClick={(e) => e.stopPropagation() }/>
                    </>
                else
                    return null
            }
        },
        { key: 'action', align: 'center',
            render: (record) => {
                return <Popconfirm title="Delete photo confirmed?" 
                    onClick={e => e.stopPropagation()} onCancel={e => e.stopPropagation()} onConfirm={e => removePhoto(e, record.pkey)} 
                    okText="Yes" cancelText="No">
                    <Button danger type="primary" icon={<DeleteOutlined/>} disabled={disable}/>
                </Popconfirm>
            }
        }
    ]

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles, backgroundColor: retrieveBackgroundColor()}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader 
                    onBack={() => onCancel()} 
                    title="Harvesting">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update harvesting record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row align='center'>
                    <Col span={8} style={{textAlign: "start"}} >
                        {/* <Card title={<Title level={5}>{`Worker: ${urlParams.get("name")}`}</Title>}> */}
                        <Card title={`Tree ID: ${harvesting.treeId}`}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Created On:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{harvesting.createdOn}</Card.Grid>
                    
                            { harvesting.updatedOn != harvesting.createdOn &&
                                <>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Updated On:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{harvesting.updatedOn}</Card.Grid>
                                </>
                            }
                            { harvesting.uploadedOn != "" &&
                                <>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Uploaded On:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{harvesting.uploadedOn}</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Uploaded By:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{harvesting.uploadedBy}</Card.Grid>
                                </>
                            }
                        </Card>
                    </Col>
                </Row>
                    
                <Row><Col><Space><div /></Space></Col></Row>

                <Form form={form} onFinish={onSave} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                <Form.Item name="harvestedOn" label="Harvested On"
                        rules={[
                            { required: true, validator: validateHarvestedOn },
                        ]}>
                        <DatePicker showTime onChange={onHarvestedOnChange} defaultValue={harvestedOn} format={DATETIMEFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item name="numberOfBunches" label="Number of Bunches"
                        rules={[
                            { required: true, message: 'Number of bunches is required.' },
                        ]}>
                        <InputNumber defaultValue={numberOfBunches} onChange={onNumberOfBunchesChange} min={1} max={100000} step={1} precision={0} placeholder="Number of Bunches" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item name="estimatedTotalWeightKg" label="Estimated Total Weight (Kg)"
                        rules={[
                            { required: true, message: 'Estimated total weight is required.' },
                        ]}>
                        <InputNumber defaultValue={estimatedTotalWeightKg} onChange={onEstimatedTotalWeightKgChange} min={1} max={100000} step={1} precision={0} placeholder="Estimated Total Weight (Kg)" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item name="withPruning" label="With Pruning">
                        <Switch defaultChecked={withPruning} onChange={onWithPruningChange} style={{ marginLeft: 10 }} />
                    </Form.Item> 

                    {/* { !disable &&  */}
                    <Form.Item name="photoFile" label={`Photo (max. ${MAXPHOTOS})`}>
                        <Upload {...props} accept=".jpg,.png,.gif">
                            <Button icon={<UploadOutlined />} loading={uploading}>Add Photo</Button>
                        </Upload>
                    </Form.Item>
                    {/* } */}

                    {/* <Row>
                        <Col span={6} />
                            <Col span={12} style={{textAlign: "center"}}>
                                { !disableRemovePhotoButton && 
                                    <Image 
                                        width={IMAGEWIDTH} 
                                        //height = {IMAGEHEIGHT}
                                        src={photoFileURI}
                                        fallback={FALLBACK}
                                        preview={true} />
                                }
                            </Col>
                        <Col span={6} />
                    </Row> */}

                    <Row justify="center">
                        <Col span={3}>
                            <Table bordered columns={columns} dataSource={photoDataSource} pagination={false} />
                        </Col>
                    </Row>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined/>}>Update</Button>
                            <Popconfirm title="Delete harvesting record confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" onClick={onCancel} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                    title="Harvesting:"
                    subTitle="Update harvesting record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default HarvestingUpdate