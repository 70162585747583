import { Table, Row, Col, Space, Button, Collapse, Pagination, message, Spin, DatePicker, Image, InputNumber, Input, Badge } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT, FALLBACK, LOADING, PAGESIZE, PAGINATIONSIZE, SEARCHOPTIONVIEWWIDTH, UNIDATEFORMAT, UNIDATETIMEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserEstateId, getUserEstateName, retrieveTreeBlock, retrieveTreeRow, storeTreeBlock, storeTreeRow } from "../common/usersession"
import { useNavigate } from "react-router"
import { reportError, validPalmSearchRange } from '../common/utility'
import dayjs from 'dayjs';
import TrueFalseSelect from '../common/truefalseselect'
import SearchTreeByLocation from '../common/searchtreebylocation'
import ActivityIntervalTypeSelect from '../common/activityintervaltypeselect'

const { Panel } = Collapse
const { RangePicker } = DatePicker


const SlashingTable = () => {
    const FileDownload = require('js-file-download')
    const navigate = useNavigate()

    const [slashingDataSource, setSlashingDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const [slashedOnDateRange, setSlashedOnDateRange] = useState([dayjs().add(-1, 'month').add(1, 'day'), dayjs()])
    //const [block, setBlock] = useState(-1)
    //const [row, setRow] = useState(-1)
    const [block, setBlock] = useState(!isNaN(retrieveTreeBlock()) ? retrieveTreeBlock() : -1)
    const [row, setRow] = useState(!isNaN(retrieveTreeRow()) ? retrieveTreeRow() : -1)
    const [number, setNumber] = useState('')
    const [slashingTypePKey, setSlashingTypePKey] = useState(0)
    const [slashingType, setSlashingType] = useState('')
    // const [isLiving, setIsLiving] = useState(true)
    // const [isLivingLabel, setIsLivingLabel] = useState(OTHERSYSPARAM('YES'))

    const [showSearchTree, setShowSearchTree] = useState(false)
    const [visible, setVisible] = useState(false)
    const [previewPhotos, setPreviewPhotos] = useState([])
    
    const searchSlashing = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}slashingpaginated/`, {
            params: { 
                estate: getUserEstateId(),
                block: block,
                row: row,
                number: number,
                from_date: slashedOnDateRange[0] == null ? null : slashedOnDateRange[0].format(UNIDATEFORMAT),
                to_date: slashedOnDateRange[1] == null ? null : slashedOnDateRange[1].format(UNIDATEFORMAT),
                slashing_type: slashingTypePKey,
                //is_living: isLiving,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)

            if(response.data.results.length > 0) {
                storeTreeBlock(block)
                storeTreeRow(row)
            }
        })
        .catch( error => {
            reportError(error, "Failed to search slashing activity.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( slashing => {
            data.push({
                key: slashing.pkey,
                treeId: slashing.tree_data.tree_id,
                slashedOn: dayjs(slashing.slashed_on).format(DATETIMEFORMAT),
                slashedOnUnformatted: dayjs(slashing.slashed_on).format(UNIDATETIMEFORMAT),
                slashingTypePKey: slashing.slashing_type_data.pkey,
                slashingType: slashing.slashing_type_data.name,
                remark: slashing.remark,
                createdOn: dayjs(slashing.created_on).format(DATETIMEFORMAT),
                updatedOn: dayjs(slashing.updated_on).format(DATETIMEFORMAT),
                uploadedOn: slashing.uploaded_on != null ? dayjs(slashing.uploaded_on).format(DATETIMEFORMAT) : '',
                uploadedBy: slashing.uploader_data != null ? slashing.uploader_data.username : '',
                img: `${slashing?.photo_data[0]?.img}?now=${dayjs().valueOf()}`,
                photoCount: `${slashing.photo_data.length}`,
                photos: slashing?.photo_data
            })
        })
        
        setSlashingDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const downloadSearchResult = (mode) => {
        if(slashedOnDateRange[0] == null) {
            message.warning('Date range is required.')
            return
        }

        setIsLoading(true)
                        
        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Estate: ${getUserEstateName()}`
        if(block != -1) searchCriteria = `${searchCriteria}\nBlock: ${block}`
        if(row != -1) searchCriteria = `${searchCriteria}\nRow: ${row}`
        if(number != '') searchCriteria = `${searchCriteria}\nNumber: ${number}`
        if(slashedOnDateRange[0] != null) searchCriteria = `${searchCriteria}\nSlashed On: ${slashedOnDateRange[0].format(DATEFORMAT)} to ${slashedOnDateRange[1].format(DATEFORMAT)}`
        if(slashingTypePKey != 0) searchCriteria = `${searchCriteria}\nSlashing Type: ${slashingType}`
        //if(isLiving != '') searchCriteria = `${searchCriteria}\nIs Living: ${isLivingLabel}`
                                                
        axios.get(`${APIURL}slashing/download/`, {
            params: { 
                estate: getUserEstateId(),
                block: block,
                row: row,
                number: number,
                from_date: slashedOnDateRange[0] == null ? null : slashedOnDateRange[0].format(UNIDATEFORMAT),
                to_date: slashedOnDateRange[1] == null ? null : slashedOnDateRange[1].format(UNIDATEFORMAT),
                slashing_type: slashingTypePKey,
                //is_living: isLiving,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 12),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Slashing ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const showPhotos = (e, record) => {
        e.stopPropagation()

        const photos = []
        record.photos.forEach( photo => {
            photos.push(<Image fallback={FALLBACK} src={`${photo.img}?now=${new Date()}`}/>)
        })
        
        setPreviewPhotos(photos)
        setVisible(true)
    }
    
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchSlashing(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSearch = () => {
        if(!validPalmSearchRange(number)) {
            message.warning('Invalid palm search range.')
            return
        }

        setCurrentPage(1)
        searchSlashing(1)
    }

    const onReset = () => {
        window.location.reload()
    }

    const onCreateNew = () => {
        setShowSearchTree(true)
    }

    const navigateTo = (tree) => {
        navigate({
            pathname: '/slashingnew',
            search: `?tree=${JSON.stringify(tree)}`
        })
    }

    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/slashingupdate", 
                    search: `?slashing=${JSON.stringify(record)}`
                }) 
            }
        }
    }

    const onBlockChange = (e, value) => {
        if(e !== null)
            setBlock(e)
        else 
            setBlock(-1)
    }

    const onRowChange = (e, value) => {
        if(e !== null)
            setRow(e)
        else 
            setRow(-1)
    }

    const onNumberChange = (e) => {
        setNumber(e.target.value.trim())
    }

    const onSlashedOnDateRangeChange = (dateRange) => {
        if(dateRange == null)
            setSlashedOnDateRange([null, null])
        else
            setSlashedOnDateRange(dateRange)
    }

    const onActivityIntervalTypeChange = (e, value) => {
        setSlashingTypePKey(e)
        setSlashingType(value.children)
    }

    // const onIsLivingChange = (e, value) => {
    //     setIsLiving(e)
    //     setIsLivingLabel(value.children)
    // }

    useEffect(() => {
        searchSlashing(currentPage)
    }, [])

    const columns = [
        { title: 'Tree ID', dataIndex: 'treeId', key: 'treeId', align: 'center', sorter: (a, b) => a.treeId.localeCompare(b.treeId) },
        { title: 'Slashed On', dataIndex: 'slashedOn', key: 'slashedOn', align: 'center', sorter: (a, b) => a.slashedOn.localeCompare(b.slashedOn) },
        { title: 'Slashing Type', dataIndex: 'slashingType', key: 'slashingType', align: 'center', sorter: (a, b) => a.slashingType.localeCompare(b.slashingType) },
        { title: 'Uploaded By', dataIndex: 'uploadedBy', key: 'uploadedBy', align: 'center', sorter: (a, b) => a.uploadedBy.localeCompare(b.uploadedBy) },
        { title: 'Uploaded On', dataIndex: 'uploadedOn', key: 'uploadedOn', align: 'center', sorter: (a, b) => a.uploadedOn.localeCompare(b.uploadedOn) },
        { title: 'Photo', dataIndex: 'img', key: 'img', align: 'center',
            render: (img, record) => {
                if(img != null) 
                    return <>
                        <Badge count={record.photoCount}>
                            <Image width={50} fallback={FALLBACK} src={img} preview={false} onClick={(e) => showPhotos(e, record) }/>
                        </Badge>
                    </>
                else
                    return null
            }
        },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search slahsing" key="1">
                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Block :</Col>
                            <Col span={3}>
                                <InputNumber defaultValue={block != -1 ? block : ''} onChange={onBlockChange} style={{ width: SEARCHOPTIONVIEWWIDTH }} min={0} max={100000} step={1} precision={0} placeholder="Block"/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Slashed On :</Col>
                            <Col span={3}>
                                <RangePicker onChange={onSlashedOnDateRangeChange} defaultValue={slashedOnDateRange} format={DATEFORMAT}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            </>
                        </Row>   

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Row :</Col>
                            <Col span={3}>
                                <InputNumber defaultValue={row != -1 ? row : ''} onChange={onRowChange} style={{ width: SEARCHOPTIONVIEWWIDTH }} min={0} max={100000} step={1} precision={0} placeholder="Row"/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Slashing Type :</Col>
                            <Col span={3}>
                                <ActivityIntervalTypeSelect withBlank={true} allowClear={true} onChange={onActivityIntervalTypeChange} noStyle={true}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            </>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', paddingRight: 10 }}>Palm :</Col>
                            <Col span={3}>
                                <Input onChange={onNumberChange} style={{ width: SEARCHOPTIONVIEWWIDTH }} placeholder={"Use \",\" and \"-\" for multiple palms"}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            {/* <Col span={3}>Is Living:</Col>
                            <Col span={3}>
                                <TrueFalseSelect withBlank={true} defaultValue={isLivingLabel} onChange={onIsLivingChange} noStyle={true}/>
                            </Col> */}
                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            </>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>
                        
                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} loading={isLoading} icon={<DownloadOutlined />} />
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} loading={isLoading} icon={<FileExcelOutlined />} />
                                <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={slashingDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <SearchTreeByLocation show={showSearchTree} toClose={() => setShowSearchTree(false)} navigationCallBack={navigateTo} />
        
        <div style={{ display: 'none'}}>
            <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}>{previewPhotos}</Image.PreviewGroup>
        </div>

        </Spin>
        </>
    )
}

export default SlashingTable