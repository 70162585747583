import { BrowserRouter, Routes, Route } from "react-router-dom"
import Login from "./login"
import LocationMain from "../setup/locationmain"
import LocationUpdate from "../setup/locationupdate"
import LocationNew from "../setup/locationnew"
import EstateMain from "../setup/estatemain"
import EstateUpdate from "../setup/estateupdate"
import EstateNew from "../setup/estatenew"
import SpeciesMain from "../setup/speciesmain"
import SpeciesUpdate from "../setup/speciesupdate"
import SpeciesNew from "../setup/speciesnew"
import ChangePassword from "../setup/changepassword"
import UserMain from "../setup/usermain"
import UserNew from "../setup/usernew"
import UserUpdate from "../setup/userupdate"
import FertilizerMain from "../fertilizer/fertilizermain"
import FertilizerNew from "../fertilizer/fertilizernew"
import FertilizerUpdate from "../fertilizer/fertilizerupdate"
import HerbicidePesticideMain from "../herbicidepesticide/herbicidepesticidemain"
import HerbicidePesticideNew from "../herbicidepesticide/herbicidepesticidenew"
import HerbicidePesticideUpdate from "../herbicidepesticide/herbicidepesticideupdate"
import WorkerMain from "../worker/workermain"
import WorkerNew from "../worker/workernew"
import WorkerUpdate from "../worker/workerupdate"
import AttendanceMain from "../attendance/attendancemain"
import AttendanceNew from "../attendance/attendancenew"
import AttendanceUpdate from "../attendance/attendanceupdate"
import TreeMain from "../tree/treemain"
import TreeNew from "../tree/treenew"
import TreeUpdate from "../tree/treeupdate"
import TreeViewDead from "../tree/treeviewdead"
import HarvestingMain from "../harvesting/harvestingmain"
import HarvestingNew from "../harvesting/harvestingnew"
import HarvestingUpdate from "../harvesting/harvestingupdate"
import PruningMain from "../pruning/pruningmain"
import PruningNew from "../pruning/pruningnew"
import PruningUpdate from "../pruning/pruningupdate"
import SlashingMain from "../slashing/slashingmain"
import SlashingNew from "../slashing/slashingnew"
import SlashingUpdate from "../slashing/slashingupdate"
import ManuringMain from "../manuring/manuringmain"
import ManuringNew from "../manuring/manuringnew"
import ManuringUpdate from "../manuring/manuringupdate"
import SprayingMain from "../spraying/sprayingmain"
import SprayingNew from "../spraying/sprayingnew"
import SprayingUpdate from "../spraying/sprayingupdate"
import DeliveryTripMain from "../deliverytrip/deliverytripmain"
import DeliveryTripNew from "../deliverytrip/deliverytripnew"
import DeliveryTripUpdate from "../deliverytrip/deliverytripupdate"
import FFBDailyCollectionMain from "../ffbdailycollection/ffbdailycollectionmain"
import FFBDailyCollectionNew from "../ffbdailycollection/ffbdailycollectionnew"
import FFBDailyCollectionUpdate from "../ffbdailycollection/ffbdailycollectionupdate"
import FactoryMain from "../setup/factorymain"
import FactoryNew from "../setup/factorynew"
import FactoryUpdate from "../setup/factoryupdate"
import FactoryReceiptMain from "../factoryreceipt/factoryreceiptmain"
import FactoryReceiptNew from "../factoryreceipt/factoryreceiptnew"
import FactoryReceiptUpdate from "../factoryreceipt/factoryreceiptupdate"
import DriverMain from "../driver/drivermain"
import DriverNew from "../driver/drivernew"
import DriverUpdate from "../driver/driverupdate"
import LorryMain from "../lorry/lorrymain"
import LorryNew from "../lorry/lorrynew"
import LorryUpdate from "../lorry/lorryupdate"
import DailyHarvestReportMain from "../report/dailyharvestreportmain"
import TreeSummaryReportMain from "../report/treesummaryreportmain"
import HarvestingSummaryReportMain from "../report/harvestingsummaryreportmain"
import PruningSummaryReportMain from "../report/pruningsummaryreportmain"
import SlashingSummaryReportMain from "../report/slashingsummaryreportmain"
import ManuringSummaryReportMain from "../report/manuringsummaryreportmain"
import SprayingSummaryReportMain from "../report/sprayingsummaryreportmain"
import DeliveryTripSummaryReportMain from "../report/deliverytripsummaryreportmain"
import FFBCollectionSummaryReportMain from "../report/ffbcollectionsummaryreportmain"
import FactoryReceiptSummaryReportMain from "../report/factoryreceiptsummaryreportmain"
import DashboardMain from "../dashboard/dashboardmain"
import UploadedTreeRecordMain from "../report/uploadedtreerecordmain"
import TreeRecordZippedFileMain from "../tree/treerecordzippedfilemain"
import TreeBatchUpdate from "../tree/treebatchupdate"
import AttendanceReportMain from "../report/attendancereportmain"
import IncidentMain from "../incident/incidentmain"
import IncidentNew from "../incident/incidentnew"
import IncidentUpdate from "../incident/incidentupdate"


const Router = () => (
    <BrowserRouter>
        <Routes>
            <Route index path="/" element={<Login />} />
            
            <Route path="/location" element={<LocationMain />} />
            <Route path="/locationnew" element={<LocationNew />} />
            <Route path="/locationupdate" element={<LocationUpdate />} />

            <Route path="/estate" element={<EstateMain />} />
            <Route path="/estatenew" element={<EstateNew />} />
            <Route path="/estateupdate" element={<EstateUpdate />} />

            <Route path="/worker" element={<WorkerMain />} />
            <Route path="/workernew" element={<WorkerNew />} />
            <Route path="/workerupdate" element={<WorkerUpdate />} />

            <Route path="/driver" element={<DriverMain />} />
            <Route path="/drivernew" element={<DriverNew />} />
            <Route path="/driverupdate" element={<DriverUpdate />} />

            <Route path="/lorry" element={<LorryMain />} />
            <Route path="/lorrynew" element={<LorryNew />} />
            <Route path="/lorryupdate" element={<LorryUpdate />} />

            <Route path="/species" element={<SpeciesMain />} />
            <Route path="/speciesnew" element={<SpeciesNew />} />
            <Route path="/speciesupdate" element={<SpeciesUpdate />} />

            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/user" element={<UserMain />} />
            <Route path="/usernew" element={<UserNew />} />
            <Route path="/userupdate" element={<UserUpdate />} />

            <Route path="/fertilizer" element={<FertilizerMain />} />
            <Route path="/fertilizernew" element={<FertilizerNew />} />
            <Route path="/fertilizerupdate" element={<FertilizerUpdate />} />

            <Route path="/herbicidepesticide" element={<HerbicidePesticideMain />} />
            <Route path="/herbicidepesticidenew" element={<HerbicidePesticideNew />} />
            <Route path="/herbicidepesticideupdate" element={<HerbicidePesticideUpdate />} />

            <Route path="/attendance" element={<AttendanceMain />} />
            <Route path="/attendancenew" element={<AttendanceNew />} />
            <Route path="/attendanceupdate" element={<AttendanceUpdate />} />

            <Route path="/tree" element={<TreeMain />} />
            <Route path="/treenew" element={<TreeNew />} />
            <Route path="/treeupdate" element={<TreeUpdate />} />
            <Route path="/treeviewdead" element={<TreeViewDead />} />
            <Route path="/treerecordzippedfile" element={<TreeRecordZippedFileMain />} />
            <Route path="/treebatchupdate" element={<TreeBatchUpdate />} />

            <Route path="/harvesting" element={<HarvestingMain />} />
            <Route path="/harvestingnew" element={<HarvestingNew />} />
            <Route path="/harvestingupdate" element={<HarvestingUpdate />} />

            <Route path="/pruning" element={<PruningMain />} />
            <Route path="/pruningnew" element={<PruningNew />} />
            <Route path="/pruningupdate" element={<PruningUpdate />} />

            <Route path="/slashing" element={<SlashingMain />} />
            <Route path="/slashingnew" element={<SlashingNew />} />
            <Route path="/slashingupdate" element={<SlashingUpdate />} />

            <Route path="/manuring" element={<ManuringMain />} />
            <Route path="/manuringnew" element={<ManuringNew />} />
            <Route path="/manuringupdate" element={<ManuringUpdate />} />

            <Route path="/spraying" element={<SprayingMain />} />
            <Route path="/sprayingnew" element={<SprayingNew />} />
            <Route path="/sprayingupdate" element={<SprayingUpdate />} />

            <Route path="/deliverytrip" element={<DeliveryTripMain />} />
            <Route path="/deliverytripnew" element={<DeliveryTripNew />} />
            <Route path="/deliverytripupdate" element={<DeliveryTripUpdate />} />

            <Route path="/ffbdailycollection" element={<FFBDailyCollectionMain />} />
            <Route path="/ffbdailycollectionnew" element={<FFBDailyCollectionNew />} />
            <Route path="/ffbdailycollectionupdate" element={<FFBDailyCollectionUpdate />} />

            <Route path="/factory" element={<FactoryMain />} />
            <Route path="/factorynew" element={<FactoryNew />} />
            <Route path="/factoryupdate" element={<FactoryUpdate />} />

            <Route path="/factoryreceipt" element={<FactoryReceiptMain />} />
            <Route path="/factoryreceiptnew" element={<FactoryReceiptNew />} />
            <Route path="/factoryreceiptupdate" element={<FactoryReceiptUpdate />} />

            <Route path="/incident" element={<IncidentMain />} />
            <Route path="/incidentnew" element={<IncidentNew />} />
            <Route path="/incidentupdate" element={<IncidentUpdate />} />

            <Route path="/attendancereport" element={<AttendanceReportMain />} />
            <Route path="/dailyharvestreport" element={<DailyHarvestReportMain />} />
            <Route path="/treesummary" element={<TreeSummaryReportMain />} />
            <Route path="/harvestingsummary" element={<HarvestingSummaryReportMain />} />
            <Route path="/pruningsummary" element={<PruningSummaryReportMain />} />
            <Route path="/slashingsummary" element={<SlashingSummaryReportMain />} />
            <Route path="/manuringsummary" element={<ManuringSummaryReportMain />} />
            <Route path="/sprayingsummary" element={<SprayingSummaryReportMain />} />
            <Route path="/deliverytripsummary" element={<DeliveryTripSummaryReportMain />} />
            <Route path="/ffbcollectionsummary" element={<FFBCollectionSummaryReportMain />} />
            <Route path="/factoryreceiptsummary" element={<FactoryReceiptSummaryReportMain />} />
            <Route path="/uploadedtreerecord" element={<UploadedTreeRecordMain />} />

            <Route path="/dashboard" element={<DashboardMain />} />
                        
        </Routes>
    </BrowserRouter>
)

export default Router