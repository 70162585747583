import { useState, useEffect } from 'react'
import { Col, Form, Row, Select, Space } from 'antd'

import { getUserAuthToken, getUserEstateId, OTHERSYSPARAM, refreshUserSession, SYSPARAM } from "./usersession"
import axios from 'axios'
import { APIURL, MEDIAMAXWIDTH, ALLOWED_ESTATES, SEARCHOPTIONVIEWWIDTH } from './systemparameter'
import { useMediaQuery } from 'react-responsive'
import { reportError } from './utility'
//import { formLayout_2Columns } from './layout'

const { Option } = Select


const CommonSearchFormItem = ({onEstateChange, onSpeciesChange, onWorkerChange, onDriverChange, onEmploymentTypeChange, excludeOwnEstate, defaultSpeciesId = 0, defaultSpecies = "",
    defaultWorkerId = 0, defaultWorker = "", defaultDriverId = 0, defaultDriver = "", defaultEmploymentTypeId = 0, defaultEmploymentType = ""}) => {
    //defaultWorkerId = 0, defaultWorker = "", formLayout = formLayout_2Columns}) => {

    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : SEARCHOPTIONVIEWWIDTH)
    
    const [estateOption, setEstateOption] = useState([])
    const [speciesOption, setSpeciesOption] = useState([])
    const [workerOption, setWorkerOption] = useState([])
    const [driverOption, setDriverOption] = useState([])
    const [employmentTypeOption, setEmploymentTypeOption] = useState([])
    
    const [estateId, setEstateId] = useState(0)
    const [speciesId, setSpeciesId] = useState(defaultSpeciesId)
    const [species, setSpecies] = useState(defaultSpecies)
    const [workerId, setWorkerId] = useState(defaultWorkerId)
    const [worker, setWorker] = useState(defaultWorker)
    const [driverId, setDriverId] = useState(defaultDriverId)
    const [driver, setDriver] = useState(defaultDriver)
    const [employmentTypeId, setEmploymentTypeId] = useState(defaultEmploymentTypeId)
    const [employmentType, setEmploymentType] = useState(defaultEmploymentType)
        
    const showEstate = onEstateChange != null
    const showSpecies = onSpeciesChange != null
    const showWorker = onWorkerChange != null
    const showDriver = onDriverChange != null
    const showEmploymentType = onEmploymentTypeChange != null
    
    const getEstate = () => {
        axios.get(`${APIURL}estate/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options.push(<Option key={0}>{" "}</Option>) // Blank
            
            const filteredEstates = response.data.results.filter( estate => String(SYSPARAM(ALLOWED_ESTATES)).includes(estate.pkey))
            options = options.concat(filteredEstates.map( estate => <Option key={estate.pkey}>{estate.name}</Option>))
            
            if(excludeOwnEstate) options = options = options.filter( option => option.key != getUserEstateId())
            setEstateOption(options)
        })
        .catch( error => {
            reportError(error, "<CommonSearchFormItem /> Failed to get estate.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const getEmploymentType = () => {
        axios.get(`${APIURL}employmenttype/`, { 
            params: {},
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( empType => <Option key={empType.pkey}>{empType.name}</Option>))
            setEmploymentTypeOption(options)
        })
        .catch( error => {
            reportError(error, "<EmploymentTypeSelect /> Failed to get employment type.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    const getSpecies = () => {
        axios.get(`${APIURL}species/`, { 
            params: { 
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( species => <Option key={species.pkey}>{species.general_name}</Option>))
            setSpeciesOption(options)
        })
        .catch( error => {
            reportError(error, "<SpeciesSelect /> Failed to get species.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const getWorker = (estateId, employmentTypeId) => {
        axios.get(`${APIURL}worker/`, { 
            params: { 
                estate: estateId,
                employment_type: employmentTypeId
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( worker => <Option key={worker.pkey}>{worker.name} ({worker.employee_id})</Option>))
            setWorkerOption(options)
        })
        .catch( error => {
            reportError(error, "<WorkerSelect /> Failed to get worker.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const getDriver = (estateId) => {
        axios.get(`${APIURL}driver/`, { 
            params: { 
                estate: estateId
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( driver => <Option key={driver.pkey}>{driver.name} ({driver.employee_id})</Option>))
            setDriverOption(options)
        })
        .catch( error => {
            reportError(error, "<DriverSelect /> Failed to get driver.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    const estateChange = (e, value) => {
        if(e != null) {
            setEstateId(e)
            onEstateChange(e, value)
        }
        else {
            onEstateChange(0, "")
            setEstateId(0)
        }

        if(showSpecies) onSpeciesChange(0, "")
        if(showWorker) onWorkerChange(0, "")
        if(showDriver) onDriverChange(0, "")
        if(showEmploymentType) onEmploymentTypeChange(0, "")
        
        setSpeciesId(0)
        setSpecies("")
        setWorkerId(0)
        setWorker("")
        setDriverId(0)
        setDriver("")
        setEmploymentTypeId(0)
        setEmploymentType("")
        
        if(showSpecies) getSpecies(0)
        if(showWorker) getWorker(e)
        if(showDriver) getDriver(e)
        if(showEmploymentType) getEmploymentType(e)
    }

    const employmentTypeChange = (e, value) => {
        if(e != null) {
            setEmploymentTypeId(e)
            setEmploymentType(value.children)
            onEmploymentTypeChange(e, value)
        }
        else {
            onEmploymentTypeChange(0, "")
            setEmploymentTypeId(0)
            setEmploymentType("")
        }

        if(showWorker) onWorkerChange(0, "")
                
        setWorkerId(0)
        setWorker("")
                
        if(showWorker) getWorker(estateId, e)
    }

    const speciesChange = (e, value) => {
        if(e != null) {
            onSpeciesChange(e, value)
            setSpeciesId(e)
            setSpecies(value)
        }
        else {
            onSpeciesChange(0, "")
            setSpeciesId(0)
            setSpecies("")
        }
    }

    const workerChange = (e, value) => {
        if(e != null) {
            onWorkerChange(e, value)
            setWorkerId(e)
            setWorker(value)
        }
        else {
            onWorkerChange(0, "")
            setWorkerId(0)
            setWorker("")
        }
    }

    const driverChange = (e, value) => {
        if(e != null) {
            onDriverChange(e, value)
            setDriverId(e)
            setDriver(value)
        }
        else {
            onDriverChange(0, "")
            setDriverId(0)
            setDriver("")
        }
    }

    useEffect(() => {
        if(showEstate) getEstate()
        if(showSpecies) getSpecies()
        if(showWorker) getWorker()
        if(showDriver) getDriver()
        if(showEmploymentType) getEmploymentType()
    }, [])

    return (
        <>
        { showEstate &&
            <>
            <Row justify="center">
                <>
                <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Estate :</Col>
                <Col span={3}>
                    <Select style={{width: optionWidth}} onChange={estateChange} allowClear showSearch optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {estateOption}    
                    </Select>
                </Col>
                </>
            </Row>

            <Row><Col><Space><div /></Space></Col></Row>
            </>
        }

        { showEmploymentType &&
            <>
            <Row justify="center">
                <>
                <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Employment Type :</Col>
                <Col span={3}>
                    <Select value={employmentType} style={{width: optionWidth}} onChange={employmentTypeChange} allowClear showSearch optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {employmentTypeOption}    
                    </Select>
                </Col>
                </>
            </Row>

            <Row><Col><Space><div /></Space></Col></Row>
            </>
        }

        { showSpecies &&
            <>
            <Row justify="center">
                <>
                <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Species :</Col>
                <Col span={3}>
                    <Select style={{width: optionWidth}} onChange={speciesChange} allowClear showSearch optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {speciesOption}    
                    </Select>
                </Col>
                </>
            </Row>

            <Row><Col><Space><div /></Space></Col></Row>
            </>
        }

        { showWorker &&
            <>
            <Row justify="center">
                <>
                <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Worker :</Col>
                <Col span={3}>
                    <Select value={worker} style={{width: optionWidth}} onChange={workerChange} allowClear showSearch optionFilterProp="children"
                        filterOption={(input, option) => (option?.value ?? '').includes(input)}
                        filterSort={(optionA, optionB) => (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.value ?? '').toLowerCase())}>
                        {workerOption}    
                    </Select>
                </Col>
                </>
            </Row>

            <Row><Col><Space><div /></Space></Col></Row>
            </>
        }

        { showDriver &&
            <>
            <Row justify="center">
                <>
                <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Driver :</Col>
                <Col span={3}>
                    <Select value={driver} style={{width: optionWidth}} onChange={driverChange} allowClear showSearch optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {driverOption}    
                    </Select>
                </Col>
                </>
            </Row>

            <Row><Col><Space><div /></Space></Col></Row>
            </>
        }
        </>
    )
}

export default CommonSearchFormItem
