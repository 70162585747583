import { useCookies } from "react-cookie"
import { useState } from 'react'
import { Menu, message } from 'antd'
import { SettingOutlined, UserOutlined, TagsOutlined, OrderedListOutlined, CheckSquareOutlined, ProfileOutlined, FormatPainterOutlined, PieChartOutlined } from '@ant-design/icons'
import { useNavigate } from "react-router"
import { DARKTHEMEBACKGROUNDCOLOR, DARKTHEMEBORDERCOLOR, LIGHTTHEMEBACKGROUNDCOLOR, LIGHTTHEMEBORDERCOLOR, MENUKEY_ATTENDANCE, MENUKEY_ATTENDANCEREPORT, MENUKEY_CHANGEPASSWORD, MENUKEY_DAILYHARVESTREPORT, MENUKEY_DASHBOARD, MENUKEY_DELIVERYTRIP, MENUKEY_DELIVERYTRIPSUMMARYREPORT, MENUKEY_DRIVER, MENUKEY_ESTATE, MENUKEY_FACTORY, MENUKEY_FACTORYRECEIPT, MENUKEY_FACTORYRECEIPTSUMMARYREPORT, MENUKEY_FERTILIZER, 
    MENUKEY_FFBCOLLECTIONSUMMARYREPORT, 
    MENUKEY_FFBDAILYCOLLECTION, MENUKEY_HARVESTING, MENUKEY_HARVESTINGSUMMARYREPORT, MENUKEY_HERBICIDEPESTICIDE, MENUKEY_INCIDENT, MENUKEY_LOCATION, MENUKEY_LORRY, MENUKEY_MANURING, MENUKEY_MANURINGSUMMARYREPORT, MENUKEY_PRUNING, MENUKEY_PRUNINGSUMMARYREPORT, MENUKEY_SLASHING, 
    MENUKEY_SLASHINGSUMMARYREPORT, 
    MENUKEY_SPECIES, MENUKEY_SPRAYING, MENUKEY_SPRAYINGSUMMARYREPORT, MENUKEY_THEMESWITCH, MENUKEY_TREE, MENUKEY_TREERECORDZIPPEDFILE, MENUKEY_TREESUMMARYREPORT, MENUKEY_UPLOADEDTREERECORD, MENUKEY_USER, MENUKEY_WORKER, MENUPATH_ATTENDANCE, MENUPATH_ATTENDANCEREPORT, MENUPATH_CHANGEPASSWORD, MENUPATH_DAILYHARVESTREPORT, MENUPATH_DASHBOARD, MENUPATH_DELIVERYTRIP, MENUPATH_DELIVERYTRIPSUMMARYREPORT, MENUPATH_DRIVER, 
    MENUPATH_ESTATE, MENUPATH_FACTORY, MENUPATH_FACTORYRECEIPT, MENUPATH_FACTORYRECEIPTSUMMARYREPORT, MENUPATH_FERTILIZER, MENUPATH_FFBCOLLECTIONSUMMARYREPORT, MENUPATH_FFBDAILYCOLLECTION, MENUPATH_HARVESTING, MENUPATH_HARVESTINGSUMMARYREPORT, MENUPATH_HERBICIDEPESTICIDE, MENUPATH_INCIDENT, MENUPATH_LOCATION, 
    MENUPATH_LORRY, MENUPATH_MANURING, MENUPATH_MANURINGSUMMARYREPORT, MENUPATH_PRUNING, MENUPATH_PRUNINGSUMMARYREPORT, MENUPATH_SLASHING, MENUPATH_SLASHINGSUMMARYREPORT, MENUPATH_SPECIES, MENUPATH_SPRAYING, MENUPATH_SPRAYINGSUMMARYREPORT, MENUPATH_TREE, MENUPATH_TREERECORDZIPPEDFILE, MENUPATH_TREESUMMARYREPORT, MENUPATH_UPLOADEDTREERECORD, MENUPATH_USER, MENUPATH_WORKER, MENU_ATTENDANCE, 
    MENU_ATTENDANCEREPORT, 
    MENU_CHANGEPASSWORD, MENU_DAILYHARVESTREPORT, MENU_DELIVERYTRIP, MENU_DELIVERYTRIPSUMMARYREPORT, MENU_DRIVER, MENU_ESTATE, MENU_FACTORY, MENU_FACTORYRECEIPT, MENU_FACTORYRECEIPTSUMMARYREPORT, MENU_FERTILIZER, MENU_FFBCOLLECTIONSUMMARYREPORT, MENU_FFBDAILYCOLLECTION, MENU_HARVESTING, 
    MENU_HARVESTINGSUMMARYREPORT, 
    MENU_HERBICIDEPESTICIDE, MENU_INCIDENT, MENU_LOCATION, MENU_LORRY, MENU_MANURING, MENU_MANURINGSUMMARYREPORT, MENU_PRUNING, MENU_PRUNINGSUMMARYREPORT, MENU_SLASHING, MENU_SLASHINGSUMMARYREPORT, MENU_SPECIES, MENU_SPRAYING, MENU_SPRAYINGSUMMARYREPORT, MENU_TREE, MENU_TREERECORDZIPPEDFILE, MENU_TREESUMMARYREPORT, MENU_UPLOADEDTREERECORD, MENU_USER, MENU_WORKER } from './systemparameter'

import { getUserGroup, OTHERSYSPARAM, storeBackgroundColor, storeBorderColor } from './usersession'

const { SubMenu } = Menu

const ACISMenu = ({setDrawerVisible}) => {
    const navigate = useNavigate()

    const [cookies, setCookies] = useCookies(['useDarkTheme'])
    const [themeLabel, setThemeLabel] = useState(cookies['useDarkTheme'] == '1' ? 'Light Theme' : 'Dark Theme')
    

    //-------------
    // Handle click
    //-------------
    const onClick = (e) => {
        // Close drawer
        setDrawerVisible(false) 
        
        switch(parseInt(e.key)) {
            case MENUKEY_DASHBOARD:
                navigate(MENUPATH_DASHBOARD)
                break

            case MENUKEY_HARVESTING:
                navigate(MENUPATH_HARVESTING)
                break
            case MENUKEY_PRUNING:
                navigate(MENUPATH_PRUNING)
                break
            case MENUKEY_SLASHING:
                navigate(MENUPATH_SLASHING)
                break
            case MENUKEY_MANURING:
                navigate(MENUPATH_MANURING)
                break
            case MENUKEY_SPRAYING:
                navigate(MENUPATH_SPRAYING)
                break
            case MENUKEY_DELIVERYTRIP:
                navigate(MENUPATH_DELIVERYTRIP)
                break
            case MENUKEY_FFBDAILYCOLLECTION:
                navigate(MENUPATH_FFBDAILYCOLLECTION)
                break
            case MENUKEY_FACTORYRECEIPT:
                navigate(MENUPATH_FACTORYRECEIPT)
                break
            case MENUKEY_INCIDENT:
                navigate(MENUPATH_INCIDENT)
                break
            

            case MENUKEY_CHANGEPASSWORD:
                navigate(MENUPATH_CHANGEPASSWORD)
                break
            case MENUKEY_USER:
                navigate(MENUPATH_USER)
                break
            case MENUKEY_ATTENDANCE:
                navigate(MENUPATH_ATTENDANCE)
                break
            case MENUKEY_TREE:
                navigate(MENUPATH_TREE)
                break
            case MENUKEY_TREERECORDZIPPEDFILE:
                navigate(MENUPATH_TREERECORDZIPPEDFILE)
                break

            case MENUKEY_ATTENDANCEREPORT:
                navigate(MENUPATH_ATTENDANCEREPORT)
                break
            case MENUKEY_DAILYHARVESTREPORT:
                navigate(MENUPATH_DAILYHARVESTREPORT)
                break
            case MENUKEY_TREESUMMARYREPORT:
                navigate(MENUPATH_TREESUMMARYREPORT)
                break
            case MENUKEY_HARVESTINGSUMMARYREPORT:
                navigate(MENUPATH_HARVESTINGSUMMARYREPORT)
                break
            case MENUKEY_PRUNINGSUMMARYREPORT:
                navigate(MENUPATH_PRUNINGSUMMARYREPORT)
                break
            case MENUKEY_SLASHINGSUMMARYREPORT:
                navigate(MENUPATH_SLASHINGSUMMARYREPORT)
                break
            case MENUKEY_MANURINGSUMMARYREPORT:
                navigate(MENUPATH_MANURINGSUMMARYREPORT)
                break
            case MENUKEY_SPRAYINGSUMMARYREPORT:
                navigate(MENUPATH_SPRAYINGSUMMARYREPORT)
                break
            case MENUKEY_DELIVERYTRIPSUMMARYREPORT:
                navigate(MENUPATH_DELIVERYTRIPSUMMARYREPORT)
                break
            case MENUKEY_FFBCOLLECTIONSUMMARYREPORT:
                navigate(MENUPATH_FFBCOLLECTIONSUMMARYREPORT)
                break
            case MENUKEY_FACTORYRECEIPTSUMMARYREPORT:
                navigate(MENUPATH_FACTORYRECEIPTSUMMARYREPORT)
                break
            case MENUKEY_UPLOADEDTREERECORD:
                navigate(MENUPATH_UPLOADEDTREERECORD)
                break

            case MENUKEY_LOCATION:
                navigate(MENUPATH_LOCATION)
                break
            case MENUKEY_ESTATE:
                navigate(MENUPATH_ESTATE)
                break
            case MENUKEY_WORKER:
                navigate(MENUPATH_WORKER)
                break
            case MENUKEY_DRIVER:
                navigate(MENUPATH_DRIVER)
                break
            case MENUKEY_LORRY:
                navigate(MENUPATH_LORRY)
                break
            case MENUKEY_SPECIES:
                navigate(MENUPATH_SPECIES)
                break
            case MENUKEY_FERTILIZER:
                navigate(MENUPATH_FERTILIZER)
                break
            case MENUKEY_HERBICIDEPESTICIDE:
                navigate(MENUPATH_HERBICIDEPESTICIDE)
                break
            case MENUKEY_FACTORY:
                navigate(MENUPATH_FACTORY)
                break

            case MENUKEY_THEMESWITCH:
                if(cookies['useDarkTheme'] == '1') {
                    setCookies('useDarkTheme', '0', { path: '/', SameSite: 'Strict' })
                    storeBackgroundColor(LIGHTTHEMEBACKGROUNDCOLOR)
                    storeBorderColor(LIGHTTHEMEBORDERCOLOR)
                }
                else {
                    setCookies('useDarkTheme', '1', { path: '/', SameSite: 'Strict' })
                    storeBackgroundColor(DARKTHEMEBACKGROUNDCOLOR)
                    storeBorderColor(DARKTHEMEBORDERCOLOR)
                }

                window.location.reload()
                break
            default: 
                message.error("Page not found.")
        }
    }

    return (
        <Menu onClick={onClick} mode="inline" /*defaultSelectedKeys={['1']}*/ defaultOpenKeys={[]}>
            <Menu.Item key={MENUKEY_DASHBOARD} icon={<PieChartOutlined />}>Dashboard</Menu.Item>

            <SubMenu key="userMenu" icon={<UserOutlined />} title="Account">
                <Menu.Item key={MENUKEY_CHANGEPASSWORD}>{MENU_CHANGEPASSWORD}</Menu.Item>
                { (getUserGroup().includes(OTHERSYSPARAM("SYSTEMIMPLEMENTOR")) || getUserGroup().includes(OTHERSYSPARAM("ADMINOFFICER"))) &&
                    <>
                        <Menu.Item key={MENUKEY_USER}>{MENU_USER}</Menu.Item>
                    </>
                }
            </SubMenu>

            <SubMenu key="activityMenu" icon={<OrderedListOutlined />} title="Activity">
                <>
                { (getUserGroup().includes(OTHERSYSPARAM("SYSTEMIMPLEMENTOR")) || getUserGroup().includes(OTHERSYSPARAM("ADMINOFFICER")) || getUserGroup().includes(OTHERSYSPARAM("MANDUR")) || getUserGroup().includes(OTHERSYSPARAM("FIELDCONDUCTOR")) || getUserGroup().includes(OTHERSYSPARAM("LORRYDRIVER"))) &&
                    <>
                    <Menu.Item key={MENUKEY_HARVESTING}>{MENU_HARVESTING}</Menu.Item>
                    <Menu.Item key={MENUKEY_PRUNING}>{MENU_PRUNING}</Menu.Item>
                    <Menu.Item key={MENUKEY_SLASHING}>{MENU_SLASHING}</Menu.Item>
                    <Menu.Item key={MENUKEY_MANURING}>{MENU_MANURING}</Menu.Item>
                    <Menu.Item key={MENUKEY_SPRAYING}>{MENU_SPRAYING}</Menu.Item>
                    </>
                }
                { (getUserGroup().includes(OTHERSYSPARAM("SYSTEMIMPLEMENTOR")) || getUserGroup().includes(OTHERSYSPARAM("ADMINOFFICER")) || getUserGroup().includes(OTHERSYSPARAM("MANDUR")) || getUserGroup().includes(OTHERSYSPARAM("FIELDCONDUCTOR")) || getUserGroup().includes(OTHERSYSPARAM("LORRYDRIVER"))) &&
                    <>
                    <Menu.Item key={MENUKEY_DELIVERYTRIP}>{MENU_DELIVERYTRIP}</Menu.Item>
                    </>
                }
                { (getUserGroup().includes(OTHERSYSPARAM("SYSTEMIMPLEMENTOR")) || getUserGroup().includes(OTHERSYSPARAM("ADMINOFFICER")) || getUserGroup().includes(OTHERSYSPARAM("MANDUR")) || getUserGroup().includes(OTHERSYSPARAM("FIELDCONDUCTOR")) || getUserGroup().includes(OTHERSYSPARAM("LORRYDRIVER"))) &&
                    <>
                    <Menu.Item key={MENUKEY_FFBDAILYCOLLECTION}>{MENU_FFBDAILYCOLLECTION}</Menu.Item>
                    <Menu.Item key={MENUKEY_FACTORYRECEIPT}>{MENU_FACTORYRECEIPT}</Menu.Item>
                    <Menu.Item key={MENUKEY_INCIDENT}>{MENU_INCIDENT}</Menu.Item>
                    </>
                }
                </>
            </SubMenu>

            { (getUserGroup().includes(OTHERSYSPARAM("SYSTEMIMPLEMENTOR")) || getUserGroup().includes(OTHERSYSPARAM("ADMINOFFICER")) || getUserGroup().includes(OTHERSYSPARAM("MANDUR")) || getUserGroup().includes(OTHERSYSPARAM("FIELDCONDUCTOR")) || getUserGroup().includes(OTHERSYSPARAM("LORRYDRIVER"))) &&
                <>
                <Menu.Item key={MENUKEY_ATTENDANCE} icon={<CheckSquareOutlined />}>{MENU_ATTENDANCE}</Menu.Item>
                <SubMenu key="treeMenu" icon={<TagsOutlined />} title="Tree Database">
                    <Menu.Item key={MENUKEY_TREE}>{MENU_TREE}</Menu.Item>
                    <Menu.Item key={MENUKEY_TREERECORDZIPPEDFILE}>{MENU_TREERECORDZIPPEDFILE}</Menu.Item>
                </SubMenu>
                </>
            }

            { (getUserGroup().includes(OTHERSYSPARAM("SYSTEMIMPLEMENTOR")) || getUserGroup().includes(OTHERSYSPARAM("ADMINOFFICER"))) &&
                <>
                <SubMenu key="reportMenu" icon={<ProfileOutlined />} title="Report">
                    <Menu.Item key={MENUKEY_ATTENDANCEREPORT}>{MENU_ATTENDANCEREPORT}</Menu.Item>
                    <Menu.Item key={MENUKEY_DAILYHARVESTREPORT}>{MENU_DAILYHARVESTREPORT}</Menu.Item>
                    <Menu.Item key={MENUKEY_TREESUMMARYREPORT}>{MENU_TREESUMMARYREPORT}</Menu.Item>
                    <Menu.Item key={MENUKEY_HARVESTINGSUMMARYREPORT}>{MENU_HARVESTINGSUMMARYREPORT}</Menu.Item>
                    <Menu.Item key={MENUKEY_PRUNINGSUMMARYREPORT}>{MENU_PRUNINGSUMMARYREPORT}</Menu.Item>
                    <Menu.Item key={MENUKEY_SLASHINGSUMMARYREPORT}>{MENU_SLASHINGSUMMARYREPORT}</Menu.Item>
                    <Menu.Item key={MENUKEY_MANURINGSUMMARYREPORT}>{MENU_MANURINGSUMMARYREPORT}</Menu.Item>
                    <Menu.Item key={MENUKEY_SPRAYINGSUMMARYREPORT}>{MENU_SPRAYINGSUMMARYREPORT}</Menu.Item>
                    <Menu.Item key={MENUKEY_DELIVERYTRIPSUMMARYREPORT}>{MENU_DELIVERYTRIPSUMMARYREPORT}</Menu.Item>
                    <Menu.Item key={MENUKEY_FFBCOLLECTIONSUMMARYREPORT}>{MENU_FFBCOLLECTIONSUMMARYREPORT}</Menu.Item>
                    <Menu.Item key={MENUKEY_FACTORYRECEIPTSUMMARYREPORT}>{MENU_FACTORYRECEIPTSUMMARYREPORT}</Menu.Item>
                    <Menu.Item key={MENUKEY_UPLOADEDTREERECORD}>{MENU_UPLOADEDTREERECORD}</Menu.Item>
                </SubMenu>
                </>
            }
            
            { (getUserGroup().includes(OTHERSYSPARAM("SYSTEMIMPLEMENTOR")) || getUserGroup().includes(OTHERSYSPARAM("ADMINOFFICER"))) &&
                <>
                <SubMenu key="setupMenu" icon={<SettingOutlined />} title="Setup">
                    <Menu.Item key={MENUKEY_LOCATION}>{MENU_LOCATION}</Menu.Item>
                    <Menu.Item key={MENUKEY_ESTATE}>{MENU_ESTATE}</Menu.Item>
                    <Menu.Item key={MENUKEY_FACTORY}>{MENU_FACTORY}</Menu.Item>
                    <Menu.Item key={MENUKEY_WORKER}>{MENU_WORKER}</Menu.Item>
                    <Menu.Item key={MENUKEY_DRIVER}>{MENU_DRIVER}</Menu.Item>
                    <Menu.Item key={MENUKEY_LORRY}>{MENU_LORRY}</Menu.Item>
                    <Menu.Item key={MENUKEY_SPECIES}>{MENU_SPECIES}</Menu.Item>
                    <Menu.Item key={MENUKEY_FERTILIZER}>{MENU_FERTILIZER}</Menu.Item>
                    <Menu.Item key={MENUKEY_HERBICIDEPESTICIDE}>{MENU_HERBICIDEPESTICIDE}</Menu.Item>
                </SubMenu>
                </>
            }

            <Menu.Item key={MENUKEY_THEMESWITCH} icon={<FormatPainterOutlined />}>{themeLabel}</Menu.Item>
        </Menu>
  )
}

export default ACISMenu