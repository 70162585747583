import { Table, Row, Col, Space, Button, Collapse, Pagination, message, Spin, DatePicker, Image, Input, Badge } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT, FALLBACK, LOADING, PAGESIZE, PAGINATIONSIZE, SEARCHOPTIONVIEWWIDTH, UNIDATEFORMAT, UNIDATETIMEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserEstateId, getUserEstateName } from "../common/usersession"
import { useNavigate } from "react-router"
import { numberWithCommas, reportError } from '../common/utility'
import dayjs from 'dayjs';
import FactorySelect from '../common/factoryselect'

const { Panel } = Collapse
const { RangePicker } = DatePicker

const FactoryReceiptTable = () => {
    const FileDownload = require('js-file-download')
    const navigate = useNavigate()

    const [factoryReceiptDataSource, setFactoryReceiptDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const [collectedOnDateRange, setCollectedOnDateRange] = useState([dayjs().add(-1, 'month').add(1, 'day'), dayjs()])
    const [factoryPKey, setFactoryPKey] = useState(0)
    const [factory, setFactory] = useState('')
    const [chitNumber, setChitNumber] = useState('')
    const [visible, setVisible] = useState(false)
    const [previewPhotos, setPreviewPhotos] = useState([])
        
    const searchFactoryReceipt = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}factoryreceiptpaginated/`, {
            params: { 
                estate: getUserEstateId(),
                from_date: collectedOnDateRange[0] == null ? null : collectedOnDateRange[0].format(UNIDATEFORMAT),
                to_date: collectedOnDateRange[1] == null ? null : collectedOnDateRange[1].format(UNIDATEFORMAT),
                factory: factoryPKey,
                chit_number: chitNumber,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search factory receipt.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( factoryReceipt => {
            data.push({
                key: factoryReceipt.pkey,
                factoryPKey: factoryReceipt.factory_data.pkey,
                factory: factoryReceipt.factory_data.name,
                chitNumber: factoryReceipt.chit_number,
                lorryPKey: factoryReceipt.lorry_data.pkey,
                lorry: factoryReceipt.lorry > 0 ? factoryReceipt.lorry_data.number_plate : factoryReceipt.unregistered_lorry_number_plate,

                driverPKey: factoryReceipt.driver_data.pkey,
                driver: factoryReceipt.driver > 0 ? factoryReceipt.driver_data.name : factoryReceipt.unregistered_driver_name,
                employeeId: factoryReceipt.driver_data.employee_id,
                grossWeightKg: factoryReceipt.gross_weight_kg,
                tareWeightKg: factoryReceipt.tare_weight_kg,
                nettWeightKg: factoryReceipt.nett_weight_kg,
                grossWeightKgLabel: numberWithCommas(factoryReceipt.gross_weight_kg),
                tareWeightKgLabel: numberWithCommas(factoryReceipt.tare_weight_kg),
                nettWeightKgLabel: numberWithCommas(factoryReceipt.nett_weight_kg),

                collectedOn: dayjs(factoryReceipt.collected_on).format(DATEFORMAT),
                collectedOnUnformatted: dayjs(factoryReceipt.collected_on).format(UNIDATETIMEFORMAT),
                createdOn: dayjs(factoryReceipt.created_on).format(DATETIMEFORMAT),
                updatedOn: dayjs(factoryReceipt.updated_on).format(DATETIMEFORMAT),
                uploadedOn: factoryReceipt.uploaded_on != null ? dayjs(factoryReceipt.uploaded_on).format(DATETIMEFORMAT) : '',
                uploadedBy: factoryReceipt.uploader_data != null ? factoryReceipt.uploader_data.username : '',
                
                img: `${factoryReceipt?.photo_data[0]?.img}?now=${dayjs().valueOf()}`,
                photoCount: `${factoryReceipt.photo_data.length}`,
                photos: factoryReceipt?.photo_data
            })
        })
        
        setFactoryReceiptDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const downloadSearchResult = (mode) => {
        if(collectedOnDateRange[0] == null) {
            message.warning('Date range is required.')
            return
        }

        setIsLoading(true)
                        
        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Estate: ${getUserEstateName()}`
        if(collectedOnDateRange[0] != null) searchCriteria = `${searchCriteria}\nCollected On: ${collectedOnDateRange[0].format(DATEFORMAT)} to ${collectedOnDateRange[1].format(DATEFORMAT)}`
        if(factoryPKey != 0) searchCriteria = `${searchCriteria}\nFactory: ${factory}`
        if(chitNumber != '') searchCriteria = `${searchCriteria}\nChit No.: ${chitNumber}`
                                                
        axios.get(`${APIURL}factoryreceipt/download/`, {
            params: { 
                estate: getUserEstateId(),
                from_date: collectedOnDateRange[0].format(UNIDATEFORMAT),
                to_date: collectedOnDateRange[1].format(UNIDATEFORMAT),
                factory: factoryPKey,
                chit_number: chitNumber,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 12),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Factory Receipt ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const showPhotos = (e, record) => {
        e.stopPropagation()

        const photos = []
        record.photos.forEach( photo => {
            photos.push(<Image fallback={FALLBACK} src={`${photo.img}?now=${new Date()}`}/>)
        })
        
        setPreviewPhotos(photos)
        setVisible(true)
    }
    
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchFactoryReceipt(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSearch = () => {
        setCurrentPage(1)
        searchFactoryReceipt(1)
    }

    const onReset = () => {
        window.location.reload()
    }

    const onCreateNew = () => {
        navigate({
            pathname: '/factoryreceiptnew',
        })
    }

    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/factoryreceiptupdate", 
                    search: `?factoryReceipt=${JSON.stringify(record)}`
                }) 
            }
        }
    }

    const onCollectedOnDateRangeChange = (dateRange) => {
        if(dateRange == null)
            setCollectedOnDateRange([null, null])
        else
            setCollectedOnDateRange(dateRange)
    }

    const onFactoryChange = (e, value) => {
        setFactoryPKey(e)
        setFactory(value.children)
    }

    const onChitNumberChange = (e) => {
        setChitNumber(e.target.value)
    }

    useEffect(() => {
        searchFactoryReceipt(currentPage)
    }, [])

    const columns = [
        { title: 'Collected On', dataIndex: 'collectedOn', key: 'collectedOn', align: 'center', sorter: (a, b) => a.collectedOn.localeCompare(b.collectedOn) },
        { title: 'Chit Number', dataIndex: 'chitNumber', key: 'chitNumber', align: 'center', sorter: (a, b) => a.chitNumber.localeCompare(b.chitNumber) },
        { title: 'Gross Weight (Kg)', dataIndex: 'grossWeightKgLabel', key: 'grossWeightKgLabel', align: 'right', sorter: (a, b) => a.grossWeightKgLabel.localeCompare(b.grossWeightKgLabel) },
        { title: 'Tare Weight (Kg)', dataIndex: 'tareWeightKgLabel', key: 'tareWeightKgLabel', align: 'right', sorter: (a, b) => a.tareWeightKgLabel.localeCompare(b.tareWeightKgLabel) },
        { title: 'Nett Weight (Kg)', dataIndex: 'nettWeightKgLabel', key: 'nettWeightKgLabel', align: 'right', sorter: (a, b) => a.nettWeightKgLabel.localeCompare(b.nettWeightKgLabel) },
        { title: 'Lorry', dataIndex: 'lorry', key: 'lorry', align: 'center', sorter: (a, b) => a.lorry.localeCompare(b.lorry),
            render: (lorry, record) => {
                return {
                    props: {
                        style: {background: record.lorryPKey > 0 ? '' : 'coral'}
                    },
                    children: <div>{lorry}</div>
                }
            }
        },
        { title: 'Driver', dataIndex: 'driver', key: 'driver', align: 'center', sorter: (a, b) => a.driver.localeCompare(b.driver),
            render: (driver, record) => {
                return {
                    props: {
                        style: {background: record.driverPKey > 0 ? '' : 'coral'}
                    },
                    children: <div>{driver}</div>
                }
            }
        },
        { title: 'Employee ID', dataIndex: 'employeeId', key: 'employeeId', align: 'center', sorter: (a, b) => a.driver.localeCompare(b.driver) },
        { title: 'Factory', dataIndex: 'factory', key: 'factory', align: 'center', sorter: (a, b) => a.factory.localeCompare(b.factory) },
        { title: 'Uploaded By', dataIndex: 'uploadedBy', key: 'uploadedBy', align: 'center', sorter: (a, b) => a.uploadedBy.localeCompare(b.uploadedBy) },
        { title: 'Uploaded On', dataIndex: 'uploadedOn', key: 'uploadedOn', align: 'center', sorter: (a, b) => a.uploadedOn.localeCompare(b.uploadedOn) },
        { title: 'Photo', dataIndex: 'img', key: 'img', align: 'center',
            render: (img, record) => {
                if(img != null) 
                    return <>
                        <Badge count={record.photoCount}>
                            <Image width={50} fallback={FALLBACK} src={img} preview={false} onClick={(e) => showPhotos(e, record) }/>
                        </Badge>
                    </>
                else
                    return null
            }
        },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search factory receipt" key="1">
                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Collected On :</Col>
                            <Col span={3}>
                                <RangePicker onChange={onCollectedOnDateRangeChange} defaultValue={collectedOnDateRange} format={DATEFORMAT}/>
                            </Col>

                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Factory :</Col>
                            <Col span={3}>
                                <FactorySelect withBlank={true} allowClear={true} onChange={onFactoryChange} noStyle={true}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Chit Number :</Col>
                            <Col span={3}>
                                <Input onChange={onChitNumberChange} style={{ width: SEARCHOPTIONVIEWWIDTH }} placeholder="Chit Number"/>
                            </Col>
                            </>
                        </Row>   

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} loading={isLoading} icon={<DownloadOutlined />} />
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} loading={isLoading} icon={<FileExcelOutlined />} />
                                <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={factoryReceiptDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <div style={{ display: 'none'}}>
            <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}>{previewPhotos}</Image.PreviewGroup>
        </div>
        
        </Spin>
        </>
    )
}

export default FactoryReceiptTable