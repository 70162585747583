import { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import { Form, Button, Space, Layout, Row, Col, Popconfirm, message, DatePicker, Card, Modal, Input, Descriptions, Image, Upload, Spin, Table } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { SaveOutlined, CloseOutlined, DeleteOutlined, QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons"
import MainFooter from '../common/footer'
import { APIURL, FALLBACK, URL, LOADING, MENUPATH_ATTENDANCE, DATETIMEFORMAT, UNIDATETIMEFORMAT, SEARCHOPTIONVIEWWIDTH, MAXPHOTOS, MENUPATH_INCIDENT } from "../common/systemparameter"
import { reportError } from "../common/utility"
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserGroup, retrieveBackgroundColor } from "../common/usersession"
import dayjs from 'dayjs'
import MainHeader from '../common/header'
import { walltiles } from '../common/layout'
import IncidentTypeSelect from '../common/incidenttypeselect'

const { Header, Footer, Content } = Layout
const { confirm } = Modal


const IncidentUpdate = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [uploading, setUploading] = useState(false)

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)
    const incident = JSON.parse(urlParams.get("incident"))
    
    const [photoDataSource, setPhotoDataSource] = useState([])
        
    const [incidentTypePKey, setIncidentTypePKey] = useState(incident.incidentTypePKey)
    const [incidentDate, setIncidentDate] = useState(dayjs(incident.incidentDateUnformatted), UNIDATETIMEFORMAT)
    const [photoFile, setPhotoFile] = useState([])

    const removePhoto = (e, pkey) => {
        e.stopPropagation()
        setIsLoading(true)
        
        axios.patch(`${APIURL}incident/removephoto/${pkey}/`, {}, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info("Photo removed.")
            setPhotoDataSource(photoDataSource.filter(photo => photo.pkey != pkey))
        })
        .catch( error => {
            reportError(error, `Failed to remove incident photo.`)
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }
    
    const updateIncident = () => {
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${APIURL}incident/update/${incident.key}/`, {
                incident_date: incidentDate,
                incident_type: incidentTypePKey,
                remark: values.remark,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Incident record updated.")
                navigate(MENUPATH_INCIDENT)
            })
            .catch( error => {
                reportError(error, `Failed to update incident record.`)
            })
            .finally(() => {
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }

    const onDelete = () => {
        setIsLoading(true)
        
        axios.delete(`${APIURL}incident/delete/${incident.key}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Incident record deleted.`)
            navigate(MENUPATH_INCIDENT)
        })
        .catch( error => {
            reportError(error, "Failed to delete incident record.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onSave = () => {
        form.validateFields()
        .then( values => {
            if(String(values.remark).trim().length == 0) {
                message.warning('Remark is required.')
                return
            }

            if(photoDataSource.length == 0) {
                message.warning('Photo is required.')
                return
            }

            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>Update incident record confirmed?</p></Space>,
                onOk() { updateIncident() },
                onCancel() {},
                centered: true
            })
        })
    }

    const onCancel = () => {
        navigate(MENUPATH_INCIDENT)
    }

    const onIncidentTypeChange = (e, value) => {
        if(e != undefined) 
            setIncidentTypePKey(e)
        else 
            setIncidentTypePKey(0)
    }

    const onIncidentDateChange = (datetime) => {
        setIncidentDate(datetime)
    }

    const validateIncidentType = (() => {
        if(incidentTypePKey != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Incident type is required."))
    })

    const validateIncidentDate = (() => {
        if(incidentDate != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Incident date is required."))
    })

    const props = {
        name: "img",
        action: `${APIURL}incident/uploadphoto/${incident.key}/`,
        timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 10,
        headers: {
            Authorization: `Token ${getUserAuthToken()}`,
        },
        beforeUpload: file => {
            if(photoDataSource.length >= MAXPHOTOS) {
                setUploading(false)
                setIsLoading(false)
                message.error(`Maximum of only ${MAXPHOTOS} photos are allowed.`)
                setPhotoFile([])
                return false
            }

            const isImage = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif'
            if(!isImage) {
                setUploading(false)
                setIsLoading(false)
                message.error(`${file.name} is not an image file.`)
                setPhotoFile([])
                return false
            }

            setPhotoFile([file])
        },
        onChange(info) {
            setUploading(true)

            if(info.file.status !== 'uploading') {
                setUploading(false)
                //setIsLoading(true)
            }

            if(info.file.status === 'done') {
                setUploading(false)
                setIsLoading(false)
                setPhotoFile([])

                setPhotoDataSource(
                    [{pkey: info.fileList[0].response.pkey, img: `${URL.substring(0, URL.length - 1)}${info.fileList[0].response.img}?now=${dayjs().valueOf()}`}]
                    .concat(photoDataSource)
                )    

                message.info("Photo uploaded.")
            }
            else if(info.file.status === 'error') {
                setUploading(false)
                message.error(`"${info.file.name}" upload failed.`)
                message.error(info.file.response?.detail)
            }
        },
        maxCount: 1,
        fileList: photoFile,
    }

    useEffect(() => {
        const photos = []
        incident.photos.forEach( photo => {
            photos.push({
                pkey: photo.pkey,
                img: `${photo.img}?now=${dayjs().valueOf()}`
            })
        })
        setPhotoDataSource(photos)

        form.setFieldsValue({
            remark: incident.remark
        })
    }, [])

    const columns = [
        { title: 'Photo', dataIndex: 'img', key: 'img', align: 'center',
            render: (img, record) => {
                if(img != null) 
                    return <>
                        <Image width={100} fallback={FALLBACK} src={img} onClick={(e) => e.stopPropagation() }/>
                    </>
                else
                    return null
            }
        },
        { key: 'action', align: 'center',
            render: (record) => {
                return <Popconfirm title="Delete photo confirmed?" 
                    onClick={e => e.stopPropagation()} onCancel={e => e.stopPropagation()} onConfirm={e => removePhoto(e, record.pkey)} 
                    okText="Yes" cancelText="No">
                    <Button danger type="primary" icon={<DeleteOutlined/>} />
                </Popconfirm>
            }
        }
    ]

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles, backgroundColor: retrieveBackgroundColor()}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader 
                    onBack={() => onCancel()} 
                    title="Incident">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update incident record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row><Col><Space><div /></Space></Col></Row>

                <Form form={form} onFinish={onSave} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                    <Form.Item name="incidentDate" label="Incident Date"
                        rules={[
                            { required: true, validator: validateIncidentDate },
                        ]}>
                        <DatePicker onChange={onIncidentDateChange} defaultValue={incidentDate} format={DATETIMEFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="incidentType" label="Incident Type"
                        rules={[
                            { required: true, validator: validateIncidentType},
                        ]}>
                        <IncidentTypeSelect onChange={onIncidentTypeChange} withBlank={false} allowClear={false} defaultValue={incident.incidentType} marginLeft={10} />
                    </Form.Item>

                    <Form.Item name="remark" label="Remark" 
                        rules={[ 
                            { required: true }
                            ]}>
                        <Input placeholder="Remark" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }}/>
                    </Form.Item>

                    {/* { !disable &&  */}
                    <Form.Item name="photoFile" label={`Photo (max. ${MAXPHOTOS})`}>
                        <Upload {...props} accept=".jpg,.png,.gif">
                            <Button icon={<UploadOutlined />} loading={uploading}>Add Photo</Button>
                        </Upload>
                    </Form.Item>
                    {/* } */}

                    {/* <Row>
                        <Col span={6} />
                            <Col span={12} style={{textAlign: "center"}}>
                                { !disableRemovePhotoButton && 
                                    <Image 
                                        width={IMAGEWIDTH} 
                                        //height = {IMAGEHEIGHT}
                                        src={photoFileURI}
                                        fallback={FALLBACK}
                                        preview={true} />
                                }
                            </Col>
                        <Col span={6} />
                    </Row> */}

                    <Row justify="center">
                        <Col span={3}>
                            <Table bordered columns={columns} dataSource={photoDataSource} pagination={false} />
                        </Col>
                    </Row>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined/>}>Update</Button>
                            <Popconfirm title="Delete incident record confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" onClick={onCancel} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                    title="Incident:"
                    subTitle="Update incident record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default IncidentUpdate