import { useState } from 'react'
import { Select } from 'antd'
import { OTHERSYSPARAM } from './usersession'
import { SEARCHOPTIONVIEWWIDTH } from './systemparameter'

const { Option } = Select


const TrueFalseSelect = ({withBlank, defaultValue, onChange, noStyle = false}) => {
    const [optionWidth, setOptionWidth] = useState(!noStyle ? "" : SEARCHOPTIONVIEWWIDTH)

    const options = []
    if(withBlank) options.push(<Option key={""}>{" "}</Option>)
    options.push(<Option key={true}>{OTHERSYSPARAM("YES")}</Option>)
    options.push(<Option key={false}>{OTHERSYSPARAM("NO")}</Option>)
                
    //---------------------------------------------------------------
    // Call back to parent component to set selected option key value
    //---------------------------------------------------------------
    const onSelectionChange = (e, value) => {
        if(e != undefined && value != undefined)
            onChange(e, value)
        else
            onChange(0, "")
    }

    return(
        <Select defaultValue={defaultValue} style={{width: optionWidth}} onChange={onSelectionChange} allowClear showSearch optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {options}
        </Select>
    )
}

export default TrueFalseSelect