import { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import { Form, Input, Button, message, Space, Layout, Row, Col, Popconfirm, Modal, Descriptions, Select, Switch, Spin } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { SaveOutlined, CloseOutlined, DeleteOutlined, QuestionCircleOutlined, LockOutlined } from "@ant-design/icons"
import MainHeader from '../common/header'
import MainFooter from '../common/footer'
import { reportError } from '../common/utility'
import axios from "axios"
import { APIURL, LOADING, MENUPATH_USER, SEARCHOPTIONVIEWWIDTH } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, retrieveBackgroundColor } from "../common/usersession"
import EstateSelect from '../common/estateselect'
import UserGroupSelect from '../common/usergroupselect'
import { walltiles } from '../common/layout'

const { Header, Footer, Content } = Layout
const { confirm } = Modal
const { Option } = Select


const UserUpdate = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
                
    const [form] = Form.useForm()
    const [disableDeleteButton, setDisableDeleteButton] = useState(false)
    const [allowedEstateIds, setAllowedEstateIds] = useState([])
    const [allowedEstatesOption, setAllowedEstatesOption] = useState([])
                                   
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [defaultEstateId, setDefaultEstateId] = useState(urlParams.get("defaultEstateId"))
    const [userGroupId, setUserGroupId] = useState(urlParams.get("groupId"))

    const [allowedEstatesBuffer, setAllowedEstatesBuffer] = useState({})
    const [isActive, setIsActive] = useState(urlParams.get("isActive") == "true" ? true: false)
    const [isLoading, setIsLoading] = useState(false)
    
    const updateUser = () => {
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${APIURL}user/update/${urlParams.get("id")}/`, {
                username: values.username,
                email: values.email,
                password: values.password,
                default_estate: defaultEstateId,
                allowed_estates: allowedEstateIds,
                user_group: userGroupId,
                is_active: isActive,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`User ${urlParams.get("username")} updated.`)
                navigate(MENUPATH_USER)
            })
            .catch( error => {
                reportError(error, "Failed to update user.")
            })
            .finally(() => {
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    const onDelete = () => {
        setIsLoading(true)
        
        axios.delete(`${APIURL}user/delete/${urlParams.get("id")}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`User ${urlParams.get("username")} deleted.`)
            navigate(MENUPATH_USER)
        })
        .catch( error => {
            reportError(error, "Failed to delete user.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const getAllowableEstatesOption = (defaultEstateId) => {
        setIsLoading(true)

        return new Promise((resolve, reject) => {
            axios.get(`${APIURL}estate/`, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}})
            .then( response => {
                const data = response.data.results.filter(estate => estate.pkey != defaultEstateId)
                let options = data.map( estate => <Option key={estate.pkey}>{estate.name}</Option>)
                setAllowedEstatesOption(options)

                let dict = {}
                data.forEach( estate => {
                    dict[estate.name] = estate.pkey
                })
                setAllowedEstatesBuffer(dict)
                resolve()
            })
            .catch( error => {
                reportError(error, "Failed to get estate(s).")
                reject()
            })
            .finally(() => {
                setIsLoading(false)
                refreshUserSession()
            })
        })
    }

    const getUserInfo = () => {
        setIsLoading(true)

        axios.get(`${APIURL}userpaginated/`, {
            params: {
                id: urlParams.get("id")
            }, 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            form.setFieldsValue({
                username: response.data.results[0].username,
                email: response.data.results[0].email,
            })    

            const allowed_estates = response.data.results[0].allowed_estates.filter(estate => estate.default == false)
            if(allowed_estates.length > 0) {
                setAllowedEstateIds(allowed_estates.map(estate => estate.estate_data.pkey))
                form.setFieldsValue({
                     allowedEstates: allowed_estates.map(estate => estate.estate_data.name)
                })
            }
        })
        .catch( error => {
            reportError(error, "Failed to get estate(s).")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    // Determine if has related record.
    const toDisableDeleteButton = () => {
        setIsLoading(true)

        axios.get(`${APIURL}userdownloaded/${urlParams.get("id")}/`, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response.data.downloaded)
                setDisableDeleteButton(true)
        })
        .catch( error => {
            reportError(error, "Failed to determine if user has been downloaded.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onCancel = () => {
        navigate(MENUPATH_USER)
    }

    const onUpdate = () => {
        form.validateFields()
        .then( values => {
            if(String(values.password).trim() != "" && String(values.password).trim().length < 6) {
                message.warning("Invalid reset password.")
                return
            }

            if(String(values.confirmPassword).trim() != "" && String(values.confirmPassword).trim().length < 6) {
                message.warning("Invalid confirm password.")
                return
            }

            if(String(values.password).trim() != "" && values.password != values.confirmPassword) {
                message.warning("Password do not match.")
                return
            }
            else {
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Update user confirmed?</p></Space>,
                    onOk() { updateUser() },
                    onCancel() {},
                    centered: true
                })
            }
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    const validateDefaultEstate = (() => {
        if(defaultEstateId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Default estate is required."))
    })

    const validateUserGroup = (() => {
        if(userGroupId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("User group is required."))
    })

    const onDefaultEstateChange = (e, value) => {
        setAllowedEstatesOption([])
        setAllowedEstateIds([])

        form.setFieldsValue({
            allowedEstates: []
        })
        
        if(e != undefined) {
            setDefaultEstateId(e)
            getAllowableEstatesOption(e)
        }
        else 
            setDefaultEstateId(0)
    }

    const onAllowedEstatesChange = (e) => {
        let filteredIds = []
        e.forEach( i => {
            if(allowedEstatesBuffer[i] === undefined)
                filteredIds.push(parseInt(i))
            else 
                filteredIds.push(allowedEstatesBuffer[i])
        })

        filteredIds = Array.from(new Set(filteredIds))
        setAllowedEstateIds(filteredIds)
    }

    const onUserGroupChange = (e, value) => {
        if(e != undefined) 
            setUserGroupId(e)
        else 
            setUserGroupId(0)
    }

    const onIsActiveChange = (checked) => {
        setIsActive(checked)
    }

    useEffect(() => {
        toDisableDeleteButton()

        const callAsyncFunc = async () => {
            await getAllowableEstatesOption(urlParams.get("defaultEstateId"))
        }
        callAsyncFunc()

        getUserInfo()
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles, backgroundColor: retrieveBackgroundColor()}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onCancel()} 
                    title="User">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update user</Descriptions.Item>
                    </Descriptions>
                </PageHeader>
        
                <Form form={form} onFinish={onUpdate} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                    <Form.Item name="username" label="Username"
                        rules={[
                            { required: true, message: "Username is required."},
                        ]}>
                        <Input maxLength={150} placeholder="Username" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="email" label="Email"
                        rules={[
                            { required: true, type: "email", message: "Email is required."},
                        ]}>
                        <Input maxLength={254} placeholder="Email" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="password" label="Reset Password">
                        <Input.Password prefix={<LockOutlined type="lock" />} minLength={6} maxLength={20} placeholder="New Password" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="confirmPassword" label="Confirm Reset Password">
                        <Input.Password prefix={<LockOutlined type="lock" />} minLength={6} maxLength={20} placeholder="Confirm New Password" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="defaultEstate" label="Default Estate"
                        rules={[
                            { required: true, validator: validateDefaultEstate },
                        ]}>
                        <EstateSelect withBlank={false} allowClear={false} onChange={onDefaultEstateChange} defaultValue={urlParams.get("defaultEstate")} marginLeft={10}/>
                    </Form.Item>

                    <Form.Item name="allowedEstates" label="Other Allowed Estate(s)">
                        <Select mode="multiple"style={{width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10}} onChange={onAllowedEstatesChange} allowClear showSearch optionFilterProp="children" 
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} >
                            {allowedEstatesOption}
                        </Select>
                    </Form.Item>

                    <Form.Item name="userGroup" label="User Group"
                        rules={[
                            { required: true, validator: validateUserGroup },
                        ]}>
                        <UserGroupSelect withBlank={false} allowClear={false} onChange={onUserGroupChange} defaultValue={urlParams.get("group")} marginLeft={10}/>
                    </Form.Item> 

                    <Form.Item name="isActive" label="Is Active">
                        <Switch defaultChecked={isActive} onChange={onIsActiveChange} style={{ marginLeft: 10 }}/>
                    </Form.Item>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>Update</Button>
                            <Popconfirm title="Delete user confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" disabled={disableDeleteButton} icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" onClick={onCancel} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                        title="User:"
                        subTitle="Update user"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default UserUpdate