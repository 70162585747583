import { useState, useEffect } from 'react'
import { useNavigate } from "react-router"
import { Form, Input, Button, message, Layout, Row, Col, Spin, Typography } from 'antd'
import { UserOutlined, LockOutlined } from "@ant-design/icons"
import axios from "axios"
import { URL, ALLOWED_ESTATES, LOADING, MEDIAMAXWIDTH, TIMEOUT, WALLPAPER, MENUPATH_CHANGEPASSWORD, MENUPATH_TREE, MENUPATH_DELIVERYTRIP, MENUPATH_DASHBOARD } from "./systemparameter"
import MainFooter from './footer'
import { formLayout } from "./layout"
import { clearUserSession, createUserSession, getUserGroup, OTHERSYSPARAM, storeBackgroundColor, storeBorderColor, storeSystemParameter } from './usersession'
import { loadSystemParameters } from './loadsystemparameters'
import { checkUnregisteredInfo, reportError } from './utility'
import { useCookies } from "react-cookie"
import { DARKTHEMEBACKGROUNDCOLOR, DARKTHEMEBORDERCOLOR, LIGHTTHEMEBACKGROUNDCOLOR, LIGHTTHEMEBORDERCOLOR } from '../common/systemparameter'

const { Content, Footer } = Layout
const DEFAULTBREADCRUMB = "POPS (Palm Oil Plantation System) 2023"
const { Title } = Typography


const Login = () => {
    const navigate = useNavigate()
    const [cookies, setCookies] = useCookies(['useDarkTheme'])
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    // Set theme colors.
    if(cookies['useDarkTheme'] == '0' || cookies['useDarkTheme'] == undefined) {
        storeBackgroundColor(LIGHTTHEMEBACKGROUNDCOLOR)
        storeBorderColor(LIGHTTHEMEBORDERCOLOR)
    }
    else {
        storeBackgroundColor(DARKTHEMEBACKGROUNDCOLOR)
        storeBorderColor(DARKTHEMEBORDERCOLOR)
    }
    
    //--------------
    // To login
    //--------------
    const onSubmit = () => {
        setLoading(true)

        form.validateFields()
        .then( values => {
            axios.post(`${URL}rest-auth/login/`, {
                username: values.username,
                password: values.password
            }, {
                timeout: parseInt(TIMEOUT) * 2
            })
            .then( response => {
                const defaultEstate = response.data.allowed_estates.filter(estate => estate.default == true)
                
                storeSystemParameter(ALLOWED_ESTATES, response.data.allowed_estates.map(estate => estate.estate))
                                
                createUserSession(
                    response.data.allowed_estates[0].user_id,
                    values.username, 
                    response.data.user_groups.map( group => group.name), 
                    defaultEstate[0].estate_data.pkey,
                    defaultEstate[0].estate_data.name,
                    response.data.key)

                // Load system parameters.
                loadSystemParameters()
                .then(() => {
                    // Enter system main page.
                    // if(getUserGroup() == OTHERSYSPARAM("SYSTEMIMPLEMENTOR") || getUserGroup() == OTHERSYSPARAM("ADMINOFFICER") || getUserGroup() == OTHERSYSPARAM("MANDUR") || getUserGroup() == OTHERSYSPARAM("FIELDCONDUCTOR"))
                    //     navigate(MENUPATH_TREE)
                    // else if(getUserGroup() == OTHERSYSPARAM("LORRYDRIVER"))
                    //     navigate(MENUPATH_DELIVERYTRIP)
                    navigate(MENUPATH_DASHBOARD)

                    // Check for unregistered info. If found, prompt.
                    if(getUserGroup() == OTHERSYSPARAM("SYSTEMIMPLEMENTOR") || getUserGroup() == OTHERSYSPARAM("ADMINOFFICER"))
                        checkUnregisteredInfo()
                })
                .catch(error => {
                    clearUserSession()
                    message.error("System anomaly detected.")
                })
            })
            .catch( error => {
                if(String(error).includes("timeout")) message.error("Login timeout.")
                reportError(error)
            })
            .finally(() => {
                setLoading(false)
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }

    useEffect(() => {
        clearUserSession()    
    }, [])
    
    return(
        <Spin spinning={loading} size="large" tip={LOADING}>
        <Layout>
            <Content style={{
                backgroundImage: `url(${WALLPAPER})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'}}>

                <Row justify='center' style={{height: "calc(100vh - 58px)", alignContent: "center"}}>
                    <Col xs={24} xl={8}>
                        <Row justify="center">
                            <Col>
                                <Title style={{ color: 'white' }}>PALM OIL PLANTATION SYSTEM</Title>
                            </Col>
                        </Row>
                    
                        <Form form={form} onFinish={onSubmit} {...formLayout}>
                            <Form.Item name="username" label=""
                                rules={[
                                    { required: true, message: "Username is required."},
                                ]}>
                                <Input prefix={<UserOutlined type="user" />} placeholder="Username"/>
                            </Form.Item>

                            <Form.Item name="password" label="" 
                                rules={[
                                    { required: true, message: "Password is required."},
                                ]}>
                                <Input.Password prefix={<LockOutlined type="lock" />} placeholder="Password"/>
                            </Form.Item>
                        
                            <Row justify="center">
                                <Col span={8}></Col>
                                <Col span={8} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="submit" loading={loading} style={{ borderColor: "white" }}>Login</Button>
                                </Col>
                                <Col span={8}></Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Content>
            <Footer>
                <MainFooter breadCrumb={DEFAULTBREADCRUMB}/>
            </Footer>
        </Layout>
        </Spin>
    )
}

export default Login