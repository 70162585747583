import { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import { Form, Button, Space, Layout, Row, Col, Popconfirm, message, DatePicker, Card, Modal, Input, Descriptions, Image, Upload, Spin, Table } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { SaveOutlined, CloseOutlined, DeleteOutlined, QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons"
import MainFooter from '../common/footer'
import { APIURL, FALLBACK, URL, LOADING, MENUPATH_ATTENDANCE, DATETIMEFORMAT, UNIDATETIMEFORMAT, SEARCHOPTIONVIEWWIDTH, MAXPHOTOS } from "../common/systemparameter"
import { reportError } from "../common/utility"
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserGroup, retrieveBackgroundColor } from "../common/usersession"
import dayjs from 'dayjs'
import AttendanceTypeSelect from './atttendancetypeselect'
import MainHeader from '../common/header'
import WorkerSelect from '../common/workerselect'
import { walltiles } from '../common/layout'

const { Header, Footer, Content } = Layout
const { confirm } = Modal


const AttendanceUpdate = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [uploading, setUploading] = useState(false)

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)
    const attendance = JSON.parse(urlParams.get("attendance"))
    
    const [photoDataSource, setPhotoDataSource] = useState([])
        
    const [attendanceTypePKey, setAttendanceTypePKey] = useState(attendance.attendanceTypePKey)
    const [workerPKey, setWorkerPKey] = useState(attendance.workerPKey)
    const [clockedOn, setClockedOn] = useState(dayjs(attendance.clockedOnUnformatted), UNIDATETIMEFORMAT)
    const [photoFile, setPhotoFile] = useState([])

    const disable = getUserGroup() == OTHERSYSPARAM('SYSTEMIMPLEMENTOR') ? false : true
    const hasUnregisteredInfo = attendance.workerPKey != "0" ? false : true

    const removePhoto = (e, pkey) => {
        e.stopPropagation()
        setIsLoading(true)
        
        axios.patch(`${APIURL}attendance/removephoto/${pkey}/`, {}, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info("Photo removed.")
            setPhotoDataSource(photoDataSource.filter(photo => photo.pkey != pkey))
        })
        .catch( error => {
            reportError(error, `Failed to remove attendance photo.`)
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }
    
    const updateAttendance = () => {
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${APIURL}attendance/update/${attendance.key}/`, {
                clocked_on: clockedOn,
                attendance_type: attendanceTypePKey,
                worker: workerPKey,
                remark: values.remark,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Attendance record updated.")
                navigate(MENUPATH_ATTENDANCE)
            })
            .catch( error => {
                reportError(error, `Failed to update attendance record.`)
            })
            .finally(() => {
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }

    const onDelete = () => {
        setIsLoading(true)
        
        axios.delete(`${APIURL}attendance/delete/${attendance.key}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Attendance record deleted.`)
            navigate(MENUPATH_ATTENDANCE)
        })
        .catch( error => {
            reportError(error, "Failed to delete attendance record.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onSave = () => {
        if(photoDataSource.length == 0) {
            message.warning('Photo is required.')
            return
        }

        form.validateFields()
        .then( values => {
            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>Update attendance record confirmed?</p></Space>,
                onOk() { updateAttendance() },
                onCancel() {},
                centered: true
            })
        })
    }

    const onCancel = () => {
        navigate(MENUPATH_ATTENDANCE)
    }

    const onAttendanceTypeChange = (e, value) => {
        if(e != undefined) 
            setAttendanceTypePKey(e)
        else 
            setAttendanceTypePKey(0)
    }

    const onWorkerChange = (e, value) => {
        if(e != undefined) 
            setWorkerPKey(e)
        else 
            setWorkerPKey(0)
    }

    const onClockedOnChange = (datetime) => {
        setClockedOn(datetime)
    }

    const validateAttendanceType = (() => {
        if(attendanceTypePKey != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Attendance type is required."))
    })

    const validateClockedOn = (() => {
        if(clockedOn != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Clocked on is required."))
    })

    const props = {
        name: "img",
        action: `${APIURL}attendance/uploadphoto/${attendance.key}/`,
        timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 10,
        headers: {
            Authorization: `Token ${getUserAuthToken()}`,
        },
        beforeUpload: file => {
            if(photoDataSource.length >= MAXPHOTOS) {
                setUploading(false)
                setIsLoading(false)
                message.error(`Maximum of only ${MAXPHOTOS} photos are allowed.`)
                setPhotoFile([])
                return false
            }

            const isImage = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif'
            if(!isImage) {
                setUploading(false)
                setIsLoading(false)
                message.error(`${file.name} is not an image file.`)
                setPhotoFile([])
                return false
            }

            setPhotoFile([file])
        },
        onChange(info) {
            setUploading(true)

            if(info.file.status !== 'uploading') {
                setUploading(false)
                //setIsLoading(true)
            }

            if(info.file.status === 'done') {
                setUploading(false)
                setIsLoading(false)
                setPhotoFile([])

                setPhotoDataSource(
                    [{pkey: info.fileList[0].response.pkey, img: `${URL.substring(0, URL.length - 1)}${info.fileList[0].response.img}?now=${dayjs().valueOf()}`}]
                    .concat(photoDataSource)
                )    

                message.info("Photo uploaded.")
            }
            else if(info.file.status === 'error') {
                setUploading(false)
                message.error(`"${info.file.name}" upload failed.`)
                message.error(info.file.response?.detail)
            }
        },
        maxCount: 1,
        fileList: photoFile,
    }

    useEffect(() => {
        const photos = []
        attendance.photos.forEach( photo => {
            photos.push({
                pkey: photo.pkey,
                img: `${photo.img}?now=${dayjs().valueOf()}`
            })
        })
        setPhotoDataSource(photos)

        form.setFieldsValue({
            remark: attendance.remark
        })
    }, [])

    const columns = [
        { title: 'Photo', dataIndex: 'img', key: 'img', align: 'center',
            render: (img, record) => {
                if(img != null) 
                    return <>
                        <Image width={100} fallback={FALLBACK} src={img} onClick={(e) => e.stopPropagation() }/>
                    </>
                else
                    return null
            }
        },
        { key: 'action', align: 'center',
            render: (record) => {
                return <Popconfirm title="Delete photo confirmed?" 
                    onClick={e => e.stopPropagation()} onCancel={e => e.stopPropagation()} onConfirm={e => removePhoto(e, record.pkey)} 
                    okText="Yes" cancelText="No">
                    <Button danger type="primary" icon={<DeleteOutlined/>} disabled={disable}/>
                </Popconfirm>
            }
        }
    ]

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles, backgroundColor: retrieveBackgroundColor()}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader 
                    onBack={() => onCancel()} 
                    title="Attendance">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update attendance record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row align='center'>
                    <Col span={8} style={{textAlign: "start"}} >
                        {/* <Card title={<Title level={5}>{`Worker: ${urlParams.get("name")}`}</Title>}> */}
                        <Card title={`Worker: ${attendance.name}`}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Checker:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{attendance.checker}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Created On:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{attendance.createdOn}</Card.Grid>
                    
                            { attendance.updatedOn != attendance.createdOn &&
                                <>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Updated On:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{attendance.updatedOn}</Card.Grid>
                                </>
                            }
                            { attendance.uploadedOn != "" &&
                                <>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Uploaded On:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{attendance.uploadedOn}</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Uploaded By:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{attendance.uploadedBy}</Card.Grid>
                                </>
                            }
                        </Card>
                    </Col>
                </Row>
                    
                <Row><Col><Space><div /></Space></Col></Row>

                <Form form={form} onFinish={onSave} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                    <Form.Item name="clockedOn" label="Clocked On"
                        rules={[
                            { required: true, validator: validateClockedOn },
                        ]}>
                        <DatePicker showTime onChange={onClockedOnChange} disabled={disable} defaultValue={clockedOn} format={DATETIMEFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="attendanceType" label="Attendance Type"
                        rules={[
                            { required: true, validator: validateAttendanceType},
                        ]}>
                        <AttendanceTypeSelect onChange={onAttendanceTypeChange} withBlank={false} allowClear={false} defaultValue={attendance.attendanceType} marginLeft={10} />
                    </Form.Item>

                    { hasUnregisteredInfo &&
                        <Form.Item name="worker" label="Worker">
                        <WorkerSelect onChange={onWorkerChange} withBlank={false} allowClear={false} marginLeft={10}/>
                    </Form.Item>
                    }

                    <Form.Item name="remark" label="Remark" >
                        <Input placeholder="Remark" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }}/>
                    </Form.Item>

                    {/* { !disable &&  */}
                    <Form.Item name="photoFile" label={`Photo (max. ${MAXPHOTOS})`}>
                        <Upload {...props} accept=".jpg,.png,.gif">
                            <Button icon={<UploadOutlined />} loading={uploading}>Add Photo</Button>
                        </Upload>
                    </Form.Item>
                    {/* } */}

                    {/* <Row>
                        <Col span={6} />
                            <Col span={12} style={{textAlign: "center"}}>
                                { !disableRemovePhotoButton && 
                                    <Image 
                                        width={IMAGEWIDTH} 
                                        //height = {IMAGEHEIGHT}
                                        src={photoFileURI}
                                        fallback={FALLBACK}
                                        preview={true} />
                                }
                            </Col>
                        <Col span={6} />
                    </Row> */}

                    <Row justify="center">
                        <Col span={3}>
                            <Table bordered columns={columns} dataSource={photoDataSource} pagination={false} />
                        </Col>
                    </Row>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined/>}>Update</Button>
                            <Popconfirm title="Delete attendance record confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" onClick={onCancel} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                    title="Attendance:"
                    subTitle="Update attendance record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default AttendanceUpdate