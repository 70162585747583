import { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import { Form, Input, Button, message, Space, Layout, Row, Col, Popconfirm, Modal, Descriptions, Spin } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { SaveOutlined, CloseOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import MainHeader from '../common/header'
import MainFooter from '../common/footer'
import { APIURL, LOADING, MENUPATH_FACTORY, SEARCHOPTIONVIEWWIDTH } from '../common/systemparameter'
import { reportError } from '../common/utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, retrieveBackgroundColor } from "../common/usersession"
import { walltiles } from '../common/layout'

const { Header, Footer, Content } = Layout
const { confirm } = Modal


const FactoryUpdate = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
            
    const [form] = Form.useForm()
    const [disableDeleteButton, setDisableDeleteButton] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)
    const factory = JSON.parse(urlParams.get("factory"))
    const [estatePKey, setEstatePKey] = useState(factory.defaultEstatePKey)

    const updateFactory = () => {
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${APIURL}factory/update/${factory.key}/`, {
                name: values.factoryName,
                address: values.address,
                default_estate: estatePKey,
                remark: values.remark
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`Factory ${factory.name} updated.`)
                navigate(MENUPATH_FACTORY)
            })
            .catch( error => {
                reportError(error, "Failed to update factory.")
            })
            .finally(() => {
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    const onDelete = () => {
        setIsLoading(true)
        
        axios.delete(`${APIURL}factory/delete/${factory.key}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Factory ${factory.name} deleted.`)
            navigate(MENUPATH_FACTORY)
        })
        .catch( error => {
            reportError(error, "Failed to delete factory.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    // Determine if has related record.
    const toDisableDeleteButton = () => {
        setIsLoading(true)

        axios.get(`${APIURL}factorydownloaded/${factory.key}/`, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response.data.downloaded)
                setDisableDeleteButton(true)
        })
        .catch( error => {
            reportError(error, "Failed to determine if factory was downloaded.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onCancel = () => {
        navigate(MENUPATH_FACTORY)
    }

    const onUpdate = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Update factory confirmed?</p></Space>,
            onOk() { updateFactory() },
            onCancel() {},
            centered: true
        })
    }

    const onEstateChange = (e, value) => {
        if(e != undefined) 
            setEstatePKey(e)
        else 
            setEstatePKey(0)
    }

    useEffect(() => {
        toDisableDeleteButton()
        
        form.setFieldsValue({
            factoryName: factory.name,
            address: factory.address,
            remark: factory.remark
        })
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles, backgroundColor: retrieveBackgroundColor()}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onCancel()} 
                    title="Factory">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update factory</Descriptions.Item>
                    </Descriptions>
                </PageHeader>
        
                <Form form={form} onFinish={onUpdate} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                    <Form.Item name="factoryName" label="Factory Name"
                        rules={[
                            { required: true, message: "Factory name is required."},
                        ]}>
                        <Input maxLength={50} placeholder="Factory Name" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="address" label="Address">
                        <Input maxLength={250} placeholder="Address" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="remark" label="Remark">
                        <Input placeholder="Remark" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>Update</Button>
                            <Popconfirm title="Delete factory confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" disabled={disableDeleteButton} icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" onClick={onCancel} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                        title="Factory:"
                        subTitle="Update factory"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default FactoryUpdate