import { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import { Form, Button, Space, Layout, Row, Col, Modal, Descriptions, Spin, Card, Table, Pagination } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { CloseOutlined } from "@ant-design/icons"
import MainHeader from '../common/header'
import MainFooter from '../common/footer'
import { APIURL, LOADING, MENUPATH_TREE, DATEFORMAT, DATETIMEFORMAT, PAGINATIONSIZE, PAGESIZE } from '../common/systemparameter'
import { reportError } from '../common/utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, retrieveBackgroundColor } from "../common/usersession"
import dayjs from 'dayjs'
import { walltiles } from '../common/layout'

const { Header, Footer, Content } = Layout
const { confirm } = Modal


const TreeViewDead = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const [treeLocationHistoryDataSource, setTreeLocationHistoryDataSource] = useState([])

    // Unpack url search parameters
    const param = new URLSearchParams(window.location.search)
    const urlParams = JSON.parse(param.get("tree"))
        
    const getTreeLocationHistory = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}treelocationhistory/`, {
            params: { 
                tree: urlParams["key"],
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to get tree location history.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( history => {
            data.push({
                key: history.pkey,
                block: history.block,
                row: history.row,
                number: history.number,
                remark: history.remark,
                lastInLocationOn: dayjs(history.last_in_location_on).format(DATEFORMAT),
                uploadedOn: history.uploaded_on != null ? dayjs(history.uploaded_on).format(DATETIMEFORMAT) : '',
                uploadedBy: history.uploader_data != null ? history.uploader_data.username : '',
            })
        })
        
        setTreeLocationHistoryDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const onPaginationChange = (page) => {
        setCurrentPage(page)
        getTreeLocationHistory(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onCancel = () => {
        navigate(MENUPATH_TREE)
    }

    useEffect(() => {
        getTreeLocationHistory(currentPage)
    }, [])

    const columns = [
        { title: 'Last In Location', dataIndex: 'lastInLocationOn', key: 'lastInLocationOn', sorter: (a, b) => a.lastInLocationOn.localeCompare(b.lastInLocationOn) },
        { title: 'Block', dataIndex: 'block', key: 'block', sorter: (a, b) => a.block.localeCompare(b.block) },
        { title: 'Row', dataIndex: 'row', key: 'row', sorter: (a, b) => a.row.localeCompare(b.row) },
        { title: 'Palm', dataIndex: 'number', key: 'number', sorter: (a, b) => a.number.localeCompare(b.number) },
        { title: 'Remark', dataIndex: 'remark', key: 'remark', sorter: (a, b) => a.remark.localeCompare(b.remark) },
        { title: 'Uploaded On', dataIndex: 'uploadedOn', key: 'uploadedOn', sorter: (a, b) => a.uploadedOn.localeCompare(b.uploadedOn) },
        { title: 'Uploaded By', dataIndex: 'uploadedBy', key: 'uploadedBy', sorter: (a, b) => a.uploadedBy.localeCompare(b.uploadedBy) },
    ]

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles, backgroundColor: retrieveBackgroundColor()}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onCancel()} 
                    title="Tree Record (Dead)">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">View dead tree record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row align='center'>
                    <Col span={8} style={{textAlign: "start"}} >
                        <Card title={`Tree ID: ${urlParams["treeId"]}`}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Species:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{urlParams["species"]}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Gender:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{urlParams["gender"]}</Card.Grid>
                            {/* <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Health Condition:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{urlParams["healthCondition"]}</Card.Grid> */}
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Planted On:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{urlParams["plantedOn"]}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Dead On:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{urlParams["deadOn"]}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Block:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{urlParams["block"]}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Row:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{urlParams["row"]}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Palm:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{urlParams["number"]}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Remark:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{urlParams["remark"]}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Created On:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{urlParams["createdOn"]}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Updated On:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{urlParams["updatedOn"]}</Card.Grid>
                            
                            { urlParams["uploadedOn"] != "" &&
                                <>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Uploaded On:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{urlParams["uploadedOn"]}</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ width: "50%" }}>Uploaded By:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ width: "50%" }}>{urlParams["uploadedBy"]}</Card.Grid>
                                </>
                            }
                        </Card>
                    </Col>
                </Row>

                <Row><Col><Space><div /></Space></Col></Row>

                <Row justify="center">
                    <Col span={6}></Col>
                    <Col span={12} style={{textAlign: "center"}}>
                        <Button type="primary" htmlType="button" onClick={onCancel} icon={<CloseOutlined />}>Cancel</Button>
                    </Col>
                    <Col span={6}></Col>
                </Row>
            </Content>

            <Row><Col><Space><div /></Space></Col></Row>

            { totalRecord > 0 &&
            <Row justify={"center"}>
                <Col span={22}>
                    <Table bordered columns={columns} dataSource={treeLocationHistoryDataSource} pagination={false}/>
                </Col>
            </Row>
            }

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                    title="Tree Record (Dead):"
                    subTitle="View dead tree record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default TreeViewDead
