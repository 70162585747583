import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { APIURL, MEDIAMAXWIDTH, SEARCHOPTIONVIEWWIDTH,  } from './systemparameter'
import { reportError } from './utility'
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "./usersession"
import { useMediaQuery } from 'react-responsive'

const { Option } = Select


const WorkerSelect = ({withBlank, defaultValue, onChange, allowClear, noStyle = false, marginLeft = 0}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [workerOption, setWorkerOption] = useState([])
    const [optionWidth, setOptionWidth] = useState(!noStyle ? isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : SEARCHOPTIONVIEWWIDTH : SEARCHOPTIONVIEWWIDTH)
        
    const getAllWorker = () => {
        axios.get(`${APIURL}worker/`, {
            params: { 
                estate: 0//getUserEstateId()
            }, 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( worker => <Option key={worker.pkey}>{worker.name} ({worker.employee_id})</Option>))
            setWorkerOption(options)
        })
        .catch( error => {
            reportError(error, `<WorkerSelect /> Failed to get worker.`)
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const onSelectionChange = (e, value) => {
         onChange(e, value)
    }

    useEffect(() => {
        getAllWorker()
    }, []) 
    
    return(
        <Select style={{width: optionWidth, marginLeft: marginLeft}} onChange={onSelectionChange} allowClear={allowClear} showSearch defaultValue={defaultValue}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.value ?? '').includes(input)}
            filterSort={(optionA, optionB) => (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.value ?? '').toLowerCase())}>
            
            {workerOption}
        </Select>
    )
}

export default WorkerSelect