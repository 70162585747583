import { Table, Row, Col, Space, Button, Collapse, Pagination, message, Spin, DatePicker, InputNumber } from 'antd'
import { DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, LOADING, PAGESIZE, PAGINATIONSIZE, SEARCHOPTIONVIEWWIDTH, UNIDATETIMEFORMAT, UNIDATEFORMAT, DATEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserEstateId, getUserEstateName } from "../common/usersession"
import { reportError } from '../common/utility'
import dayjs from 'dayjs';
import ActivityIntervalTypeSelect from '../common/activityintervaltypeselect'
//import TrueFalseSelect from '../common/truefalseselect'

const { Panel } = Collapse
const { RangePicker } = DatePicker


const SlashingSummaryReportTable = () => {
    const FileDownload = require('js-file-download')
    
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const [slashingDataSource, setSlashingDataSource] = useState([])
    const [slashedOnDateRange, setSlashedOnDateRange] = useState([dayjs().add(-1, 'month').add(1, 'day'), dayjs()])
    const [block, setBlock] = useState(-1)
    const [row, setRow] = useState(-1)

    const [slashingTypePKey, setSlashingTypePKey] = useState(0)
    const [slashingType, setSlashingType] = useState('')
    
    const getSummary = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}slashingsummarypaginated/`, {
            params: { 
                estate: getUserEstateId(),
                block: block,
                row: row,
                from_date: slashedOnDateRange[0] == null ? null : slashedOnDateRange[0].format(UNIDATEFORMAT),
                to_date: slashedOnDateRange[1] == null ? null : slashedOnDateRange[1].format(UNIDATEFORMAT),
                slashing_type: slashingTypePKey,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 2,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search slashing summary.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( summary => {
            data.push({
                key: summary.id,
                block: summary.block,
                row: summary.row,
                slashedDate: dayjs(dayjs(summary.slashed_date, "YYYY-MM-DD")).format(DATEFORMAT),
                slashingType: summary.slashing_type_name,
                numberOfTreeSlashed: summary.number_of_tree_slashed,
                rowTreeCount: summary.row_tree_count,
                percentageOfTreeSlashed: summary.percentage_of_tree_slashed.toFixed(2),
            })
        })
        
        setSlashingDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const downloadSearchResult = (mode) => {
        setIsLoading(true)
                        
        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Estate: ${getUserEstateName()}`
        if(block != -1) searchCriteria = `${searchCriteria}\nBlock: ${block}`
        if(row != -1) searchCriteria = `${searchCriteria}\nRow: ${row}`
        if(slashedOnDateRange[0] != null) searchCriteria = `${searchCriteria}\nSlashed On: ${slashedOnDateRange[0].format(DATEFORMAT)} to ${slashedOnDateRange[1].format(DATEFORMAT)}`
        if(slashingTypePKey != 0) searchCriteria = `${searchCriteria}\nSlashing Type: ${slashingType}`
                                                        
        axios.get(`${APIURL}slashingsummary/download/`, {
            params: { 
                estate: getUserEstateId(),
                block: block,
                row: row,
                from_date: slashedOnDateRange[0] == null ? null : slashedOnDateRange[0].format(UNIDATEFORMAT),
                to_date: slashedOnDateRange[1] == null ? null : slashedOnDateRange[1].format(UNIDATEFORMAT),
                slashing_type: slashingTypePKey,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 12,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Slashing Summary ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onPaginationChange = (page) => {
        setCurrentPage(page)
        getSummary(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSearch = () => {
        setCurrentPage(1)
        getSummary(1)
    }

    const onReset = () => {
        window.location.reload()
    }

    const onSlashedOnDateRangeChange = (dateRange) => {
        if(dateRange == null)
            setSlashedOnDateRange([null, null])
        else
            setSlashedOnDateRange(dateRange)
    }

    const onBlockChange = (e, value) => {
        if(e !== null)
            setBlock(e)
        else 
            setBlock(-1)
    }

    const onRowChange = (e, value) => {
        if(e !== null)
            setRow(e)
        else 
            setRow(-1)
    }

    const onActivityIntervalTypeChange = (e, value) => {
        setSlashingTypePKey(e)
        setSlashingType(value.children)
    }

    useEffect(() => {
        getSummary(currentPage)
    }, [])

    const columns = [
        { title: 'Block', dataIndex: 'block', key: 'block', align: 'center', sorter: (a, b) => a.block - b.block },
        { title: 'Row', dataIndex: 'row', key: 'row', align: 'center', sorter: (a, b) => a.row -b.row },
        { title: 'Slashed On', dataIndex: 'slashedDate', key: 'slashedDate', align: "center", sorter: (a, b) => a.slashedDate.localeCompare(b.slashedDate) },
        { title: 'Slashing Type', dataIndex: 'slashingType', key: 'slashingType', align: 'center', sorter: (a, b) => a.slashingType.localeCompare(b.slashingType) },
        { title: 'Tree Slashed', dataIndex: 'numberOfTreeSlashed', key: 'numberOfTreeSlashed', align: 'right', sorter: (a, b) => a.numberOfTreeSlashed - b.numberOfTreeSlashed },
        { title: 'Row Tree Count', dataIndex: 'rowTreeCount', key: 'rowTreeCount', align: 'right', sorter: (a, b) => a.rowTreeCount - b.rowTreeCount },
        { title: 'Tree Slashed (%)', dataIndex: 'percentageOfTreeSlashed', key: 'percentageOfTreeSlashed', align: 'right', sorter: (a, b) => a.percentageOfTreeSlashed.localeCompare(b.percentageOfTreeSlashed) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search slashing" key="1">
                    <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Block :</Col>
                            <Col span={3}>
                                <InputNumber onChange={onBlockChange} style={{ width: SEARCHOPTIONVIEWWIDTH }} min={0} max={100000} step={1} precision={0} placeholder="Block"/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Slashed On :</Col>
                            <Col span={3}>
                                <RangePicker onChange={onSlashedOnDateRangeChange} defaultValue={slashedOnDateRange} format={DATEFORMAT}/>
                            </Col>

                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            </>
                        </Row>   

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Row :</Col>
                            <Col span={3}>
                                <InputNumber onChange={onRowChange} style={{ width: SEARCHOPTIONVIEWWIDTH }} min={0} max={100000} step={1} precision={0} placeholder="Row"/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Slashing Type :</Col>
                            <Col span={3}>
                                <ActivityIntervalTypeSelect withBlank={true} allowClear={true} onChange={onActivityIntervalTypeChange} noStyle={true}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            </>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} loading={isLoading} icon={<DownloadOutlined />} />
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} loading={isLoading} icon={<FileExcelOutlined />} />
                                <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                        
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={slashingDataSource} pagination={false} />

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={24} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
        </Row>
        </Spin>
        </>
    )
}

export default SlashingSummaryReportTable