import { Row, Col, Space, Button, Collapse, message, Spin, DatePicker } from 'antd'
import { DownloadOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, LOADING, MONTHFORMAT, SEARCHOPTIONVIEWWIDTH, UNIDATEFORMAT, UNIDATETIMEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../common/usersession"
import { reportError } from '../common/utility'
import dayjs from 'dayjs';
import EstateSelect from '../common/estateselect'

const { Panel } = Collapse


const DailyHarvestReportTable = () => {
    const FileDownload = require('js-file-download')
    const [isLoading, setIsLoading] = useState(false)
    const [reportMonth, setReportMonth] = useState(dayjs())
    const [estatePKey, setEstatePKey] = useState(0)
    
    const downloadExcelReport = () => {
        if(reportMonth == null) {
            message.warning('Report month is required.')
            return
        }

        setIsLoading(true)
                        
        axios.get(`${APIURL}dailyharvestexcelreport/download/`, {
            params: { 
                estate: estatePKey,
                report_month: dayjs(reportMonth).format(UNIDATEFORMAT),
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 12),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                FileDownload(response.data, `Daily Harvest Report ${now}.xlsx`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onEstateChange = (e, value) => {
        if(e != undefined) 
            setEstatePKey(e)
        else 
            setEstatePKey(0)
    }

    const onReportMonthChange = (month) => {
        setReportMonth(month)
    }

    useEffect(() => {
    }, [])

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="" key="1">
                        <Row justify="center">
                            <>
                            <Col span={17} />
                            </>
                        </Row>   

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Report Month :</Col>
                            <Col span={3}>
                                <DatePicker picker="month" onChange={onReportMonthChange} defaultValue={reportMonth} format={MONTHFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }} allowClear={false}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Estate :</Col>
                            <Col span={3}>
                                <EstateSelect withBlank={true} onChange={onEstateChange} allowClear={true} marginLeft={10}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            </>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={downloadExcelReport} loading={isLoading} icon={<DownloadOutlined />} />
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        </Spin>
        </>
    )
}

export default DailyHarvestReportTable