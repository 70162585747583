import  { useEffect, useState } from 'react'
import { Modal, message, Row, Col, InputNumber, Space, Select } from 'antd'
import axios from "axios"
import { APIURL, SEARCHOPTIONVIEWWIDTH } from "./systemparameter"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserEstateId, retrieveTreeBlock, retrieveTreeNumber, storeTreeBlock, storeTreeRow, storeTreeNumber, retrieveTreeRow } from "./usersession"
import { reportError } from "./utility"

const { Option } = Select


const SearchTreeByLocation = ({show, toClose, navigationCallBack}) => {
    const [palmOption, setPalmOption] = useState([])
            
    const [block, setBlock] = useState(!isNaN(retrieveTreeBlock()) ? retrieveTreeBlock() : null)
    const [row, setRow] = useState(!isNaN(retrieveTreeRow()) ? retrieveTreeRow() : null)
    const [number, setNumber] = useState(-1)
    
    const searchPalm = (block, row) => {
        axios.get(`${APIURL}treepalmbyestateblockrow/`, {
            params: { 
                estate: getUserEstateId(),
                block: block,
                row: row,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            setPalmOption(response.data.results.map( palm => <Option key={palm.number}>{palm.number}</Option> ))
        })
        .catch( error => {
            reportError(error, "Failed to search available tree.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const onBlockChange = (e) => {
        if(e !== null) 
            setBlock(e)
        else 
            setBlock(-1)

        if(e > -1 && row > -1) searchPalm(e, row)
    }

    const onRowChange = (e) => {
        if(e !== null) 
            setRow(e)
        else 
            setRow(-1)

        if(block > -1 && e > -1) searchPalm(block, e)
    }

    const onPalmSelectionChange = (e, value) => {
        if(e !== null) 
            setNumber(e)
        else 
            setNumber(-1)
    }

    const valid = () => {
        if(isNaN(parseInt(block)) || parseInt(block) <= 0) {
            message.warning('Invalid block.')
            return false
        }

        if(isNaN(parseInt(row)) || parseInt(row) <= 0) {
            message.warning('Invalid row.')
            return false
        }

        if(isNaN(parseInt(number)) || parseInt(number) <= 0) {
            message.warning('Invalid palm number.')
            return false
        }

        return true
    }

    const search = async () => {
        if(!valid()) return
        
        getTree(block, row, number)
        .then( tree => {
            if(tree != '') {
                storeTreeBlock(block)
                storeTreeRow(row)
                storeTreeNumber(number)
                navigationCallBack(tree)
            }
            else {
                message.warning('Tree at this location cannot be found.')
                return
            }
        })
        .catch( error => {
            reportError(error, "Failed to search tree.")
        })
    }

    const onClose = () => {
        toClose()
    }

    useEffect(() => {
        searchPalm(block, row)
    }, [show])

    //-----------
    // Main modal
    //-----------
    return(
        <Modal
            open={show}
            title={<div><h3>Search Tree</h3></div>}
            icon={null}
            width={400}
            centered={true}
            closable={false}
                        
            onOk={search}
            okText={"Search"}
            onCancel={onClose}
            cancelText={"Cancel"}>
            <Row>
                <Col span={13}>
                    <Row justify={'center'}>
                        <Col span={6} style={{ textAlign: 'left'}}>Block:</Col>
                        <Col span={1} />
                        <Col span={6}>
                            <InputNumber defaultValue={block != -1 ? block : ''} onChange={onBlockChange} style={{ width: SEARCHOPTIONVIEWWIDTH }} min={0} max={100000} step={1} precision={0} placeholder="Block"/>
                        </Col>
                    </Row>
                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}>Row:</Col>
                        <Col span={1} />
                        <Col span={6}>
                            <InputNumber defaultValue={row != -1 ? row : ''} onChange={onRowChange} style={{ width: SEARCHOPTIONVIEWWIDTH }} min={0} max={100000} step={1} precision={0} placeholder="Row"/>
                        </Col>
                    </Row>

                    <Row><Col><Space><div /></Space></Col></Row>
                    
                    <Row justify="center">
                        <Col span={6}>Palm:</Col>
                        <Col span={1} />
                        <Col span={6}>
                            <Select style={{ width: SEARCHOPTIONVIEWWIDTH }} onChange={onPalmSelectionChange} allowClear showSearch 
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.value ?? '').includes(input)}
                                filterSort={(optionA, optionB) => (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.value ?? '').toLowerCase())}>
                                {palmOption}
                            </Select>
                        </Col>
                    </Row>  
                </Col>
            </Row>
        </Modal>
    )
}

export default SearchTreeByLocation

const getTree = (block, row, number) => {
    return new Promise((resolve, reject) => {
        axios.get(`${APIURL}tree/`, {
            params: { 
                estate: getUserEstateId(),
                block: block,
                row: row,
                number: number
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            resolve(response.data)
        })
        .catch( error => {
            reject(error)
        })
        .finally(() => {
            refreshUserSession()
        })
    })
}