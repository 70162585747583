import { Table, Row, Col, Space, Button, Collapse, Pagination, message, Spin, DatePicker, InputNumber } from 'antd'
import { DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, LOADING, PAGESIZE, PAGINATIONSIZE, SEARCHOPTIONVIEWWIDTH, UNIDATETIMEFORMAT, UNIDATEFORMAT, DATEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserEstateId, getUserEstateName } from "../common/usersession"
import { reportError } from '../common/utility'
import dayjs from 'dayjs';
import ActivityIntervalTypeSelect from '../common/activityintervaltypeselect'
//import TrueFalseSelect from '../common/truefalseselect'

const { Panel } = Collapse
const { RangePicker } = DatePicker


const PruningSummaryReportTable = () => {
    const FileDownload = require('js-file-download')
    
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const [pruningDataSource, setPruningDataSource] = useState([])
    const [prunedOnDateRange, setPrunedOnDateRange] = useState([dayjs().add(-1, 'month').add(1, 'day'), dayjs()])
    const [block, setBlock] = useState(-1)
    const [row, setRow] = useState(-1)

    const [pruningTypePKey, setPruningTypePKey] = useState(0)
    const [pruningType, setPruningType] = useState('')
    
    const getSummary = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}pruningsummarypaginated/`, {
            params: { 
                estate: getUserEstateId(),
                block: block,
                row: row,
                from_date: prunedOnDateRange[0] == null ? null : prunedOnDateRange[0].format(UNIDATEFORMAT),
                to_date: prunedOnDateRange[1] == null ? null : prunedOnDateRange[1].format(UNIDATEFORMAT),
                pruning_type: pruningTypePKey,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 2,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search pruning summary.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( summary => {
            data.push({
                key: summary.id,
                block: summary.block,
                row: summary.row,
                prunedDate: dayjs(dayjs(summary.pruned_date, "YYYY-MM-DD")).format(DATEFORMAT),
                pruningType: summary.pruning_type_name,
                numberOfTreePruned: summary.number_of_tree_pruned,
                rowTreeCount: summary.row_tree_count,
                percentageOfTreePruned: summary.percentage_of_tree_pruned.toFixed(2),
            })
        })
        
        setPruningDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const downloadSearchResult = (mode) => {
        setIsLoading(true)
                        
        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Estate: ${getUserEstateName()}`
        if(block != -1) searchCriteria = `${searchCriteria}\nBlock: ${block}`
        if(row != -1) searchCriteria = `${searchCriteria}\nRow: ${row}`
        if(prunedOnDateRange[0] != null) searchCriteria = `${searchCriteria}\nPruned On: ${prunedOnDateRange[0].format(DATEFORMAT)} to ${prunedOnDateRange[1].format(DATEFORMAT)}`
        if(pruningTypePKey != 0) searchCriteria = `${searchCriteria}\nPruning Type: ${pruningType}`
                                                        
        axios.get(`${APIURL}pruningsummary/download/`, {
            params: { 
                estate: getUserEstateId(),
                block: block,
                row: row,
                from_date: prunedOnDateRange[0] == null ? null : prunedOnDateRange[0].format(UNIDATEFORMAT),
                to_date: prunedOnDateRange[1] == null ? null : prunedOnDateRange[1].format(UNIDATEFORMAT),
                pruning_type: pruningTypePKey,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 12,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Pruning Summary ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onPaginationChange = (page) => {
        setCurrentPage(page)
        getSummary(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSearch = () => {
        setCurrentPage(1)
        getSummary(1)
    }

    const onReset = () => {
        window.location.reload()
    }

    const onPrunedOnDateRangeChange = (dateRange) => {
        if(dateRange == null)
            setPrunedOnDateRange([null, null])
        else
            setPrunedOnDateRange(dateRange)
    }

    const onBlockChange = (e, value) => {
        if(e !== null)
            setBlock(e)
        else 
            setBlock(-1)
    }

    const onRowChange = (e, value) => {
        if(e !== null)
            setRow(e)
        else 
            setRow(-1)
    }

    const onPruningTypeChange = (e, value) => {
        setPruningTypePKey(e)
        setPruningType(value.children)
    }

    useEffect(() => {
        getSummary(currentPage)
    }, [])

    const columns = [
        { title: 'Block', dataIndex: 'block', key: 'block', align: 'center', sorter: (a, b) => a.block - b.block },
        { title: 'Row', dataIndex: 'row', key: 'row', align: 'center', sorter: (a, b) => a.row - b.row },
        { title: 'Pruned On', dataIndex: 'prunedDate', key: 'prunedDate', align: "center", sorter: (a, b) => a.prunedDate.localeCompare(b.prunedDate) },
        { title: 'Pruning Type', dataIndex: 'pruningType', key: 'pruningType', align: 'center', sorter: (a, b) => a.pruningType.localeCompare(b.pruningType) },
        { title: 'Tree Pruned', dataIndex: 'numberOfTreePruned', key: 'numberOfTreePruned', align: 'right', sorter: (a, b) => a.numberOfTreePruned - b.numberOfTreePruned },
        { title: 'Row Tree Count', dataIndex: 'rowTreeCount', key: 'rowTreeCount', align: 'right', sorter: (a, b) => a.rowTreeCount - b.rowTreeCount },
        { title: 'Tree Pruned (%)', dataIndex: 'percentageOfTreePruned', key: 'percentageOfTreePruned', align: 'right', sorter: (a, b) => a.percentageOfTreePruned.localeCompare(b.percentageOfTreePruned) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search pruning" key="1">
                    <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Block :</Col>
                            <Col span={3}>
                                <InputNumber onChange={onBlockChange} style={{ width: SEARCHOPTIONVIEWWIDTH }} min={0} max={100000} step={1} precision={0} placeholder="Block"/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Pruned On :</Col>
                            <Col span={3}>
                                <RangePicker onChange={onPrunedOnDateRangeChange} defaultValue={prunedOnDateRange} format={DATEFORMAT}/>
                            </Col>

                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            </>
                        </Row>   

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <>
                            <Col span={3}  style={{ textAlign: 'end', marginRight: 10 }}>Row :</Col>
                            <Col span={3}>
                                <InputNumber onChange={onRowChange} style={{ width: SEARCHOPTIONVIEWWIDTH }} min={0} max={100000} step={1} precision={0} placeholder="Row"/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Pruning Type :</Col>
                            <Col span={3}>
                                <ActivityIntervalTypeSelect withBlank={true} allowClear={true} onChange={onPruningTypeChange} noStyle={true}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            </>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} loading={isLoading} icon={<DownloadOutlined />} />
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} loading={isLoading} icon={<FileExcelOutlined />} />
                                <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                        
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={pruningDataSource} pagination={false} />

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={24} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
        </Row>
        </Spin>
        </>
    )
}

export default PruningSummaryReportTable