import { useEffect } from 'react'
import { Layout, Row, Col, Space, Descriptions } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import MainHeader from '../common/header'
import MainFooter from '../common/footer'
import UploadedTreeRecordTable from './uploadedtreerecordtable'
import { OTHERSYSPARAM, retrieveBackgroundColor } from '../common/usersession'
import { walltiles } from '../common/layout'

const { Header, Footer, Content } = Layout


const UploadedTreeRecordMain = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
    
    useEffect(() => {
    })

    return(
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles, backgroundColor: retrieveBackgroundColor()}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>

                <PageHeader 
                    onBack={null} 
                    title="Uploaded Tree Record">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">View uploaded tree record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row justify="center">
                    <Col span={1}></Col>
                    <Col span={22}><UploadedTreeRecordTable /></Col>
                    <Col span={1}></Col>
                </Row>

            </Content>

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader 
                        onBack={null} 
                        title="Uploaded Tree Record:"
                        subTitle="View uploaded tree record"/>} />
            </Footer>
        </Layout>
    )
}

export default UploadedTreeRecordMain