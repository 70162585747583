import { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import { Form, Button, Space, Layout, Row, Col, Popconfirm, message, Modal, Descriptions, Spin, Upload, DatePicker, InputNumber, Input } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { SaveOutlined, LeftOutlined, QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons"
import MainHeader from '../common/header'
import MainFooter from '../common/footer'
import { APIURL, DATEFORMAT, DATETIMEFORMAT, LOADING, MAXPHOTOS, MENUPATH_FACTORYRECEIPT, SEARCHOPTIONVIEWWIDTH, UNIDATEFORMAT } from '../common/systemparameter'
import { reportError } from '../common/utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserEstateId, retrieveBackgroundColor } from "../common/usersession"
import dayjs from 'dayjs'
import LorrySelect from '../common/lorryselect'
import DriverSelect from '../common/driverselect'
import FactorySelect from '../common/factoryselect'
import { walltiles } from '../common/layout'

const { Header, Footer, Content } = Layout
const { confirm } = Modal


const FactoryReceiptNew = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)

    const [lorryPKey, setLorryPKey] = useState(0)
    const [driverPKey, setDriverPKey] = useState(0)
    const [factoryPKey, setFactoryPKey] = useState(0)
    const [factory, setFactory] = useState('')
    const [collectedOn, setCollectedOn] = useState(dayjs())
    const [photoFile, setPhotoFile] = useState([])

    const createFactoryReceipt = () => {
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.post(`${APIURL}factoryreceipt/create/`, {
                estate: getUserEstateId(),
                factory: factoryPKey,
                chit_number: values.chitNumber,
                lorry: lorryPKey,
                driver: driverPKey,
                gross_weight_kg: values.grossWeightKg,
                tare_weight_kg: values.tareWeightKg,
                nett_weight_kg: values.nettWeightKg,
                collected_on: dayjs(collectedOn).format(UNIDATEFORMAT),
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: { "Authorization": `Token ${getUserAuthToken()}`, }
            })
            .then( response => {
                let data = new FormData()
                photoFile.forEach( file => {
                    data.append("img", file)
                })

                data.append("factoryreceipt", response.data.pkey)
                            
                fetch(`${APIURL}factoryreceipt/uploadphoto/${response.data.pkey}/`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Token ${getUserAuthToken()}`,
                    },
                    body: data,
                })
                .then( response => {
                    message.info("Factory receipt record saved.")
                    navigate(MENUPATH_FACTORYRECEIPT)
                })
                .catch( error => {
                    reportError(error, `Failed to upload photo.`)
                })
                .finally(() => {
                    refreshUserSession()
                })
            })
            .catch( error => {
                reportError(error, `Failed to save factory receipt record.`)
            })
            .finally(() => {
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onSave = async (e) => {
        form.validateFields()
        .then( values => {
            if(parseInt(values.tareWeightKg) > parseInt(values.grossWeightKg)) {
                message.warning('Tare weight is more than gross weight.')
                return
            }

            if(parseInt(values.nettWeightKg) > parseInt(values.grossWeightKg)) {
                message.warning('Nett weight is more than gorss weight.')
                return
            }

            if(photoFile.length == 0) {
                message.warning('Photo is required.')
                return
            }
    
            if(photoFile.length > MAXPHOTOS) {
                message.warning(`Maximum of only ${MAXPHOTOS} photos are allowed.`)
                return
            }
    
            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>Create factory receipt record confirmed?</p></Space>,
                onOk() { createFactoryReceipt() },
                onCancel() {},
                centered: true
            })
        })
    }

    const onCollectedOnChange = (datetime) => {
        setCollectedOn(datetime)
    }

    const validateCollectedOn = (() => {
        if(collectedOn != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Collected on is required."))
    })

    const validateLorry = (() => {
        if(lorryPKey != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Lorry number plate is required."))
    })

    const validateDriver = (() => {
        if(driverPKey != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Driver is required."))
    })

    const validateFactory = (() => {
        if(factoryPKey != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Factory is required."))
    })

    const onLorryChange = (e, value) => {
        if(e != undefined) 
            setLorryPKey(e)
        else 
            setLorryPKey(0)
    }

    const onDriverChange = (e, value) => {
        if(e != undefined) 
            setDriverPKey(e)
        else 
            setDriverPKey(0)
    }

    const onFactoryChange = (e, value) => {
        if(e != undefined) 
            setFactoryPKey(e)
        else 
            setFactoryPKey(0)
    }

    const onBack = () => {
        navigate(MENUPATH_FACTORYRECEIPT)
    }

    const props = {
        onRemove: file => {
            setPhotoFile(photoFile.filter(photo => photo.name != file.name))
        },
        beforeUpload: (file, fileList) => {
            const isImage = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif'
            if(!isImage) {
                message.error(`${file.name} is not an image file.`)
                setPhotoFile([])
                return false
            }

            setPhotoFile(fileList)
            return false
        },
        multiple: true,
        maxCount: {MAXPHOTOS},
        fileList: photoFile,
    }

    useEffect(() => {
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles, backgroundColor: retrieveBackgroundColor()}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onBack()} 
                    title="Factory Receipt">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create factory receipt record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                    <Form.Item name="collectedOn" label="Collected On"
                        rules={[
                            { required: true, validator: validateCollectedOn },
                        ]}>
                        <DatePicker onChange={onCollectedOnChange} defaultValue={collectedOn} format={DATEFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item name="chitNumber" label="Chit Number"
                        rules={[
                            { required: true, message: 'Chit number is required.' },
                        ]}>
                        <Input placeholder="Chit Number" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }} />
                    </Form.Item>
                    
                    <Form.Item name="grossWeightKg" label="Gross Weight (Kg)"
                        rules={[
                            { required: true, message: 'Gross weight is required.' },
                        ]}>
                        <InputNumber min={1} max={100000} step={1} precision={0} placeholder="Gross Weight (Kg)" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item name="tareWeightKg" label="Tare Weight (Kg)"
                        rules={[
                            { required: true, message: 'Tare weight is required.' },
                        ]}>
                        <InputNumber min={1} max={100000} step={1} precision={0} placeholder="Tare Weight (Kg)" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item name="nettWeightKg" label="Nett Weight (Kg)"
                        rules={[
                            { required: true, message: 'Nett weight is required.' },
                        ]}>
                        <InputNumber min={1} max={100000} step={1} precision={0} placeholder="Nett Weight (Kg)" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item name="lorry" label="Lorry Number Plate"
                        rules={[
                            { required: true, validator: validateLorry },
                        ]}>
                        <LorrySelect withBlank={false} allowClear={false} onChange={onLorryChange} marginLeft={10}/>
                    </Form.Item>

                    <Form.Item name="driver" label="Driver"
                        rules={[
                            { required: true, validator: validateDriver },
                        ]}>
                        <DriverSelect withBlank={false} allowClear={false} onChange={onDriverChange} marginLeft={10}/>
                    </Form.Item>
                    
                    <Form.Item name="factory" label="Factory"
                        rules={[
                            { required: true, validator: validateFactory },
                        ]}>
                        <FactorySelect withBlank={false} allowClear={false} onChange={onFactoryChange} marginLeft={10} defaultValue={factory}/>
                    </Form.Item>

                    <Form.Item name="photoFile" label={`Photo (max. ${MAXPHOTOS})`}
                        rules={[ 
                            { required: true }
                            ]}>
                        <Upload {...props} accept=".jpg,.png,.gif">
                            <Button icon={<UploadOutlined />}>Add Photo</Button>
                        </Upload>
                    </Form.Item> 

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" icon={<LeftOutlined />}>Cancel</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined/>}>Save</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Factory Receipt:"
                    subTitle="Create factory receipt record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default FactoryReceiptNew