import { Table, Row, Col, Space, Button, Pagination, message, Spin, Modal, Popconfirm } from 'antd'
import { PlusOutlined, QuestionCircleOutlined, DeleteOutlined, DownloadOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, LOADING, PAGESIZE, PAGINATIONSIZE, UNIDATETIMEFORMAT_FILE } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../common/usersession"
import { reportError } from '../common/utility'
import dayjs from 'dayjs';

const { confirm } = Modal


const TreeRecordZippedFileTable = () => {
    const FileDownload = require('js-file-download')

    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const [treeZippedFileDataSource, setTreeZippedFileDataSource] = useState([])

    const deleteTreeRecordZippedFile = (e, record) => { 
        setIsLoading(true)
        
        axios.delete(`${APIURL}tree/deletezipped/${record.key}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Tree record zipped file deleted.`)
            window.location.reload()
        })
        .catch( error => {
            reportError(error, "Failed to delete tree record zipped file.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const requestNewZippedFile = () => {
        axios.post(`${APIURL}tree/createzippedfile/`, {}, {
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info('Request for new tree record zipped file sent to server.')
        })
        .catch( error => {
            reportError(error, `Failed to send request for create latest tree record zipped file.`)
        })
    }
    
    const getTreeZippedFile = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}treezippedfile/`, {
            params: { 
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search tree record zipped file.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( file => {
            data.push({
                key: file.pkey,
                filename: `${dayjs(file.created_on).format(UNIDATETIMEFORMAT_FILE)}.zip`,
                fileSize: `${file.file_size_kb}`
            })
        })
        
        setTreeZippedFileDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const onPaginationChange = (page) => {
        setCurrentPage(page)
        getTreeZippedFile(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onCreateNew = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>This action will request server to generate new tree record zipped file. If you have sent request in past few minutes, please refresh this page to see latest list. Procceed?</p></Space>,
            onOk() { requestNewZippedFile() },
            onCancel() {},
            centered: true
        })
    }

    const downloadTreeRecordZippedFile = (e, record) => {
        axios.get(`${APIURL}tree/downloadzipped/${record.key}/`, {
            params: { 
            },
            responseType: "blob",
            timeout: 60 * 60 * 1000,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else 
                FileDownload(response.data, record.filename)
        })
        .catch( error => {
            reportError(error, "Failed to download tree record zipped file.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    useEffect(() => {
        getTreeZippedFile(currentPage)
    }, [])

    const columns = [
        { title: 'Filename', dataIndex: 'filename', key: 'filename', sorter: (a, b) => a.filename.localeCompare(b.filename) },
        { title: 'File Size (KB)', dataIndex: 'fileSize', key: 'fileSize', align: 'center', sorter: (a, b) => a.fileSize.localeCompare(b.fileSize) },
        { key: 'action', align: 'center', width: 200,
            render: (record) => {
                return <>
                    <Button type="primary" disabled={isLoading} icon={<DownloadOutlined/>} onClick={e => downloadTreeRecordZippedFile(e, record)}/>
                    <Popconfirm title="Delete tree record zipped file confirmed?" 
                        onClick={e => e.stopPropagation()} onCancel={e => e.stopPropagation()} onConfirm={e => deleteTreeRecordZippedFile(e, record)} 
                        okText="Yes" cancelText="No">
                        <Button danger type="primary" disabled={isLoading} icon={<DeleteOutlined/>} />
                    </Popconfirm>
                    </>
            }
        }
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>Generate Latest Tree Record Zipped File</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={treeZippedFileDataSource} pagination={false}/>
        
        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={6}></Col>
            <Col span={12} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={6} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>Generate Latest Tree Record Zipped File</Button></Col>
        </Row>
        </Spin>
        </>
    )
}

export default TreeRecordZippedFileTable