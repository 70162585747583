import { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import { Form, Button, Space, Layout, Row, Col, Popconfirm, message, Modal, Input, Descriptions, Spin, DatePicker, Upload } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { SaveOutlined, LeftOutlined, QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons"
import MainHeader from '../common/header'
import MainFooter from '../common/footer'
import { APIURL, DATEFORMAT, LOADING, MENUPATH_WORKER, SEARCHOPTIONVIEWWIDTH } from '../common/systemparameter'
import { reportError } from '../common/utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, retrieveBackgroundColor } from "../common/usersession"
import EstateSelect from '../common/estateselect'
import { walltiles } from '../common/layout'
import EmploymentTypeSelect from '../common/employmenttypeselect'
import dayjs from 'dayjs'

const { Header, Footer, Content } = Layout
const { confirm } = Modal


const WorkerNew = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)

    const [estateId, setEstateId] = useState(0)
    const [employmentTypeId, setEmploymentTypeId] = useState(0)
    const [employedOn, setEmployedOn] = useState(dayjs())
    const [photoFile, setPhotoFile] = useState([])
        
    const createWorker = () => {
        setIsLoading(true)

        form.validateFields()
        .then( values => {
            axios.post(`${APIURL}worker/create/`, {
                estate: estateId,
                name: values.name,
                employment_type: employmentTypeId,
                employee_id: values.employeeId,
                employed_on: employedOn,
                remark: values.remark,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                let data = new FormData()
                photoFile.forEach( file => {
                    data.append("img", file)
                })
                            
                fetch(`${APIURL}worker/uploadphoto/${response.data.pkey}/`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Token ${getUserAuthToken()}`,
                    },
                    body: data,
                })
                .then( response => {
                    message.info(`New worker ${values.name} created.`)
                    navigate(MENUPATH_WORKER)
                })
                .catch( error => {
                    reportError(error, `Failed to upload photo.`)
                })
                .finally(() => {
                    refreshUserSession()
                })
            })
            .catch( error => {
                reportError(error, "Failed to create new worker.")
            })
            .finally(() => {
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    const onSave = async (e) => {
        if(photoFile.length == 0) {
            message.warning('Photo is required.')
            return
        }

        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Create worker confirmed?</p></Space>,
            onOk() { createWorker() },
            onCancel() {},
            centered: true
        })
    }

    const onBack = () => {
        navigate(MENUPATH_WORKER)
    }

    const onEstateChange = (e, value) => {
        if(e != undefined) 
            setEstateId(e)
        else 
            setEstateId(0)
    }

    const onEmploymentTypeChange = (e, value) => {
        if(e != undefined) 
            setEmploymentTypeId(e)
        else 
            setEmploymentTypeId(0)
    }

    const onEmployedOnChange = (datetime) => {
        setEmployedOn(datetime)
    }
    
    const validateEstate = (() => {
        if(estateId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Estate is required."))
    })

    const validateEmploymentType = (() => {
        if(employmentTypeId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Employment type is required."))
    })

    const validateEmployedOn = (() => {
        if(employedOn != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Employment date is required."))
    })

    const props = {
        onRemove: file => {
            setPhotoFile([])
        },
        beforeUpload: file => {
            const isImage = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif'
            if(!isImage) {
                message.error(`${file.name} is not an image file.`)
                setPhotoFile([])
                return false
            }

            setPhotoFile([file])
            return false
        },
        maxCount: 1,
        photoFile,
        
    }

    useEffect(() => {
        form.setFieldsValue({
            remark: ''
        })
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles, backgroundColor: retrieveBackgroundColor()}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onBack()} 
                    title="Worker">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create worker</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                    <Form.Item name="name" label="Name"
                        rules={[
                            { required: true, message: "Worker name is required."},
                        ]}>
                        <Input maxLength={50} placeholder="Worker Name" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="employmentType" label="Employment Type"
                        rules={[
                            { required: true, validator: validateEmploymentType },
                        ]}>
                        <EmploymentTypeSelect withBlank={false} allowClear={false} onChange={onEmploymentTypeChange} marginLeft={10}/>
                    </Form.Item>

                    <Form.Item name="employedOn" label="Employment Date"
                        rules={[
                            { required: true, validator: validateEmployedOn },
                        ]}>
                        <DatePicker showTime onChange={onEmployedOnChange} defaultValue={employedOn} format={DATEFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="employeeId" label="Employee ID"
                        rules={[
                            { required: true, message: "Employee ID is required."},
                        ]}>
                        <Input maxLength={50} placeholder="Employee ID" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="estate" label="Estate"
                        rules={[
                            { required: true, validator: validateEstate },
                        ]}>
                        <EstateSelect withBlank={false} allowClear={false} onChange={onEstateChange} marginLeft={10}/>
                    </Form.Item>

                    <Form.Item name="remark" label="Remark">
                        <Input placeholder="Remark" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="photoFile" label="Photo">
                        <Upload {...props} accept=".jpg,.png,.gif">
                            <Button icon={<UploadOutlined />}>Select Photo</Button>
                        </Upload>
                    </Form.Item> 

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" icon={<LeftOutlined />}>Cancel</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined/>}>Save</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Worker:"
                    subTitle="Create worker"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default WorkerNew