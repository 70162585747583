import { Table, Row, Col, Space, Button, Collapse, Pagination, message, Spin, Tag, Image } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, DATEFORMAT, LOADING, PAGESIZE, PAGINATIONSIZE, UNIDATETIMEFORMAT, FALLBACK } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../common/usersession"
import { useNavigate } from "react-router"
import { reportError } from '../common/utility'
import moment from 'moment'
import CommonSearchFormItem from '../common/commonsearchformitem'
import dayjs from 'dayjs'

const { Panel } = Collapse


const WorkerTable = () => {
    const FileDownload = require('js-file-download')
    const navigate = useNavigate()

    const [workerId, setWorkerId] = useState(0)
    const [worker, setWorker] = useState("")
    const [estateId, setEstateId] = useState(0)
    const [estate, setEstate] = useState("")
    const [employmentTypeId, setEmploymentTypeId] = useState(0)
    const [employmentType, setEmploymentType] = useState("")
    const [workerDataSource, setWorkerDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    
    const searchWorker = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}workerpaginated/`, {
            params: { 
                estate: estateId,
                employment_type: employmentTypeId,
                worker: workerId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search worker.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( worker => {
            data.push({
                key: worker.pkey,
                employmentTypeId: worker.employment_type_data.pkey,
                employmentType: worker.employment_type_data.name,
                estateId: worker.estate_data.pkey,
                estate: worker.estate_data.name,
                name: worker.name,
                employeeId: worker.employee_id,
                employedOn: dayjs(worker.employed_on).format(UNIDATETIMEFORMAT),
                employedOnFormatted: dayjs(worker.employed_on).format(DATEFORMAT),
                active: worker.active,
                remark: worker.remark,
                img: `${worker.img}?now=${new Date()}`,
            })
        })
        
        setWorkerDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const downloadSearchResult = (mode) => {
        setIsLoading(true)
                        
        // Build search criteria string for display in pdf
        let searchCriteria = ""
        if(estateId != 0) searchCriteria = `${searchCriteria}\Estate: ${estate}`
        if(employmentTypeId != 0) searchCriteria = `${searchCriteria}\Employment Type: ${employmentType}`
        if(workerId != 0) searchCriteria = `${searchCriteria}\nName: ${worker}`
                                                
        axios.get(`${APIURL}worker/download/`, {
            params: { 
                estate: estateId,
                employment_type: employmentTypeId,
                worker: workerId,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 12),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Worker ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchWorker(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSearch = () => {
        setCurrentPage(1)
        searchWorker(1)
    }

    const onReset = () => {
        window.location.reload()
    }

    const onCreateNew = () => {
        navigate("/workernew")
    }

    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/workerupdate", 
                    search: `?pkey=${record.key}&estateId=${record.estateId}&estate=${record.estate}&name=${record.name}&employeeId=${record.employeeId}&active=${record.active}&remark=${record.remark}&employmentTypeId=${record.employmentTypeId}&employedOn=${record.employedOn}&img=${record.img}`
                }) 
            }
        }
    }

    const onSearchWorkerChange = (e, value) => {
        if(e != undefined) {
            setWorkerId(e)
            setWorker(value.children)
        }
        else {
            setWorkerId(0)
            setWorker("")
        }
    }

    const onSearchEstateChange = (e, value) => {
        if(e != undefined) {
            setEstateId(e)
            setEstate(value.children)
        }
        else {
            setEstateId(0)
            setEstate("")
        }
    }

    const onSearchEmploymentTypeChange = (e, value) => {
        if(e != undefined) {
            setEmploymentTypeId(e)
            setEmploymentType(value.children)
        }
        else {
            setEmploymentTypeId(0)
            setEmploymentType("")
        }
    }

    useEffect(() => {
        searchWorker(currentPage)
    }, [])

    const columns = [
        { title: 'Name', dataIndex: 'name', key: 'name', align: 'center', sorter: (a, b) => a.name.localeCompare(b.name) },
        { title: 'Employee ID', dataIndex: 'employeeId', key: 'employeeId', align: 'center', sorter: (a, b) => a.employeeId.localeCompare(b.employeeId) },
        { title: 'Employment Type', dataIndex: 'employmentType', key: 'employmentType', align: 'center', sorter: (a, b) => a.employmentType.localeCompare(b.employmentType) },
        { title: 'Employment Date', dataIndex: 'employedOnFormatted', key: 'employedOnFormatted', align: 'center', sorter: (a, b) => a.employedOnFormatted.localeCompare(b.employedOnFormatted) },
        { title: 'Estate', dataIndex: 'estate', key: 'estate', align: 'center', sorter: (a, b) => a.estate.localeCompare(b.estate) },
        { title: 'Is Active', dataIndex: 'active', key: 'active', align: 'center', sorter: (a, b) => a.active - b.active,
            render: (isActive) => {
                if(isActive)
                    return <Tag color="blue">{OTHERSYSPARAM("YES")}</Tag>
                else
                    return <Tag color="red">{OTHERSYSPARAM("NO")}</Tag>
            }
        },
        { title: 'Remark', dataIndex: 'remark', key: 'remark', },
        { title: 'Photo', dataIndex: 'img', key: 'img', align: 'center',
            render: (img, record) => {
                if(img != null) 
                    return <Image width={50} fallback={FALLBACK} src={img} onClick={(e) => e.stopPropagation() }/>
                else
                    return null
            }
        },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search worker" key="1">
                        <CommonSearchFormItem onEstateChange={onSearchEstateChange} onWorkerChange={onSearchWorkerChange} onEmploymentTypeChange={onSearchEmploymentTypeChange}/>
                        
                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} loading={isLoading} icon={<DownloadOutlined />} />
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} loading={isLoading} icon={<FileExcelOutlined />} />
                                <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={workerDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>
        </Spin>
        </>
    )
}

export default WorkerTable