import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { APIURL, MEDIAMAXWIDTH, SEARCHOPTIONVIEWWIDTH,  } from '../common/systemparameter'
import { reportError } from '../common/utility'
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "../common/usersession"
import { useMediaQuery } from 'react-responsive'

const { Option } = Select

const FertilizerSelect = ({withBlank, defaultValue, allowClear, onChange}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : SEARCHOPTIONVIEWWIDTH)
    const [fertilizerOption, setFertilizerOption] = useState([])
    
            
    const getAllFertilizer = () => {
        axios.get(`${APIURL}fertilizer/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( fertilizer => <Option key={fertilizer.pkey}>{fertilizer.name}</Option>))
            setFertilizerOption(options)
        })
        .catch( error => {
            reportError(error, "<FertilizerSelect /> Failed to get fertilizer(s).")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const onSelectionChange = (e, value) => {
         onChange(e, value)
    }

    useEffect(() => {
        getAllFertilizer()
    }, []) 
    
    return(
        <Select style={{width: optionWidth}} onChange={onSelectionChange} allowClear={allowClear} showSearch optionFilterProp="children" defaultValue={defaultValue}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {fertilizerOption}
        </Select>
    )
}

export default FertilizerSelect