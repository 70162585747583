import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { APIURL, MEDIAMAXWIDTH, SEARCHOPTIONVIEWWIDTH,  } from './systemparameter'
import { reportError } from './utility'
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "./usersession"
import { useMediaQuery } from 'react-responsive'

const { Option } = Select


const DriverSelect = ({withBlank, defaultValue, onChange, allowClear, noStyle = false, marginLeft = 0}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [driverOption, setDriverOption] = useState([])
    const [optionWidth, setOptionWidth] = useState(!noStyle ? isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : SEARCHOPTIONVIEWWIDTH : SEARCHOPTIONVIEWWIDTH)
        
    const getAllDriver = () => {
        axios.get(`${APIURL}driver/`, {
            params: { 
                estate: 0//getUserEstateId()
            }, 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( driver => <Option key={driver.pkey}>{driver.name} ({driver.employee_id})</Option>))
            setDriverOption(options)
        })
        .catch( error => {
            reportError(error, `<DriverSelect /> Failed to get driver.`)
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const onSelectionChange = (e, value) => {
         onChange(e, value)
    }

    useEffect(() => {
        getAllDriver()
    }, []) 
    
    return(
        <Select style={{width: optionWidth, marginLeft: marginLeft}} onChange={onSelectionChange} allowClear={allowClear} showSearch optionFilterProp="children" defaultValue={defaultValue}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {driverOption}
        </Select>
    )
}

export default DriverSelect