import { Table, Row, Col, Space, Button, Collapse, Pagination, message, Spin, DatePicker, InputNumber } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT, LOADING, PAGESIZE, PAGINATIONSIZE, SEARCHOPTIONVIEWWIDTH, UNIDATEFORMAT, UNIDATETIMEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserEstateId, getUserEstateName } from "../common/usersession"
import { useNavigate } from "react-router"
import { reportError } from '../common/utility'
import dayjs from 'dayjs';
import WorkerSelect from '../common/workerselect'

const { Panel } = Collapse
const { RangePicker } = DatePicker


const FFBCollectionTable = () => {
    const FileDownload = require('js-file-download')
    const navigate = useNavigate()

    const [ffbDailyCollectionDataSource, setFFBDailyCollectionDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const [collectedOnDateRange, setCollectedOnDateRange] = useState([dayjs().add(-1, 'month').add(1, 'day'), dayjs()])
    const [workerPKey, setWorkerPKey] = useState(0)
    const [worker, setWorker] = useState("")
    const [block, setBlock] = useState(-1)
    
    const searchFFBCollection = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}ffbdailycollectionpaginated/`, {
            params: { 
                estate: getUserEstateId(),
                from_date: collectedOnDateRange[0] == null ? null : collectedOnDateRange[0].format(UNIDATEFORMAT),
                to_date: collectedOnDateRange[1] == null ? null : collectedOnDateRange[1].format(UNIDATEFORMAT),
                worker: workerPKey,
                block: block,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search ffb collection activity.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( ffbDailyCollection => {
            data.push({
                key: ffbDailyCollection.pkey,
                collectedOn: dayjs(ffbDailyCollection.collected_on).format(DATETIMEFORMAT),
                collectedOnUnformatted: ffbDailyCollection.collected_on,
                workerPKey: ffbDailyCollection.worker_data.pkey,
                worker: ffbDailyCollection.worker > 0 ? ffbDailyCollection.worker_data.name : ffbDailyCollection.unregistered_worker_name,
                employeeId: ffbDailyCollection.worker_data.employee_id,
                block: ffbDailyCollection.block,
                numberOfBunches: ffbDailyCollection.number_of_bunches,
                looseFruitBag: ffbDailyCollection.loose_fruit_bag,
                remark: ffbDailyCollection.remark,
                createdOn: dayjs(ffbDailyCollection.created_on).format(DATETIMEFORMAT),
                updatedOn: dayjs(ffbDailyCollection.updated_on).format(DATETIMEFORMAT),
                uploadedOn: ffbDailyCollection.uploaded_on != null ? dayjs(ffbDailyCollection.uploaded_on).format(DATETIMEFORMAT) : '',
                uploadedBy: ffbDailyCollection.uploader_data != null ? ffbDailyCollection.uploader_data.username : '',
            })
        })
        
        setFFBDailyCollectionDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const downloadSearchResult = (mode) => {
        if(collectedOnDateRange[0] == null) {
            message.warning('Date range is required.')
            return
        }

        setIsLoading(true)
                        
        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Estate: ${getUserEstateName()}`
        if(collectedOnDateRange[0] != null) searchCriteria = `${searchCriteria}\nCollected On: ${collectedOnDateRange[0].format(DATEFORMAT)} to ${collectedOnDateRange[1].format(DATEFORMAT)}`
        if(workerPKey != 0) searchCriteria = `${searchCriteria}\nWorker: ${worker}`
        if(block != -1) searchCriteria = `${searchCriteria}\nBlock: ${block}`
                                                        
        axios.get(`${APIURL}ffbdailycollection/download/`, {
            params: { 
                estate: getUserEstateId(),
                from_date: collectedOnDateRange[0] == null ? null : collectedOnDateRange[0].format(UNIDATEFORMAT),
                to_date: collectedOnDateRange[1] == null ? null : collectedOnDateRange[1].format(UNIDATEFORMAT),
                worker: workerPKey,
                block: block,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 12),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `FFB Daily Collection ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchFFBCollection(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSearch = () => {
        setCurrentPage(1)
        searchFFBCollection(1)
    }

    const onReset = () => {
        window.location.reload()
    }

    const onCreateNew = () => {
        navigate('/ffbdailycollectionnew')
    }

    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/ffbdailycollectionupdate", 
                    search: `?ffbDailyCollection=${JSON.stringify(record)}`
                }) 
            }
        }
    }

    const onCollectedOnDateRangeChange = (dateRange) => {
        if(dateRange == null)
            setCollectedOnDateRange([null, null])
        else
            setCollectedOnDateRange(dateRange)
    }

    const onSearchWorkerChange = (e, value) => {
        if(e != undefined) {
            setWorkerPKey(e)
            setWorker(value.children.join(''))
        }
        else {
            setWorkerPKey(0)
            setWorker("")
        }
    }

    const onBlockChange = (e, value) => {
        if(e !== null)
            setBlock(e)
        else 
            setBlock(-1)
    }

    useEffect(() => {
        searchFFBCollection(currentPage)
    }, [])

    const columns = [
        { title: 'Worker', dataIndex: 'worker', key: 'worker', align: 'center', sorter: (a, b) => a.worker.localeCompare(b.worker), 
            render: (worker, record) => {
                return {
                    props: {
                        style: {background: record.workerPKey > 0 ? '' : 'coral'}
                    },
                    children: <div>{worker}</div>
                }
            }
        },
        { title: 'Employee ID', dataIndex: 'employeeId', key: 'employeeId', align: 'center', sorter: (a, b) => a.employeeId.localeCompare(b.employeeId) },
        { title: 'Collected On', dataIndex: 'collectedOn', key: 'collectedOn', align: 'center', sorter: (a, b) => a.collectedOn.localeCompare(b.collectedOn) },
        { title: 'Block', dataIndex: 'block', key: 'block', align: 'center', sorter: (a, b) => a.block - b.block },
        { title: 'Number of Bunches', dataIndex: 'numberOfBunches', key: 'numberOfBunches', align: 'right', sorter: (a, b) => a.numberOfBunches - b.numberOfBunches },
        { title: 'Loose Fruit (Bag)', dataIndex: 'looseFruitBag', key: 'looseFruitBag', align: 'right', sorter: (a, b) => a.looseFruitBag.localeCompare(b.looseFruitBag) },
        { title: 'Uploaded By', dataIndex: 'uploadedBy', key: 'uploadedBy', align: 'center', sorter: (a, b) => a.uploadedBy.localeCompare(b.uploadedBy) },
        { title: 'Uploaded On', dataIndex: 'uploadedOn', key: 'uploadedOn', align: 'center', sorter: (a, b) => a.uploadedOn.localeCompare(b.uploadedOn) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search ffb daily collection" key="1">
                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Block :</Col>
                            <Col span={3}>
                                <InputNumber onChange={onBlockChange} style={{ width: SEARCHOPTIONVIEWWIDTH }} min={0} max={100000} step={1} precision={0} placeholder="Block"/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Collected On :</Col>
                            <Col span={3}>
                                <RangePicker onChange={onCollectedOnDateRangeChange} defaultValue={collectedOnDateRange} format={DATEFORMAT}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Worker :</Col>
                            <Col span={3}>
                                <WorkerSelect withBlank={true} onChange={onSearchWorkerChange} noStyle={true}/>
                            </Col>
                            
                            </>
                        </Row>   

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} loading={isLoading} icon={<DownloadOutlined />} />
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} loading={isLoading} icon={<FileExcelOutlined />} />
                                <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={ffbDailyCollectionDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>
        </Spin>
        </>
    )
}

export default FFBCollectionTable