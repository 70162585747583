import { Row, Col, Space, Typography, Spin } from 'antd'
import { Pie, Line } from '@ant-design/charts'
import { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, LOADING, UNIDATEFORMAT, MONTHFORMAT } from "../common/systemparameter"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, retrieveBorderColor } from "../common/usersession"
import { numberWithCommas, reportError } from "../common/utility"
import dayjs from 'dayjs'

const { Title } = Typography


const DashboardTable = () => {
    const [loading, setLoading] = useState(false)
    const [weightPie, setWeightPie] = useState(null)
    const [weightGraph, setWeightGraph] = useState(null)
    const [manuringPie, setManuringPie] = useState(null)
    const [manuringGraph, setManuringGraph] = useState(null)
    
    const leftWeightPie = () => {
        setLoading(true)

        axios.get(`${APIURL}dashboard/monthlynettweight/`, {
            params: { 
                from_date: dayjs().subtract(12, 'month').format(UNIDATEFORMAT),
                to_date: dayjs().format(UNIDATEFORMAT)
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            plotWeightPie(response.data.results)
        })
        .catch( error => {
            reportError(error, "Failed to load weight record.")
        })
        .finally(() => {
            setLoading(false)
            refreshUserSession()
        })
    }

    const rightWeightGraph = () => {
        setLoading(true)

        axios.get(`${APIURL}dashboard/monthlynettweight/`, {
            params: { 
                from_date: dayjs().subtract(25, 'month').format(UNIDATEFORMAT),
                to_date: dayjs().format(UNIDATEFORMAT)
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            plotWeightGraph(response.data.results)
        })
        .catch( error => {
            reportError(error, "Failed to load weight record.")
        })
        .finally(() => {
            setLoading(false)
            refreshUserSession()
        })
    }

    const leftManuringPie = () => {
        setLoading(true)

        axios.get(`${APIURL}dashboard/monthlymanuring/`, {
            params: { 
                from_date: dayjs().subtract(12, 'month').format(UNIDATEFORMAT),
                to_date: dayjs().format(UNIDATEFORMAT)
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            plotManuringPie(response.data.results)
        })
        .catch( error => {
            reportError(error, "Failed to load manuring record.")
        })
        .finally(() => {
            setLoading(false)
            refreshUserSession()
        })
    }

    const rightManuringGraph = () => {
        setLoading(true)

        axios.get(`${APIURL}dashboard/monthlymanuring/`, {
            params: { 
                from_date: dayjs().subtract(25, 'month').format(UNIDATEFORMAT),
                to_date: dayjs().format(UNIDATEFORMAT)
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            plotManuringGraph(response.data.results)
        })
        .catch( error => {
            reportError(error, "Failed to load manuring record.")
        })
        .finally(() => {
            setLoading(false)
            refreshUserSession()
        })
    }

    const plotWeightPie = (results) => {
        let grandTotalNettWeightTon = 0
        
        // Get distinct estate names.
        const estateNames = new Set(results.map( summary => summary.estate_name))
        let estateTotalWeightTon = []
        
        // Get total weight for each estate.
        estateNames.forEach( estateName => {
            let totalWeightTon = 0
            results.filter( summary => summary.estate_name == estateName).forEach( summary => {
                totalWeightTon += summary.nett_weight_ton
                grandTotalNettWeightTon += summary.nett_weight_ton
            })

            estateTotalWeightTon.push({
                type: estateName,
                value: totalWeightTon
            })
        })

        //----------------------
        // Total nett weight pie
        //----------------------
        const weightPieConfig = {
            //autoFit: false,
            //width: 600,
            //height: 600,
            data: estateTotalWeightTon,
            angleField: 'value',
            radius: 0.75,
            label: {
                type: 'spider',
                labelHeight: 28,
                content: (value) => {
                    return `${numberWithCommas(value.value.toFixed(2))} Ton (${(value.value / grandTotalNettWeightTon * 100).toFixed(2)}%) (${value.type})`
                }
            },
            tooltip: {
                customContent: (title, items) => {
                  return (
                    <>
                        <h5 style={{ marginTop: 16 }}>{title}</h5>
                        <ul style={{ paddingLeft: 0 }}>
                            {items?.map((item, index) => {
                                const { name, value, color } = item
                                
                                return (
                                    <li
                                    key={item.type}
                                    className="g2-tooltip-list-item"
                                    data-index={index}
                                    style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
                                    >
                                    <span className="g2-tooltip-marker" style={{ backgroundColor: color }}></span>
                                    <span style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}>
                                        {/* <span style={{ margiRight: 16 }}>{name}:</span> */}
                                        <span className="g2-tooltip-list-item-value">{String(parseFloat(value).toFixed(2))} Ton</span>
                                    </span>
                                    </li>
                                )
                            })}
                        </ul>
                    </>)
                },
            },
                        
            colorField: 'type',
            interactions: [{ type: 'element-selected' }, { type: 'element-active' }, {type: 'pie-legend-active'},],
        }

        const pie =<>
            <Title level={4}>All Estates Latest 12 Months Oil Palm Accumulated Tonnage</Title>
            <Pie {...weightPieConfig} />
            </>

        setWeightPie(pie)
    }

    const plotManuringPie = (results) => {
        let grandTotalTreeCount = 0
        
        // Get distinct estate names.
        const estateNames = new Set(results.map( summary => summary.estate_name))
        let estateTotalTreeCount = []
        
        // Get total weight for each estate.
        estateNames.forEach( estateName => {
            let totalTreeCount = 0
            results.filter( summary => summary.estate_name == estateName).forEach( summary => {
                totalTreeCount += summary.tree_count
                grandTotalTreeCount += summary.tree_count
            })

            estateTotalTreeCount.push({
                type: estateName,
                value: totalTreeCount
            })
        })

        //----------------------
        // Total manuring pie
        //----------------------
        const manuringPieConfig = {
            //autoFit: false,
            //width: 600,
            //height: 600,
            data: estateTotalTreeCount,
            angleField: 'value',
            radius: 0.75,
            label: {
                type: 'spider',
                labelHeight: 28,
                content: (value) => {
                    return `${numberWithCommas(value.value)} Palm Trees (${(value.value / grandTotalTreeCount * 100).toFixed(2)}%) (${value.type})`
                }
            },
            tooltip: {
                customContent: (title, items) => {
                  return (
                    <>
                        <h5 style={{ marginTop: 16 }}>{title}</h5>
                        <ul style={{ paddingLeft: 0 }}>
                            {items?.map((item, index) => {
                                const { name, value, color } = item
                                return (
                                    <li
                                    key={item.type}
                                    className="g2-tooltip-list-item"
                                    data-index={index}
                                    style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
                                    >
                                    <span className="g2-tooltip-marker" style={{ backgroundColor: color }}></span>
                                    <span style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}>
                                        {/* <span style={{ margiRight: 16 }}>{name}:</span> */}
                                        <span className="g2-tooltip-list-item-value">{value} Palm Trees</span>
                                    </span>
                                    </li>
                                )
                            })}
                        </ul>
                    </>)
                },
            },
                        
            colorField: 'type',
            interactions: [{ type: 'element-selected' }, { type: 'element-active' }, {type: 'pie-legend-active'},],
        }

        const pie = <>
            <Title level={4}>All Estates Latest 12 Months Oil Palm Tree Accumulated Manuring</Title>
            <Pie {...manuringPieConfig} />
            </>
        
        setManuringPie(pie)
    }

    const plotWeightGraph = (results) => {
        let allEstateMonthlyWeightTon = []
        
        // Get all weight for all estate.
        results.forEach( summary => {
            allEstateMonthlyWeightTon.push({
                estateName: summary.estate_name,
                month: dayjs(dayjs(summary.collected_month, UNIDATEFORMAT)).format(MONTHFORMAT),
                value: summary.nett_weight_ton
            })
        })
        
        //-------------------------------
        // Monthly nett weight line graph
        //-------------------------------
        const weightLineConfig = {
            data: allEstateMonthlyWeightTon,
            xField: 'month',
            yField: 'value',
            seriesField: 'estateName',
            xAxis: {
                nice: true,
                label: {
                    // autoRotate: false,
                    rotate: Math.PI /10,
                    offset: 10,
                    style: {
                        fill: '#aaa',
                        fontSize: 12,
                    },
                    formatter: (name) => name,
                },
                title: {
                    text: 'Month',
                    style: {
                        fontSize: 16,
                    },
                },
                line: {
                    style: {
                        stroke: '#aaa',
                    },
                },
                tickLine: {
                    style: {
                        lineWidth: 1,
                        stroke: '#aaa',
                    },
                    length: 5,
                },
                // grid: {
                //     line: {
                //         style: {
                //             stroke: '#ddd',
                //             lineDash: [4, 2],
                //         },
                //     },
                //     alternateColor: 'rgba(0,0,0,0.05)',
                // },
            },

            yAxis: {
                // max: 3000,
                label: {
                    autoRotate: false,
                    style: {
                        fill: '#aaa',
                        fontSize: 12,
                    },
                    // Comma for thousand
                    formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
                },
                title: {
                    text: 'Nett Weight (Ton)',
                    style: {
                        fontSize: 16,
                    },
                },
                // null - not showing.
                line: {
                    style: {
                        stroke: '#aaa',
                    },
                },
                tickLine: {
                    style: {
                        lineWidth: 1,
                        stroke: '#aaa',
                    },
                    length: 5,
                },
                // grid: {
                //     line: {
                //         style: {
                //         stroke: '#ddd',
                //         lineDash: [4, 2],
                //         },
                //     },
                //     alternateColor: 'rgba(0,0,0,0.05)',
                // },
            },

            label: {
                layout: [
                    {
                        type: 'hide-overlap',
                    },
                ],
                style: {
                    textAlign: 'right',
                },
                formatter: (item) => parseFloat(item.value).toFixed(2),
            },
            
            point: {
                size: 5,
                style: {
                    lineWidth: 1,
                    fillOpacity: 1,
                },
                shape: (item) => {
                    // if (item.estateName === 'Cement production') {
                    //     return 'diamond';
                    // }
                    return 'circle';
                },
            },

            legend: {
                position: 'top-right',
                itemName: {
                    style: {
                        fill: '#777',
                    },
                    formatter: (name) => name,
                },
            },
            
            meta: {
                // Month xField || yField
                month: {
                    range: [0, 1],
                },
            },
        }

        const graph = <>
            <Title level={4}>All Estates Latest Monthly Oil Palm Tonnage</Title>
            <Line {...weightLineConfig} />
            </>

        setWeightGraph(graph)
    }

    const plotManuringGraph = (results) => {
        let allEstateMonthlyManuringTreeCount = []
        
        // Get all weight for all estate.
        results.forEach( summary => {
            allEstateMonthlyManuringTreeCount.push({
                estateName: summary.estate_name,
                month: dayjs(dayjs(summary.manured_month, UNIDATEFORMAT)).format(MONTHFORMAT),
                value: summary.tree_count
            })
        })
        
        //-------------------------------
        // Monthly nett weight line graph
        //-------------------------------
        const manuringLineConfig = {
            data: allEstateMonthlyManuringTreeCount,
            xField: 'month',
            yField: 'value',
            seriesField: 'estateName',
            xAxis: {
                nice: true,
                label: {
                    // autoRotate: false,
                    rotate: Math.PI /10,
                    offset: 10,
                    style: {
                        fill: '#aaa',
                        fontSize: 12,
                    },
                    formatter: (name) => name,
                },
                title: {
                    text: 'Month',
                    style: {
                        fontSize: 16,
                    },
                },
                line: {
                    style: {
                        stroke: '#aaa',
                    },
                },
                tickLine: {
                    style: {
                        lineWidth: 1,
                        stroke: '#aaa',
                    },
                    length: 5,
                },
                // grid: {
                //     line: {
                //         style: {
                //             stroke: '#ddd',
                //             lineDash: [4, 2],
                //         },
                //     },
                //     alternateColor: 'rgba(0,0,0,0.05)',
                // },
            },

            yAxis: {
                // max: 3000,
                label: {
                    autoRotate: false,
                    style: {
                        fill: '#aaa',
                        fontSize: 12,
                    },
                    // Comma for thousand
                    formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
                },
                title: {
                    text: 'Palm Trees',
                    style: {
                        fontSize: 16,
                    },
                },
                // null - not showing.
                line: {
                    style: {
                        stroke: '#aaa',
                    },
                },
                tickLine: {
                    style: {
                        lineWidth: 1,
                        stroke: '#aaa',
                    },
                    length: 5,
                },
                // grid: {
                //     line: {
                //         style: {
                //         stroke: '#ddd',
                //         lineDash: [4, 2],
                //         },
                //     },
                //     alternateColor: 'rgba(0,0,0,0.05)',
                // },
            },

            label: {
                layout: [
                    {
                        type: 'hide-overlap',
                    },
                ],
                style: {
                    textAlign: 'right',
                },
                formatter: (item) => item.value,
            },
            
            point: {
                size: 5,
                style: {
                    lineWidth: 1,
                    fillOpacity: 1,
                },
                shape: (item) => {
                    // if (item.estateName === 'Cement production') {
                    //     return 'diamond';
                    // }
                    return 'circle';
                },
            },

            legend: {
                position: 'top-right',
                itemName: {
                    style: {
                        fill: '#777',
                    },
                    formatter: (name) => name,
                },
            },
            
            meta: {
                // Month xField || yField
                month: {
                    range: [0, 1],
                },
            },
        }

        /*console.log(allEstateMonthlyManuringTreeCount)
        const manuringLineConfig = {
            data: allEstateMonthlyManuringTreeCount,
            xField: 'month',
            yField: 'value',
            seriesField: 'estateName',
            xAxis: {
                nice: true,
                label: {
                    // autoRotate: false,
                    rotate: Math.PI /10,
                    offset: 10,
                    style: {
                        fill: '#aaa',
                        fontSize: 12,
                    },
                    formatter: (name) => name,
                },
                title: {
                    text: 'Month',
                    style: {
                        fontSize: 16,
                    },
                },
                
            },
            yAxis: {
                label: {
                    // 数值格式化为千分位
                    formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
                },

                title: {
                    text: 'Palm Trees',
                    style: {
                        fontSize: 16,
                    },
                },

                tickLine: {
                    style: {
                        lineWidth: 2,
                        stroke: '#aaa',
                    },
                    length: 5,
                },
            },

            point: {
                size: 5,
                style: {
                    lineWidth: 1,
                    fillOpacity: 1,
                },
                shape: (item) => {
                    // if (item.estateName === 'Cement production') {
                    //     return 'diamond';
                    // }
                    return 'circle';
                },
            },

            legend: {
                position: 'top-right',
                itemName: {
                    style: {
                        fill: '#777',
                    },
                    formatter: (name) => name,
                },
            },

            meta: {
                // Month xField || yField
                month: {
                    range: [0, 1],
                },
            },
        }*/

        const graph = <>
            <Title level={4}>All Estates Latest Monthly Oil Palm Tree Manuring</Title>
            <Line {...manuringLineConfig} />
            </>

        setManuringGraph(graph)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        leftWeightPie()
        rightWeightGraph()
        leftManuringPie()
        rightManuringGraph()
    }, [])

    return(
        <>
        <Spin spinning={loading} size="large" tip={LOADING}>
        <Row><Col><Space><div /></Space></Col></Row>
        
        <Row justify="center">
            <Col style={{textAlign: "center"}} xs={24} sm={24} md={24} lg={12} xl={12}>
                <div style={{ border: `1px solid ${retrieveBorderColor()}`, margin: '5px'}}>
                    {weightPie}
                </div>
            </Col>
            <Col style={{textAlign: "center"}} xs={24} sm={24} md={24} lg={12} xl={12}>
                <div style={{ border: `1px solid ${retrieveBorderColor()}`, margin: '5px'}}>
                    {weightGraph}
                </div>
            </Col>
        </Row>

        {/* <Row><Col><Space><div /></Space></Col></Row>
        <Row><Col><Space><div /></Space></Col></Row> */}

        <Row justify="center">
            <Col style={{textAlign: "center", border: '1px'}} xs={24} sm={24} md={24} lg={12} xl={12}>
                <div style={{ border: `1px solid ${retrieveBorderColor()}`, margin: '5px'}}>
                    {manuringPie}
                </div>
            </Col>
            <Col style={{textAlign: "center"}} xs={24} sm={24} md={24} lg={12} xl={12}>
                <div style={{ border: `1px solid ${retrieveBorderColor()}`, margin: '5px'}}>
                    {manuringGraph}
                </div>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>
        </Spin>
        </>
    )
}

export default DashboardTable
