import { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import { Form, Button, Space, Layout, Row, Col, Popconfirm, message, Modal, Input, Descriptions, Spin, Upload, DatePicker, Typography } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { SaveOutlined, LeftOutlined, QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons"
import MainHeader from '../common/header'
import MainFooter from '../common/footer'
import { APIURL, DATETIMEFORMAT, LOADING, MAXPHOTOS, MENUPATH_SPRAYING, SEARCHOPTIONVIEWWIDTH } from '../common/systemparameter'
import { reportError } from '../common/utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserEstateId, retrieveBackgroundColor } from "../common/usersession"
import dayjs from 'dayjs'
import HerbicidePesticideSelect from '../common/herbicidepesticideselect'
import { walltiles } from '../common/layout'

const { Header, Footer, Content } = Layout
const { confirm } = Modal
const { Text } = Typography


const SprayingNew = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)
    
    const [herbicidePesticidePKey, setHerbicidePesticidePKey] = useState(0)
    const [sprayedOn, setSprayedOn] = useState(dayjs())
    const [photoFile, setPhotoFile] = useState([])
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)
    const tree = JSON.parse(urlParams.get('tree'))

    const createSpraying = () => {
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.post(`${APIURL}spraying/create/`, {
                estate: getUserEstateId(),
                tree: tree.pkey,
                herbicidepesticide: herbicidePesticidePKey,
                sprayed_on: sprayedOn,
                remark: values.remark
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: { "Authorization": `Token ${getUserAuthToken()}`, }
            })
            .then( response => {
                let data = new FormData()
                photoFile.forEach( file => {
                    data.append("img", file)
                })

                data.append("spraying", response.data.pkey)
                            
                fetch(`${APIURL}spraying/uploadphoto/${response.data.pkey}/`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Token ${getUserAuthToken()}`,
                    },
                    body: data,
                })
                .then( response => {
                    message.info("Spraying record saved.")
                    navigate(MENUPATH_SPRAYING)
                })
                .catch( error => {
                    reportError(error, `Failed to upload photo.`)
                })
                .finally(() => {
                    refreshUserSession()
                })
            })
            .catch( error => {
                reportError(error, `Failed to save spraying record.`)
            })
            .finally(() => {
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onSave = async (e) => {
        if(photoFile.length == 0) {
            message.warning('Photo is required.')
            return
        }

        if(photoFile.length > MAXPHOTOS) {
            message.warning(`Maximum of only ${MAXPHOTOS} photos are allowed.`)
            return
        }

        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Create spraying record confirmed?</p></Space>,
            onOk() { createSpraying() },
            onCancel() {},
            centered: true
        })
    }

    const onSprayedOnChange = (datetime) => {
        setSprayedOn(datetime)
    }

    const onHerbicidePesticideChange = (key) => {
        setHerbicidePesticidePKey(key)
    }

    const validateSprayedOn = (() => {
        if(sprayedOn != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Sprayed on is required."))
    })

    const onBack = () => {
        navigate(MENUPATH_SPRAYING)
    }

    const props = {
        onRemove: file => {
            setPhotoFile(photoFile.filter(photo => photo.name != file.name))
        },
        beforeUpload: (file, fileList) => {
            const isImage = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif'
            if(!isImage) {
                message.error(`${file.name} is not an image file.`)
                setPhotoFile([])
                return false
            }

            setPhotoFile(fileList)
            return false
        },
        multiple: true,
        maxCount: {MAXPHOTOS},
        fileList: photoFile,
    }

    useEffect(() => {
        form.setFieldsValue({
            remark: '',
        })
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles, backgroundColor: retrieveBackgroundColor()}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onBack()} 
                    title="Spraying">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create spraying record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                    <Form.Item name="treeId" label="Tree ID">
                        <Text strong style={{ marginLeft: 10 }}>{tree.tree_id}</Text>
                    </Form.Item>
                    
                    <Form.Item name="sprayedOn" label="Sprayed On"
                        rules={[
                            { required: true, validator: validateSprayedOn },
                        ]}>
                        <DatePicker showTime onChange={onSprayedOnChange} defaultValue={sprayedOn} format={DATETIMEFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }} />
                    </Form.Item>

                    <Form.Item name="herbicidePesticide" label="Herbicide/Pesticide">
                        <HerbicidePesticideSelect withBlank={false} allowClear={false} onChange={onHerbicidePesticideChange} noStyle={true} marginLeft={10}/>
                    </Form.Item> 

                    <Form.Item name="remark" label="Remark">
                        <Input placeholder="Remark" style={{ marginLeft: 10, width: SEARCHOPTIONVIEWWIDTH }}/>
                    </Form.Item>

                    <Form.Item name="photoFile" label={`Photo (max. ${MAXPHOTOS})`}
                        rules={[ 
                            { required: true }
                            ]}>
                        <Upload {...props} accept=".jpg,.png,.gif">
                            <Button icon={<UploadOutlined />}>Add Photo</Button>
                        </Upload>
                    </Form.Item> 

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" icon={<LeftOutlined />}>Cancel</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined/>}>Save</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Spraying:"
                    subTitle="Create spraying record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default SprayingNew