import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { APIURL, MEDIAMAXWIDTH, SEARCHOPTIONVIEWWIDTH,  } from "./systemparameter"
import { reportError } from "./utility"
import { getUserAuthToken, getUserEstateId, OTHERSYSPARAM, refreshUserSession } from "./usersession"
import { useMediaQuery } from 'react-responsive'

const { Option } = Select


const EstateSelect = ({withBlank, defaultValue, onChange, allowClear, ownEstateOnly, excludeOwnEstate, marginLeft = 0}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : SEARCHOPTIONVIEWWIDTH)
    const [estateOption, setEstateOption] = useState([])
            
    const getAllEstate = () => {
        axios.get(`${APIURL}estate/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( estate => <Option key={estate.pkey}>{estate.name}</Option>))
            if(ownEstateOnly) options = options.filter( option => option.key == getUserEstateId())
            if(excludeOwnEstate) options = options = options.filter( option => option.key != getUserEstateId())
            setEstateOption(options)
        })
        .catch( error => {
            reportError(error, "<EstateSelect /> Failed to get estate(s).")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    const onSelectionChange = (e, value) => {
        onChange(e, value)
    }

    useEffect(() => {
        getAllEstate()
    }, []) 
    
    return(
        <Select style={{width: optionWidth, marginLeft: marginLeft}} onChange={onSelectionChange} allowClear={allowClear} showSearch optionFilterProp="children" defaultValue={defaultValue}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {estateOption}
        </Select>
    )
}

export default EstateSelect