import { Table, Row, Col, Space, Button, Collapse, Pagination, message, Spin, DatePicker } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, DATEFORMAT, LOADING, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT, UNIDATETIMEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserEstateId, getUserEstateName } from "../common/usersession"
import { useNavigate } from "react-router"
import { reportError } from '../common/utility'
import dayjs from 'dayjs';


const { Panel } = Collapse
const { RangePicker } = DatePicker

const DeliveryTripSummaryReportTable = () => {
    const FileDownload = require('js-file-download')
    const navigate = useNavigate()

    const [deliveryTripDataSource, setDeliveryTripDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const [deliveredOnDateRange, setDeliveredOnDateRange] = useState([dayjs().add(-1, 'month').add(1, 'day'), dayjs()])
    
    const getSummary = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}deliverytripsummarypaginated/`, {
            params: { 
                estate: getUserEstateId(),
                from_date: deliveredOnDateRange[0] == null ? null : deliveredOnDateRange[0].format(UNIDATEFORMAT),
                to_date: deliveredOnDateRange[1] == null ? null : deliveredOnDateRange[1].format(UNIDATEFORMAT),
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search delivery trip summary.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( summary => {
            data.push({
                key: summary.id,
                deliveredOn: dayjs(summary.delivered_date).format(DATEFORMAT),
                numberOfBunches: summary.number_of_bunches,
                estimatedTotalWeightTon: summary.estimated_total_weight_ton.toFixed(2),
            })
        })
        
        setDeliveryTripDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const downloadSearchResult = (mode) => {
        setIsLoading(true)
                        
        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Estate: ${getUserEstateName()}`
        if(deliveredOnDateRange[0] != null) searchCriteria = `${searchCriteria}\nDelivered On: ${deliveredOnDateRange[0].format(DATEFORMAT)} to ${deliveredOnDateRange[1].format(DATEFORMAT)}`
                                                        
        axios.get(`${APIURL}deliverytripsummary/download/`, {
            params: { 
                estate: getUserEstateId(),
                from_date: deliveredOnDateRange[0] == null ? null : deliveredOnDateRange[0].format(UNIDATEFORMAT),
                to_date: deliveredOnDateRange[1] == null ? null : deliveredOnDateRange[1].format(UNIDATEFORMAT),
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 12),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Delivery Trip Summary ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onPaginationChange = (page) => {
        setCurrentPage(page)
        getSummary(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSearch = () => {
        setCurrentPage(1)
        getSummary(1)
    }

    const onReset = () => {
        window.location.reload()
    }

    const onDeliveredOnDateRangeChange = (dateRange) => {
        if(dateRange == null)
            setDeliveredOnDateRange([null, null])
        else
            setDeliveredOnDateRange(dateRange)
    }

    useEffect(() => {
        getSummary(currentPage)
    }, [])

    const columns = [
        { title: 'Delivered On', dataIndex: 'deliveredOn', key: 'deliveredOn', align: 'center', sorter: (a, b) => a.deliveredOn.localeCompare(b.deliveredOn) },
        { title: 'Bunches', dataIndex: 'numberOfBunches', key: 'numberOfBunches', align: 'right', sorter: (a, b) => a.numberOfBunches - b.numberOfBunches },
        { title: 'Estimated Weight (Ton)', dataIndex: 'estimatedTotalWeightTon', key: 'estimatedTotalWeightTon', align: 'right', sorter: (a, b) => a.estimatedTotalWeightTon - b.estimatedTotalWeightTon },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search delivery trip" key="1">
                        <Row justify="center">
                            <>
                            <Col span={6} />
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Delivered On :</Col>
                            <Col span={3}>
                                <RangePicker onChange={onDeliveredOnDateRangeChange} defaultValue={deliveredOnDateRange} format={DATEFORMAT}/>
                            </Col>

                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            </>
                        </Row>   

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} loading={isLoading} icon={<DownloadOutlined />} />
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} loading={isLoading} icon={<FileExcelOutlined />} />
                                <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={deliveryTripDataSource} pagination={false}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} />
        </Row>
        </Spin>
        </>
    )
}

export default DeliveryTripSummaryReportTable