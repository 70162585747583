import { Table, Row, Col, Space, Button, Collapse, Pagination, message, Spin } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, LOADING, PAGESIZE, PAGINATIONSIZE, UNIDATETIMEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../common/usersession"
import { useNavigate } from "react-router"
import { reportError } from '../common/utility'
import moment from 'moment'
import FertilizerSelect from './fertilizerselect'

const { Panel } = Collapse


const FertilizerTable = () => {
    const FileDownload = require('js-file-download')
    const navigate = useNavigate()

    const [fertilizerId, setFertilizerId] = useState(0)
    const [fertilizer, setFertilizer] = useState("")
    const [fertilizerDataSource, setFertilizerDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    
    const searchFertilizer = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}fertilizerpaginated/`, {
            params: { 
                fertilizer: fertilizerId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search fertilizer type.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( fertilizer => {
            data.push({
                key: fertilizer.pkey,
                name: fertilizer.name,
                remark: fertilizer.remark,
            })
        })
        
        setFertilizerDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const downloadSearchResult = (mode) => {
        setIsLoading(true)
                        
        // Build search criteria string for display in pdf
        let searchCriteria = ""
        if(fertilizerId != 0) searchCriteria = `${searchCriteria}\nFertilizer: ${fertilizer}`
                                        
        axios.get(`${APIURL}fertilizer/download/`, {
            params: { 
                fertilizer: fertilizerId,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 12),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Fertilizer ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchFertilizer(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSearch = () => {
        setCurrentPage(1)
        searchFertilizer(1)
    }

    const onReset = () => {
        window.location.reload()
    }

    const onCreateNew = () => {
        navigate("/fertilizernew")
    }

    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/fertilizerupdate", 
                    search: `?pkey=${record.key}&name=${record.name}&remark=${record.remark}`
                }) 
            }
        }
    }

    const onSearchFertilizerChange = (e, value) => {
        if(e != undefined) {
            setFertilizerId(e)
            setFertilizer(value.children)
        }
        else {
            setFertilizerId(0)
            setFertilizer("")
        }
    }

    useEffect(() => {
        searchFertilizer(currentPage)
    }, [])

    const columns = [
        { title: 'Fertilizer Name', dataIndex: 'name', key: 'name', align: 'center', sorter: (a, b) => a.name.localeCompare(b.name) },
        { title: 'Remark', dataIndex: 'remark', key: 'remark', },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search fertilizer" key="1">
                        <Row justify="center">
                            <>
                            <Col span={6}/>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Fertilizer :</Col>
                            <Col span={3}>
                                <FertilizerSelect withBlank={true} allowClear={true} onChange={onSearchFertilizerChange}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={6} />
                            </>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} loading={isLoading} icon={<DownloadOutlined />} />
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} loading={isLoading} icon={<FileExcelOutlined />} />
                                <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={fertilizerDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>
        </Spin>
        </>
    )
}

export default FertilizerTable