import { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import { Form, Button, Space, Layout, Row, Col, Popconfirm, message, Modal, Input, Descriptions, Spin, DatePicker, InputNumber, Typography } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { SaveOutlined, LeftOutlined, QuestionCircleOutlined, InfoCircleOutlined } from "@ant-design/icons"
import MainHeader from '../common/header'
import MainFooter from '../common/footer'
import { APIURL, LOADING, MENUPATH_TREE, MONTHFORMAT, NONEDATE, SEARCHOPTIONVIEWWIDTH, UNIDATEFORMAT } from '../common/systemparameter'
import { reportError } from '../common/utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserEstateId, SYSPARAM, retrieveBackgroundColor } from "../common/usersession"
import dayjs from 'dayjs'
import GenderSelect from '../common/genderselect'
import SpeciesSelect from '../common/speciesselect'
import HealthConditionSelect from '../common/healthconditionselect'
import { walltiles } from '../common/layout'

const { Header, Footer, Content } = Layout
const { confirm } = Modal
const { Text } = Typography


const TreeBatchUpdate = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)
    
    const [speciesPKey, setSpeciesPKey] = useState(0)
    const [genderPKey, setGenderPKey] = useState(0)
    const [healthConditionPKey, setHealthConditionPKey] = useState(0)
    const [plantedOn, setPlantedOn] = useState(dayjs())
    const [deadOn, setDeadOn] = useState(dayjs())
        
    const registerTree = () => {
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${APIURL}tree/batchupdate/`, {
                estate: getUserEstateId(),
                gender: genderPKey,
                health_condition: healthConditionPKey,
                species: speciesPKey,
                planted_on: plantedOn.format(UNIDATEFORMAT),
                dead_on: healthConditionPKey != SYSPARAM("Dead") ? NONEDATE : deadOn.format(UNIDATEFORMAT),
                block: values.block,
                row: values.row,
                from_palm: values.fromPalm,
                to_palm: values.toPalm,
                //source_seedling: null,
                remark: values.remark,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 4,
                headers: { "Authorization": `Token ${getUserAuthToken()}`, }
            })
            .then( response => {
                let skipped = ""
                if(response.data.skipped > 0) skipped = `Total of ${response.data.skipped} skipped due to tree(s) not exist.`
                //message.info(`Total of ${response.data.registered} tree(s) registered. ${skipped}`)
                //navigate(MENUPATH_TREE)
                confirm({
                    title: "Batch Tree Update",
                    icon: <InfoCircleOutlined style={{ color: 'blue'}} />,
                    //width: 700,
                    onOk() {},
                    cancelButtonProps: {style: {display: "none"}},
                    centered: true,
                    content: <>
                        <Text>{`Total of ${response.data.updated} tree(s) updated.`}</Text>
                        <br/>
                        <Text style={{ color: 'red' }}>{skipped}</Text>
                        </>
                })
            })
            .catch( error => {
                reportError(error, `Failed to batch update tree(s).`)
            })
            .finally(() => {
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
        .finally(() => {
        })
    }

    const onSave = async (e) => {
        form.validateFields()
        .then( values => {
            // If dead, remark is required.
            if(healthConditionPKey == SYSPARAM("Dead") && (form.getFieldValue("remark") == undefined || values.remark.trim() == "")) {
                message.warning('Remark is required for registering dead tree.')
                return
            }

            // To palm must be bigger than from palm.
            if(parseInt(values.toPalm) < parseInt(values.fromPalm)) {
                message.warning('Invalid "To Palm".')
                return
            }

            // Number of palms cannot be more than 10 thousand.
            if(parseInt(values.toPalm) - parseInt(values.fromPalm) + 1 > 10000) {
                message.warning('Invalid "To Palm".')
                return
            }

            // If dead, remark is required.
            if(healthConditionPKey == SYSPARAM("Dead") && (form.getFieldValue("remark") == undefined || values.remark.trim() == "")) {
                message.warning('Remark is required for registering dead tree.')
                return
            }

            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>Batch update tree(s) confirmed?</p></Space>,
                onOk() { registerTree() },
                onCancel() {},
                centered: true
            })
        })
    }

    const onSpeciesChange = (e, value) => {
        setSpeciesPKey(e)
    }

    const onGenderChange = (e, value) => {
        setGenderPKey(e)
    }

    const onPlantedOnChange = (month) => {
        setPlantedOn(month)
    }

    const onDeadOnChange = (month) => {
        setDeadOn(month)
    }

    const onHealthConditionChange = (e, value) => {
        setHealthConditionPKey(e)
    }

    const validatePlantedOn = (() => {
        if(plantedOn != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Planted on is required."))
    })

    const validateDeadOn = (() => {
        if(deadOn != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Dead on is required."))
    })
    
    const onBack = () => {
        navigate(MENUPATH_TREE)
    }

    useEffect(() => {
        form.setFieldsValue({
            remark: ''
        })
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles, backgroundColor: retrieveBackgroundColor()}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onBack()} 
                    title="Tree Record">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Batch update tree record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                    <Form.Item name="block" label="Update Block"
                        rules={[
                            { required: true, message: "Block is required."},
                        ]}>
                        <InputNumber min={0} max={100000} step={1} precision={0} placeholder="Block" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="row" label="Update Row"
                        rules={[
                            { required: true, message: "Row is required."},
                        ]}>
                        <InputNumber min={0} max={100000} step={1} precision={0} placeholder="Row" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="fromPalm" label="Update Palm (From)"
                        rules={[
                            { required: true, message: "From palm is required."},
                        ]}>
                        <InputNumber min={0} max={100000} step={1} precision={0} placeholder="From Palm" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="toPalm" label="Update Palm (To)">
                        <InputNumber min={0} max={100000} step={1} precision={0} placeholder="To Palm" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }}/>
                        {/* <Space /> (Leave blank if is registering a single tree.) */}
                    </Form.Item>

                    <Form.Item name="species" label="Species"
                        rules={[
                            { required: true, message: "Species is required."},
                        ]}>
                        <SpeciesSelect withBlank={false} allowClear={false} onChange={onSpeciesChange} marginLeft={10}/>
                    </Form.Item>
                    
                    <Form.Item name="gender" label="Gender"
                        rules={[
                            { required: true, message: "Gender is required."},
                        ]}>
                        <GenderSelect withBlank={false} allowClear={false} onChange={onGenderChange} marginLeft={10}/>
                    </Form.Item>

                    <Form.Item name="healthCondition" label="Health Condition"
                        rules={[
                            { required: true, message: "Health condition is required."},
                        ]}>
                        <HealthConditionSelect withBlank={false} allowClear={false} onChange={onHealthConditionChange} marginLeft={10}/>
                    </Form.Item>

                    <Form.Item name="plantedOn" label="Planted On"
                        rules={[
                            { required: true, validator: validatePlantedOn },
                        ]}>
                        <DatePicker picker="month" onChange={onPlantedOnChange} defaultValue={plantedOn} format={MONTHFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }}/>
                    </Form.Item>

                    { healthConditionPKey == SYSPARAM("Dead") &&
                    <Form.Item name="deadOn" label="Dead On"
                        rules={[
                            { required: true, validator: validateDeadOn },
                        ]}>
                        <DatePicker picker="month" onChange={onDeadOnChange} defaultValue={deadOn} format={MONTHFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }}/>
                    </Form.Item>
                    }

                    <Form.Item name="remark" label="Remark">
                        <Input placeholder="Remark" style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }}/>
                    </Form.Item>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" icon={<LeftOutlined />}>Cancel</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined/>}>Update</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Tree Record:"
                    subTitle="Batch update tree record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default TreeBatchUpdate
