import { useEffect, useState } from 'react'
import { useNavigate } from "react-router"
import { Form, Input, Button, message, Space, Layout, Row, Col, Popconfirm, Modal, Descriptions, Spin, Switch, DatePicker, Upload, Image } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { SaveOutlined, CloseOutlined, DeleteOutlined, QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons"
import MainHeader from '../common/header'
import MainFooter from '../common/footer'
import { reportError } from '../common/utility'
import axios from "axios"
import { APIURL, DATEFORMAT, LOADING, MENUPATH_WORKER, SEARCHOPTIONVIEWWIDTH, UNIDATETIMEFORMAT, IMGURL, WORKERIMGFOLDER, IMAGEWIDTH, FALLBACK } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, retrieveBackgroundColor } from "../common/usersession"
import EstateSelect from '../common/estateselect'
import { walltiles } from '../common/layout'
import EmploymentTypeSelect from '../common/employmenttypeselect'
import dayjs from 'dayjs'

const { Header, Footer, Content } = Layout
const { confirm } = Modal


const WorkerUpdate = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
            
    const [form] = Form.useForm()
    const [disableDeleteButton, setDisableDeleteButton] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [uploading, setUploading] = useState(false)

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)
    const [disableRemovePhotoButton, setDisableRemovePhotoButton] = useState(urlParams.get("img") == "null" ?  true : false)

    const [photoFileURI, setPhotoFileURI] = useState(`${urlParams.get("img")}?now=${dayjs().valueOf()}`)

    const [estateId, setEstateId] = useState(urlParams.get("estateId"))
    const [employmentTypeId, setEmploymentTypeId] = useState(urlParams.get("employmentTypeId"))
    const [isActive, setIsActive] = useState(urlParams.get("active") == "true" ? true: false)
    const [employedOn, setEmployedOn] = useState(dayjs(urlParams.get("employedOn")), UNIDATETIMEFORMAT)
    const [photoFile, setPhotoFile] = useState([])
    
    const removePhoto = () => {
        setIsLoading(true)
        
        axios.patch(`${APIURL}worker/removephoto/${urlParams.get("pkey")}/`, {}, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info("Photo removed.")
            setDisableRemovePhotoButton(true)
            setPhotoFile([])
            setPhotoFileURI('')
        })
        .catch( error => {
            reportError(error, `Failed to remove attendance photo.`)
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }
    
    const updateWorker = () => {
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${APIURL}worker/update/${urlParams.get("pkey")}/`, {
                name: values.name,
                estate: estateId,
                employment_type: employmentTypeId,
                employed_on: employedOn,
                employee_id: values.employeeId,
                active: isActive,
                remark: values.remark,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`Worker ${values.name} updated.`)
                navigate(MENUPATH_WORKER)
            })
            .catch( error => {
                reportError(error, "Failed to update worker.")
            })
            .finally(() => {
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    const onDelete = () => {
        setIsLoading(true)
        
        axios.delete(`${APIURL}worker/delete/${urlParams.get("pkey")}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Worker ${urlParams.get("name")} deleted.`)
            navigate(MENUPATH_WORKER)
        })
        .catch( error => {
            reportError(error, "Failed to delete worker.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    // Determine if has related record.
    const toDisableDeleteButton = () => {
        setIsLoading(true)

        axios.get(`${APIURL}workerdownloaded/${urlParams.get("pkey")}/`, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response.data.downloaded) 
                setDisableDeleteButton(true)
        })
        .catch( error => {
            reportError(error, "Failed to determine if worker record was downloaded.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onCancel = () => {
        navigate(MENUPATH_WORKER)
    }

    const onUpdate = () => {
        if(photoFileURI == '') {
            message.warning('Photo is required.')
            return
        }

        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Update worker confirmed?</p></Space>,
            onOk() { updateWorker() },
            onCancel() {},
            centered: true
        })
    }

    const onIsActiveChange = (checked) => {
        setIsActive(checked)
    }

    const onEstateChange = (e, value) => {
        if(e != undefined) 
            setEstateId(e)
        else 
            setEstateId(0)
    }

    const onEmploymentTypeChange = (e, value) => {
        if(e != undefined) 
            setEmploymentTypeId(e)
        else 
            setEmploymentTypeId(0)
    }

    const onEmployedOnChange = (datetime) => {
        setEmployedOn(datetime)
    }
    
    const validateEstate = (() => {
        if(estateId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Estate is required."))
    })

    const validateEmploymentType = (() => {
        if(employmentTypeId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Employment type is required."))
    })

    const validateEmployedOn = (() => {
        if(employedOn != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Employment date is required."))
    })
    
    const props = {
        name: "img",
        action: `${APIURL}worker/uploadphoto/${urlParams.get("pkey")}/`,
        timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 10,
        headers: {
            Authorization: `Token ${getUserAuthToken()}`,
        },
        fileList: photoFile,
        onRemove: file => {
            setPhotoFile([])
            removePhoto()
            setDisableRemovePhotoButton(true)
        },
        beforeUpload: file => {
            const isImage = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif'
            if(!isImage) {
                setUploading(false)
                setIsLoading(false)
                message.error(`${file.name} is not an image file.`)
                setPhotoFile([])
                return false
            }

            setPhotoFile([file])
        },
        onChange(info) {
            setUploading(true)

            if(info.file.status !== 'uploading') {
                setUploading(false)
                //setIsLoading(true)
            }

            if(info.file.status === 'done') {
                setUploading(false)
                setIsLoading(false)

                const fileArray = info.file.name.split('.')
                const fileExtention = fileArray[fileArray.length - 1]
                setPhotoFileURI(`${IMGURL}${WORKERIMGFOLDER}/${urlParams.get("pkey")}.${fileExtention}?now=${dayjs().valueOf()}`)
                setDisableRemovePhotoButton(false)
                message.info("Photo uploaded.")
            }
            else if(info.file.status === 'error') {
                setUploading(false)
                message.error(`"${info.file.name}" upload failed.`)
                message.error(info.file.response?.detail)
            }
        },
        maxCount: 1,
        photoFile,
    }

    useEffect(() => {
        toDisableDeleteButton()
        
        form.setFieldsValue({
            name: urlParams.get("name"),
            employmentTypeId: urlParams.get("employmentTypeID"),
            employeeId: urlParams.get("employeeId"),
            remark: urlParams.get("remark"),
        })
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <MainHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles, backgroundColor: retrieveBackgroundColor()}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onCancel()} 
                    title="Worker">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update worker</Descriptions.Item>
                    </Descriptions>
                </PageHeader>
        
                <Form form={form} onFinish={onUpdate} labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                    <Form.Item name="name" label="Name"
                        rules={[
                            { required: true, message: "Worker name is required."},
                        ]}>
                        <Input maxLength={50} placeholder="Worker Name" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="employeeId" label="Employee ID"
                        rules={[
                            { required: true, message: "Employee ID is required."},
                        ]}>
                        <Input maxLength={50} placeholder="Employee ID" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="estate" label="Estate"
                        rules={[
                            { required: true, validator: validateEstate },
                        ]}>
                        <EstateSelect withBlank={false} allowClear={false} defaultValue={urlParams.get("estate")} onChange={onEstateChange} marginLeft={10}/>
                    </Form.Item>

                    <Form.Item name="employmentType" label="Employment Type"
                        rules={[
                            { required: true, validator: validateEmploymentType },
                        ]}>
                        <EmploymentTypeSelect withBlank={false} defaultValue={employmentTypeId} allowClear={false} onChange={onEmploymentTypeChange} marginLeft={10}/>
                    </Form.Item>
                    
                    <Form.Item name="employedOn" label="Employment Date"
                        rules={[
                            { required: true, validator: validateEmployedOn },
                        ]}>
                        <DatePicker showTime onChange={onEmployedOnChange} defaultValue={employedOn} format={DATEFORMAT} style={{ width: SEARCHOPTIONVIEWWIDTH,  marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="isActive" label="Is Active">
                        <Switch defaultChecked={isActive} onChange={onIsActiveChange} style={{ marginLeft: 10 }}/>
                    </Form.Item>
                    
                    <Form.Item name="remark" label="Remark">
                        <Input placeholder="Remark" style={{ width: SEARCHOPTIONVIEWWIDTH, marginLeft: 10 }}/>
                    </Form.Item>

                    <Form.Item name="photoFile" label="Photo">
                        <Input.Group>
                            <Button onClick={removePhoto} icon={<DeleteOutlined />} disabled={disableRemovePhotoButton}/>
                            <Upload {...props} accept=".jpg,.png,.gif">
                                <Button icon={<UploadOutlined />} loading={uploading}>Select Photo</Button>
                            </Upload>
                        </Input.Group>
                    </Form.Item>

                    <Row>
                        <Col span={6} />
                            <Col span={12} style={{textAlign: "center"}}>
                                { !disableRemovePhotoButton && 
                                    <Image 
                                        width={IMAGEWIDTH} 
                                        //height = {IMAGEHEIGHT}
                                        src={photoFileURI}
                                        fallback={FALLBACK}
                                        preview={true} />
                                }
                            </Col>
                        <Col span={6} />
                    </Row>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>Update</Button>
                            <Popconfirm title="Delete worker confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" disabled={disableDeleteButton} icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" onClick={onCancel} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <MainFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                        title="Worker:"
                        subTitle="Update worker"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default WorkerUpdate