import { Table, Row, Col, Space, Button, Collapse, Pagination, message, Spin, DatePicker, InputNumber, Input } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined, EditOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { APIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT, LOADING, MONTHFORMAT, NONEDATE, PAGESIZE, PAGINATIONSIZE, SEARCHOPTIONVIEWWIDTH, UNIDATETIMEFORMAT } from '../common/systemparameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserEstateId, getUserEstateName, SYSPARAM, retrieveTreeBlock, retrieveTreeRow, storeTreeBlock, storeTreeRow, getUserGroup } from "../common/usersession"
import { useNavigate } from "react-router"
import { reportError, validPalmSearchRange } from '../common/utility'
import dayjs from 'dayjs';
import SpeciesSelect from '../common/speciesselect'
import GenderSelect from '../common/genderselect'
import HealthConditionSelect from '../common/healthconditionselect'
import TrueFalseSelect from '../common/truefalseselect'

const { Panel } = Collapse
const { RangePicker } = DatePicker


const TreeTable = () => {
    const FileDownload = require('js-file-download')
    const navigate = useNavigate()

    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const [treeDataSource, setTreeDataSource] = useState([])
    const [speciesPKey, setSpeciesPKey] = useState(0)
    const [species, setSpecies] = useState('')
    const [genderPKey, setGenderPKey] = useState(0)
    const [gender, setGender] = useState('')
    const [healthConditionPKey, setHealthConditionPKey] = useState(0)
    const [healthCondition, setHealthCondition] = useState('')
    const [plantedOnDateRange, setPlantedOnDateRange] = useState([null, null])
    const [deadOnDateRange, setDeadOnDateRange] = useState([null, null])
    //const [block, setBlock] = useState(-1)
    //const [row, setRow] = useState(-1)
    const [block, setBlock] = useState(!isNaN(retrieveTreeBlock()) ? retrieveTreeBlock() : -1)
    const [row, setRow] = useState(!isNaN(retrieveTreeRow()) ? retrieveTreeRow() : -1)
    const [number, setNumber] = useState('')
    
    const [isLiving, setIsLiving] = useState(true)
    const [isLivingLabel, setIsLivingLabel] = useState(OTHERSYSPARAM('YES'))
        
    const searchTree = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${APIURL}treepaginated/`, {
            params: { 
                estate: getUserEstateId(),
                block: block,
                row: row,
                number: number,
                species: speciesPKey,
                gender: genderPKey,
                health_condition: healthConditionPKey,
                planted_on_from_date: plantedOnDateRange[0] == null ? null : `${plantedOnDateRange[0].$y}-${parseInt(plantedOnDateRange[0].$M) + 1}`,
                planted_on_to_date: plantedOnDateRange[1] == null ? null : `${plantedOnDateRange[1].$y}-${parseInt(plantedOnDateRange[1].$M) + 1}`,
                dead_on_from_date: deadOnDateRange[0] == null ? null : `${deadOnDateRange[0].$y}-${parseInt(deadOnDateRange[0].$M) + 1}`,
                dead_on_to_date: deadOnDateRange[1] == null ? null : `${deadOnDateRange[1].$y}-${parseInt(deadOnDateRange[1].$M) + 1}`,
                is_living: isLiving,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)

            if(response.data.results.length > 0) {
                storeTreeBlock(block)
                storeTreeRow(row)
            }
        })
        .catch( error => {
            reportError(error, "Failed to search tree.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( tree => {
            data.push({
                key: tree.pkey,
                treeId: tree.tree_id,
                speciesPKey: tree.species_data.pkey,
                species: tree.species_data.general_name,
                genderPKey: tree.gender_data.pkey,
                gender: tree.gender_data.name,
                healthConditionPKey: tree.health_condition_data.pkey,
                healthCondition: tree.health_condition_data.name,
                plantedOn: dayjs(tree.planted_on).format(DATEFORMAT),
                plantedOnUnformatted: tree.planted_on,
                deadOn: tree.dead_on == NONEDATE ? '' : dayjs(tree.dead_on).format(DATEFORMAT),
                deadOnUnformatted: tree.dead_on,
                block: tree.block,
                row: tree.row,
                number: tree.number,
                remark: tree.remark,
                createdOn: dayjs(tree.created_on).format(DATETIMEFORMAT),
                updatedOn: dayjs(tree.updated_on).format(DATETIMEFORMAT),
                uploadedOn: tree.uploaded_on != null ? dayjs(tree.uploaded_on).format(DATETIMEFORMAT) : '',
                uploadedBy: tree.uploader_data != null ? tree.uploader_data.username : '',
            })
        })
        
        setTreeDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const downloadSearchResult = (mode) => {
        if(block == -1) {
            message.info('Please kindly provide the block to reduce download time and size.')
            return
        }

        setIsLoading(true)
                        
        // Build search criteria string for display in pdf
        let searchCriteria = ""
        searchCriteria = `Estate: ${getUserEstateName()}`
        if(block != -1) searchCriteria = `${searchCriteria}\nBlock: ${block}`
        if(row != -1) searchCriteria = `${searchCriteria}\nRow: ${row}`
        if(number != "") searchCriteria = `${searchCriteria}\nPalm: ${number}`
        if(speciesPKey != 0) searchCriteria = `${searchCriteria}\nSpecies: ${species}`
        if(genderPKey != 0) searchCriteria = `${searchCriteria}\nGender: ${gender}`
        if(healthConditionPKey != 0) searchCriteria = `${searchCriteria}\nHealth Condition: ${healthCondition}`
        if(plantedOnDateRange[0] != null) searchCriteria = `${searchCriteria}\nPlanted On: ${plantedOnDateRange[0].format(MONTHFORMAT)} to ${plantedOnDateRange[1].format(MONTHFORMAT)}`
        if(deadOnDateRange[0] != null) searchCriteria = `${searchCriteria}\nDead On: ${deadOnDateRange[0].format(MONTHFORMAT)} to ${deadOnDateRange[1].format(MONTHFORMAT)}`
        if(isLiving != '') searchCriteria = `${searchCriteria}\nIs Living: ${isLivingLabel}`
                                                        
        axios.get(`${APIURL}tree/download/`, {
            params: { 
                estate: getUserEstateId(),
                block: block,
                row: row,
                number: number,
                species: speciesPKey,
                gender: genderPKey,
                health_condition: healthConditionPKey,
                planted_on_from_date: plantedOnDateRange[0] == null ? null : `${plantedOnDateRange[0].$y}-${parseInt(plantedOnDateRange[0].$M) + 1}`,
                planted_on_to_date: plantedOnDateRange[1] == null ? null : `${plantedOnDateRange[1].$y}-${parseInt(plantedOnDateRange[1].$M) + 1}`,
                dead_on_from_date: deadOnDateRange[0] == null ? null : `${deadOnDateRange[0].$y}-${parseInt(deadOnDateRange[0].$M) + 1}`,
                dead_on_to_date: deadOnDateRange[1] == null ? null : `${deadOnDateRange[1].$y}-${parseInt(deadOnDateRange[1].$M) + 1}`,
                is_living: isLiving,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: 60 * 60 * 1000,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = dayjs().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Tree ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchTree(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    const onSearch = () => {
        if(!validPalmSearchRange(number)) {
            message.warning('Invalid palm search range.')
            return
        }
        
        setCurrentPage(1)
        searchTree(1)
    }

    const onReset = () => {
        window.location.reload()
    }

    const onBatchUpdate = () => {
        navigate("/treebatchupdate")
    }

    const onCreateNew = () => {
        navigate("/treenew")
    }

    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                if(record["healthConditionPKey"] != SYSPARAM("Dead"))
                    navigate({ 
                        pathname: "/treeupdate", 
                        search: `?tree=${JSON.stringify(record)}`
                    }) 
                else
                    navigate({ 
                        pathname: "/treeviewdead", 
                        search: `?tree=${JSON.stringify(record)}`
                    })
            }
        }
    }

    const onPlantedOnDateRangeChange = (dateRange) => {
        if(dateRange != null)
            setPlantedOnDateRange(dateRange)
        else
            setPlantedOnDateRange([null, null])
    }

    const onDeadOnDateRangeChange = (dateRange) => {
        if(dateRange != null)
            setDeadOnDateRange(dateRange)
        else
            setDeadOnDateRange([null, null])
    }

    const onSpeciesChange = (e, value) => {
        setSpeciesPKey(e)
        setSpecies(value.children)
    }

    const onGenderChange = (e, value) => {
        setGenderPKey(e)
        setGender(value.children)
    }

    const onHealthConditionChange = (e, value) => {
        setHealthConditionPKey(e)
        setHealthCondition(value.children)
    }

    const onBlockChange = (e, value) => {
        if(e !== null)
            setBlock(e)
        else 
            setBlock(-1)
    }

    const onRowChange = (e, value) => {
        if(e !== null)
            setRow(e)
        else 
            setRow(-1)
    }

    const onNumberChange = (e) => {
        setNumber(e.target.value.trim())
    }

    const onIsLivingChange = (e, value) => {
        setIsLiving(e)
        setIsLivingLabel(value.children)
    }

    useEffect(() => {
        searchTree(currentPage)
    }, [])

    const columns = [
        { title: 'Tree ID', dataIndex: 'treeId', key: 'treeId', align: 'center', sorter: (a, b) => a.treeId.localeCompare(b.treeId) },
        { title: 'Species', dataIndex: 'species', key: 'species', align: 'center', sorter: (a, b) => a.species.localeCompare(b.species) },
        { title: 'Gender', dataIndex: 'gender', key: 'gender', align: 'center', sorter: (a, b) => a.gender.localeCompare(b.gender) },
        { title: 'Health Condition', dataIndex: 'healthCondition', key: 'healthCondition', align: 'center', sorter: (a, b) => a.healthCondition.localeCompare(b.healthCondition) },
        { title: 'Planted On', dataIndex: 'plantedOn', key: 'plantedOn', align: 'center', sorter: (a, b) => a.plantedOn.localeCompare(b.plantedOn) },
        { title: 'Dead On', dataIndex: 'deadOn', key: 'deadOn', align: 'center', sorter: (a, b) => a.deadOn.localeCompare(b.deadOn) },
        { title: 'Uploaded By', dataIndex: 'uploadedBy', key: 'uploadedBy', align: 'center', sorter: (a, b) => a.uploadedBy.localeCompare(b.uploadedBy) },
        { title: 'Uploaded On', dataIndex: 'uploadedOn', key: 'uploadedOn', align: 'center', sorter: (a, b) => a.uploadedOn.localeCompare(b.uploadedOn) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search tree" key="1">
                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Block :</Col>
                            <Col span={3}>
                                <InputNumber defaultValue={block != -1 ? block : ''} onChange={onBlockChange} style={{ width: SEARCHOPTIONVIEWWIDTH }} min={0} max={100000} step={1} precision={0} placeholder="Block"/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Species :</Col>
                            <Col span={3}>
                                <SpeciesSelect withBlank={true} onChange={onSpeciesChange} allowClear={true} noStyle={true}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Planted On :</Col>
                            <Col span={3}>
                                <RangePicker onChange={onPlantedOnDateRangeChange} defaultValue={plantedOnDateRange} picker="month" format={MONTHFORMAT} allowClear/>
                            </Col>
                            </>
                        </Row>   

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Row :</Col>
                            <Col span={3}>
                                <InputNumber defaultValue={row != -1 ? row : ''} onChange={onRowChange} style={{ width: SEARCHOPTIONVIEWWIDTH }} min={0} max={100000} step={1} precision={0} placeholder="Row"/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Gender :</Col>
                            <Col span={3}>
                                <GenderSelect withBlank={true} onChange={onGenderChange} allowClear={true} noStyle={true}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Dead On :</Col>
                            <Col span={3}>
                                <RangePicker onChange={onDeadOnDateRangeChange} defaultValue={deadOnDateRange} picker="month" format={MONTHFORMAT} allowClear/>
                            </Col>
                            </>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Palm :</Col>
                            <Col span={3}>
                                <Input onChange={onNumberChange} style={{ width: SEARCHOPTIONVIEWWIDTH }} placeholder={"Use \",\" and \"-\" for multiple palms"}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Health Condition :</Col>
                            <Col span={3}>
                                <HealthConditionSelect withBlank={true} onChange={onHealthConditionChange} allowClear={true} noStyle={true}/>
                            </Col>
                            <Col span={1}><Space /></Col>
                            <Col span={3} style={{ textAlign: 'end', marginRight: 10 }}>Is Living :</Col>
                            <Col span={3}>
                                <TrueFalseSelect withBlank={true} defaultValue={isLivingLabel} onChange={onIsLivingChange} noStyle={true}/>
                            </Col>
                            </>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>

                        <Row justify="center">
                            <Col span={6}></Col>
                            <Col span={12} style={{textAlign: "center"}}>
                                <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} loading={isLoading} icon={<DownloadOutlined />} />
                                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} loading={isLoading} icon={<FileExcelOutlined />} />
                                <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                            </Col>
                            <Col span={6}></Col>
                        </Row>
                        
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}>
                { getUserGroup() == OTHERSYSPARAM("ADMINOFFICER") || getUserGroup() == OTHERSYSPARAM("SYSTEMIMPLEMENTOR") &&
                    <Button type="primary" htmlType="button" onClick={onBatchUpdate} icon={<EditOutlined />}>Batch Update</Button>
                }
                <Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={treeDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={6}></Col>
            <Col span={12} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={6} style={{textAlign: "end"}}>
                { getUserGroup() == OTHERSYSPARAM("ADMINOFFICER") || getUserGroup() == OTHERSYSPARAM("SYSTEMIMPLEMENTOR") &&
                    <Button type="primary" htmlType="button" onClick={onBatchUpdate} icon={<EditOutlined />}>Batch Update</Button>
                }
                <Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button>
            </Col>
        </Row>
        </Spin>
        </>
    )
}

export default TreeTable